<template>
	<div style="height: 85%;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 30px;padding: 3% 6%;">
		<div style="width: 45%;float: left;">
			<el-input :value="accountInfo.id" :readonly="true">
				<template slot="prepend">ID ：</template>
			</el-input>
			<el-input :value="accountInfo.account" :readonly="true">
				<template slot="prepend">账户：</template>
			</el-input>
			<el-input :value="accountInfo.nickname" :readonly="true">
				<template slot="prepend">昵称：</template>
			</el-input>
			<el-input :value="accountInfo.username" :readonly="true">
				<template slot="prepend">用户名：</template>
			</el-input>
			<el-input :value="accountInfo.phoneNum" :readonly="true">
				<template slot="prepend">号码：</template>
			</el-input>
			<el-input :value="accountInfo.address" :readonly="true">
				<template slot="prepend">地址：</template>
			</el-input>
			<el-input :value="accountInfo.sex" :readonly="true">
				<template slot="prepend">性别：</template>
			</el-input>
			<el-input :value="accountInfo.age" :readonly="true">
				<template slot="prepend">年龄：</template>
			</el-input>
			<el-input :value="accountInfo.status" :readonly="true">
				<template slot="prepend">状态：</template>
			</el-input>
		</div>
		<div style="width: 45%;float: left;">
			<div style="padding: 0 5% 2% 10%;width: 50%;">
				<el-avatar style="border: 1px darkgray solid;" shape="square" :size="100" :src="accountInfo.imageUrl"></el-avatar><!-- 头像 -->
				<div style="float: right;">
					<div style="padding-bottom: 10%;">
						<el-button @click="openImgButton">更改头像</el-button>
					</div>
					<div style="padding-top: 10%;">
						<el-button v-if="image!=''" @click="uploadImageButton()">上传头像</el-button>
						<input v-show="false" type="file" accept="image/*" @change="tirggerFile($event)" id="updateImage" ref="updateImage" />
					</div>
				</div>
			</div>
			<div style="padding: 0 5% 2% 10%;">
				<div style="padding: 1% 0;">
					<el-tag type="success">原创文章：999+</el-tag>
					<el-tag type="warning">待审核文章：999+</el-tag>
				</div>
				<div style="padding: 1% 0;">
					<el-tag type="success">文章评论：999+</el-tag>
				</div>
				<div style="padding: 1% 0;">
					<el-tag type="danger">受到举报次数：888+</el-tag>
				</div>
			</div>
			<div style="padding: 0 5% 2% 10%;">
				<div style="padding: 1% 0;">
					<el-button icon="el-icon-edit" type="primary" @click="updateAccountButton()">修改账户信息</el-button>
				</div>
				<div style="padding: 1% 0;">
					<el-button icon="el-icon-unlock" type="warning" @click="resetPasswordButton()">重置登录密码</el-button>
					<el-button icon="el-icon-warning-outline" type="warning" @click="updateAccountStatusButton()">锁定/解锁账户</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		requestBlogImage
	} from '../../../network/request.js'
	import {
		getAccountInfo,
		uploadImage,
		updateAccountStatus,
		resetPassword
	} from '../../../js/background/blog_account_manage/AccountInfo.js'
	import {
		warningPrompt,
		successPrompt,
		optionPrompt3
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				accountCondition: {
					id: null
				},
				updateAccountStatusCondition: {
					account: null,
					status: null
				},
				image: '', //用于判断是否显示上传头像图片按钮
				accountInfo: null,
				imageData: null
			};
		},
		methods: {
			//打开选取头像图片事件
			openImgButton() {
				this.$refs.updateImage.click();
			},
			//选取头像图片后预显示图片及图片数据赋值事件
			tirggerFile(event) {
				this.imageData = event

				let file = event.target.files[0];
				let url = "";
				var reader = new FileReader(); //html5读文件
				reader.readAsDataURL(file); //转BASE64
				let that = this;

				//读取完毕调用接口
				reader.onload = function() {
					//获取图片url
					url = this.result.substring(this.result.indexOf(",") + 1);
					//更改原有头像图片的路径，预显示要更改的头像图片
					that.accountInfo.imageUrl = "data:image/png;base64," + url;
					//更改image属性，当image不为空符串时，上传按钮将显示
					that.image = "data:image/png;base64," + url;
				};
			},
			//上传头像图片事件
			uploadImageButton() {
				let formData = new FormData(); //通过formData上传
				formData.append('id', this.$route.query.id) //设置需要更改头像图片用户的id
				formData.append('photoFile', this.imageData.target.files[0])

				uploadImage(formData).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			updateAccountButton() {
				this.$router.push({
					path: "blogUpdateAccount",
					query: {
						id: this.accountInfo.id
					}
				})
			},
			//获取账户详情
			getAccountInfo() {
				this.accountCondition.id = this.$route.query.id
				getAccountInfo(this.accountCondition).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountInfo = res.data
						this.accountInfo.imageUrl = requestBlogImage(this.accountInfo.imageUrl)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//修改账户状态
			updateAccountStatusButton() {
				this.updateAccountStatusCondition.account = this.accountInfo.account
				this.updateAccountStatusCondition.status = this.accountInfo.status
				updateAccountStatus(this.updateAccountStatusCondition).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.getAccountInfo() //重新加载数据
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			resetPasswordButton() {
				optionPrompt3("是否重置密码?").then(() => {
					this.accountCondition.id = this.$route.query.id
					resetPassword(this.accountCondition).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message);
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			}
		},
		created() {
			this.getAccountInfo()
		}
	}
</script>

<style>
</style>
