import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载角色数据列表
export function getRoles(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/roles/by/role/name',
		params: data,
		method: 'GET'
	})
}


//删除角色
export function deleteRoles(data) {
	return axiosBlogBackgroundRequest({
		url: '/delete/role',
		params: data,
		method: 'PUT'
	})
}
