import {
	axiosBlogRequest
} from '../../network/request.js'


//验证邮箱
export function validationEmail(data) {
	return axiosBlogRequest({
		url: '/get/account/open',
		params: data,
		method: 'GET'
	})
}

//验证验证码
export function validationCode(data) {
	return axiosBlogRequest({
		url: '/validation/code/open',
		params: data,
		method: 'GET'
	})
}

//找回密码
export function retrievePassword(data) {
	return axiosBlogRequest({
		url: '/update/account/password/open',
		params: data,
		method: 'PUT'
	})
}

//获取验证码事件
export function getEmailAuthCode(data) {
	return axiosBlogRequest({
		url: '/get/email/auth/code/open',
		params: data,
		method: 'GET'
	})
}