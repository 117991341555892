import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载权限数据列表
export function getPermissionByPermissionName(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/permissions',
		params: data,
		method: 'GET'
	})
}

//添加角色权限事件
export function addRolePermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/insert/role/permission',
		params: data,
		method: 'POST'
	})
}
