import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载角色数据列表
export function getRolesByRoleName(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/roles/by/role/name',
		params: data,
		method: 'GET'
	})
}

//按条件分页加载角色数据列表
export function addAccountRoles(data) {
	return axiosBlogBackgroundRequest({
		url: '/insert/account/role',
		params: data,
		method: 'POST'
	})
}
