<template>
	<div :style="{backgroundImage: 'url(' + bg2 + ')','background-size':'cover',height: '750px'}">
		<vue-particles style="position:absolute" color="#fff" :particleOpacity="0.7" :particlesNumber="60" shapeType="circle" :particleSize="4" linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="lizi">
		</vue-particles>
		<div style="padding-top: 10%;" align="center">
			<div style="text-align: center; width: 40%;">
				<el-card>
					<div>
						<h2>
							<el-image style="width: 10%; height: 10%;margin-bottom: -2%;" :src="bg3"></el-image>
							<span style="margin: 10% 0;">博客登录</span>
						</h2>
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="账户" prop="account">
							<el-input prefix-icon="el-icon-user-solid" v-model="ruleForm.account" placeholder="登录邮箱"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input prefix-icon="el-icon-unlock" type="password" v-model="ruleForm.password" placeholder="登录密码"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">立即登录</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
					<div style="text-align: right;">
						<el-link type="warning" @click="retrievePasswordButton()"><b>忘记密码?</b></el-link>
					</div>
					<div style="text-align: right;">
						<el-link @click="registerButton()"><b>没有账号?点击前往注册!</b></el-link>
					</div>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
	import Bg2 from '../../images/loginBackground.jpg'
	import Bg3 from '../../images/logo_mini_lucency.png'
	import {
		blogLogin,
		LoginValidate
	} from '../../js/blog/BlogLogin.js'
	import {
		warningPrompt
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				bg2: Bg2,
				bg3: Bg3,
				//ruleForm表单的参数对象
				ruleForm: {
					account: null,
					password: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: LoginValidate()
			};
		},
		methods: {
			tiaozhuan() {
				this.$router.push('/test') /* 通过代码跳转页面 */
			},

			//登录按钮事件
			submitForm(formName) {
				const _this = this
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						blogLogin(this.ruleForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								localStorage.setItem('Blog-Authorization', res.data)
								this.$router.push({
									path: '/BlogAccountArticleList',
									query: {
										account: this.ruleForm.account
									}
								}) /* 通过代码跳转页面 */
							} else {
								warningPrompt(res.message)
							}
							_this.token = res.data
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//前往注册
			registerButton() {
				this.$router.push({
					path: '/blogRegister'
				})
			},
			//忘记密码
			retrievePasswordButton() {
				this.$router.push({
					path: '/retrievePassword'
				})
			},
		}
	}
</script>
<style>
</style>
