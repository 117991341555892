<template>
	<div style="padding: 1.5% 2%;">
		<div>
			<div style="width: 50%;">
				<div style="float: left;">
					<img style="width: 100%;height: 40px;" src="../../images/logo.png" />
				</div>
				<el-input style="width: 65%;" placeholder="搜索您想知道的博客" v-model="searchKeyword" clearable>
					<template slot="append">
						<el-button icon="el-icon-search" @click="search()"></el-button>
					</template>
				</el-input>
			</div>
		</div>
		<div style="margin: 2% 0;">
			<ul>
				<el-card v-if="articleData == 0 || articleData == null">
					<span v-if="!show">加载中...</span>
					<span v-if="show">没有找到相关数据!</span>
				</el-card>
				<el-card v-for="(item,index) in articleData" class="box-card" :key="index" style="margin: 1% 2%;">
					<div style="text-align: left;">
						<font style="font-size: 16px;color: #3F51B5;"><b>{{item.title}}</b></font>
					</div>
					<div style="text-align: left;">
						<font style="font-size: 12px;color: #000000;">
							{{item.content}}....
							<el-button type="text" @click="getBlogArticleInfoPage(item.account,item.id)">阅读全文</el-button>
						</font>
					</div>
					<el-divider content-position="left">
						<font style="font-size: 10px;color: #7D6C6C;"><b>发表于{{item.gmtCreate}}</b></font>
					</el-divider>
					<div>
						<div style="width:25%;float: right;color: #8F8F8F;">
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-view">
									<font style="color: #33A9F3;font-size: 12px;"> 999+</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-thumb">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleLikeCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-star-off">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCollectionCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-chat-dot-round">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCommentCount}}</font>
								</i>
							</el-button>
						</div>
						<div>
							<el-tag v-for="(tag,tagIndex) in item.tags" type="success" :style="{color: tag.colour,margin: '0 1%'}" size="mini" :key="tagIndex">{{tag.name}}</el-tag>
						</div>
					</div>
				</el-card>
			</ul>
		</div>
		<div style="text-align: center;margin: 1% 0;">
			<!-- 分页插件 -->
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getTitleKeywordArticle
	} from '../../js/blog/BlogSearch.js'
	import {
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'

	export default {
		data() {
			return {
				show: false,
				searchKeyword: this.$route.query.searchKeyword,
				total: null,
				pageNum: 1,
				pageSize: 4,
				getArticleFrom: {
					titleKeyword: null,
					pageNum: null,
					pageSize: null
				},
				//文章信息数据
				articleData: null
			}
		},
		methods: {
			tab(tabName) {
				this.tabName = tabName
			},
			//搜索执行
			search() {
				this.$router.push({
					path: '/blogSearch',
					query: {
						searchKeyword: this.searchKeyword
					}
				})
			},
			//查询相关博客文章
			page(currentPage) {
				this.show = false
				this.getArticleFrom.pageNum = currentPage
				this.getArticleFrom.pageSize = this.pageSize
				this.getArticleFrom.titleKeyword = this.searchKeyword
				getTitleKeywordArticle(this.getArticleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (res.data.list != null) {
							//获取到mavon-editor对象，
							var MarkdwonIt = require('markdown-it'),
								md = new MarkdwonIt()
							var str = null
							var str2 = null
							var result = null
							//把每个文章的内容的mavon-editor符号都转换成html标签，因为是预览显示，不需
							//要样式，所以要把html标签去掉变成纯文本然后取出其中一部分内容作为预览显示
							res.data.list.forEach((item, index) => {
								//转换mavon-editor文本为html文本
								str = md.render(item.content)
								//替换文本内的所有html标签为空，使文本变回纯文本
								str2 = str.replace(/<[^>]+>/g, "  ")
								//切割纯文本的前一段文字作为预览显示的文本
								result = str2.substr(0, 110)
								//赋值修改后的数据
								res.data.list[index].content = result
							})
						}
						this.articleData = res.data.list
						this.total = res.data.total
						this.show = true
						this.backTop()
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//阅读全文事件
			getBlogArticleInfoPage(account, articleId) {
				this.$router.push({
					path: '/articleInfo',
					query: {
						account: account,
						articleId: articleId
					}
				})
			},
			//回到顶部事件
			backTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		},
		created() {
			this.page(1)
		}
	}
</script>

<style>
</style>
