import {
	axiosBlogRequest
} from '../../network/request.js'


//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}

//获取用户是否对指定id用户已关注事件
export function whetherAttention(data) {
	return axiosBlogRequest({
		url: '/whether/attention',
		params: data,
		method: 'GET'
	})
}

//用户对指定id文章点赞或取消点赞
export function accountAttention(data, flag) {
	if (flag) {
		return axiosBlogRequest({
			url: '/cancel/account/attention',
			params: data,
			method: 'PUT'
		})
	} else {
		return axiosBlogRequest({
			url: '/insert/account/attention',
			params: data,
			method: 'POST'
		})
	}
}

//获取指定账户信息事件
export function getAccount(data) {
	return axiosBlogRequest({
		url: '/get/account/open',
		params: data,
		method: 'GET'
	})
}

//获取指定账户归档列表查询统计事件
export function getArticleRecord(data) {
	return axiosBlogRequest({
		url: '/article/record/open',
		params: data,
		method: 'GET'
	})
}

//获取指定账户文章分类及分类文章统计列表事件
export function getArticleClassifyList(data) {
	return axiosBlogRequest({
		url: '/get/article/classify/list/open',
		params: data,
		method: 'GET'
	})
}

//统计未阅读用户消息
export function countCommentNotReadMessage() {
	return axiosBlogRequest({
		url: '/count/comment/not/read/message',
		method: 'GET'
	})
}
