import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载角色权限数据列表
export function getRolePermissions(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/select/role/permission',
		params: data,
		method: 'GET'
	})
}

//移除角色权限
export function removeRolePermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/delete/role/permission',
		params: data,
		method: 'PUT'
	})
}
