<template>
	<div :style="{backgroundImage: 'url(' + bg2 + ')','background-size':'cover',height: '750px'}">
		<vue-particles style="position:absolute" color="#fff" :particleOpacity="0.7" :particlesNumber="60" shapeType="circle" :particleSize="4" linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="lizi">
		</vue-particles>
		<div style="padding-top: 10%;" align="center">
			<div style="text-align: center; width: 40%;">
				<el-card>
					<div align="left">
						<el-button icon="el-icon-arrow-left" @click="goLoginPage()">返回登录</el-button>
					</div>
					<div>
						<h2>
							<el-image style="width: 10%; height: 10%;margin-bottom: -2%;" :src="bg3"></el-image>
							<span style="margin: 10% 0;">博客注册</span>
						</h2>
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="账户" prop="account">
							<el-input prefix-icon="el-icon-user-solid" v-model="ruleForm.account" placeholder="邮箱"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input prefix-icon="el-icon-unlock" type="password" v-model="ruleForm.password" placeholder="密码"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="authCode">
							<el-input v-model="ruleForm.authCode" style="width: 60%;"></el-input>
							<el-button type="primary" @click="getEmailAuthCodeButton()" style="margin: 0 4%;">获取验证码</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">立即注册</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</el-card>
			</div>

		</div>
	</div>
</template>

<script>
	import Bg2 from '../../images/register_background.jpg'
	import Bg3 from '../../images/logo_mini_lucency.png'
	import {
		registerAccount,
		registerAccountValidate,
		getEmailAuthCode
	} from '../../js/blog/BlogRegister.js'
	import {
		successPrompt,
		warningPrompt
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				bg2: Bg2,
				bg3: Bg3,
				//ruleForm表单的参数对象
				ruleForm: {
					account: null,
					password: null,
					authCode: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: registerAccountValidate()
			};
		},
		methods: {
			tiaozhuan() {
				this.$router.push('/test') /* 通过代码跳转页面 */
			},
			goLoginPage() {
				this.$router.push({
					path: "/blogLogin"
				})
			},
			//登录按钮事件
			submitForm(formName) {
				const _this = this
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						registerAccount(this.ruleForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
							_this.token = res.data
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//获取验证码
			getEmailAuthCodeButton() {
				if (this.ruleForm.account == null) {
					warningPrompt("账户不能为空！")
				} else {
					getEmailAuthCode(this.ruleForm).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}

			}
		}
	}
</script>
<style>
</style>
