import {
	axiosBlogRequest
} from '../../network/request.js'


//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}
//获取系统背景图列表
export function getSysBackgroundImage() {
	return axiosBlogRequest({
		url: '/get/sys/background/image',
		method: 'GET'
	})
}

//修改登录用户信息
export function updateLoginAccount(data) {
	if (data.sex == '男') {
		data.sex = 'Man'
	} else if (data.sex == '女') {
		data.sex = 'Female'
	}
	return axiosBlogRequest({
		url: '/update/login/account',
		params: data,
		method: 'PUT'
	})
}

//注意：发送请求时params和data的区别,在使用axios时，注意到配置选项中包含params和data两
//者，他们使用场景不同。1.params是添加到url的请求字符串中的，用于get请求。2.data是添加到
//请求体（body）中的，用于post请求,get请求中不存在data这个选项。
//上传用户头像图片事件
export function uploadImage(data) {
	return axiosBlogRequest({
		url: '/update/account/photo',
		data: data,
		method: 'POST'
	})
}

//修改用户背景图片
export function uploadBackgroundImage(data) {
	return axiosBlogRequest({
		url: '/update/account/background/image',
		data: data,
		method: 'POST'
	})
}

//修改用户背景图片
export function updateAccountBackgroundSysImage(data) {
	return axiosBlogRequest({
		url: '/update/account/background/sys/image',
		params: data,
		method: 'POST'
	})
}

