<template>
	<div>
		<div style="overflow: hidden;position: relative;overflow-x: hidden;">
			<div>
				<el-input style="float: right;width: 30%;margin: 1% 2% -2% 2%;" v-model="searchKeyword" placeholder="请输入搜索内容" size="mini">
						<el-button @click="page(1)" slot="append" icon="el-icon-search"></el-button>
				</el-input>
				<div style="padding: 1% 6%;width: 40%;">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item @click.native="goBlogHomePage()"><i class="el-icon-house"></i><a> 主页 </a></el-breadcrumb-item>
						<el-breadcrumb-item v-if="$route.query.year != null"><a> {{$route.query.year}} 年档 </a></el-breadcrumb-item>
						<el-breadcrumb-item v-if="$route.query.classifyName != null"><a> {{$route.query.classifyName}} 分类 </a></el-breadcrumb-item>
						<el-breadcrumb-item v-if="$route.query.articleTypeName != null"><a> {{$route.query.articleTypeName}} 文章 </a></el-breadcrumb-item>
						<el-breadcrumb-item v-if="$route.query.collection != null"><a> 我的收藏 </a></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<ul>
				<el-card v-if="articleData == 0 || articleData == null">
					没有找到相关数据!
				</el-card>
				<el-card v-for="(item,index) in articleData" class="box-card" :key="index" style="margin: 1% 2%;">
					<div style="text-align: left;">
						<font style="font-size: 16px;color: #3F51B5;"><b>{{item.title}}</b></font>
					</div>
					<div style="text-align: left;">
						<font style="font-size: 12px;color: #000000;">
							{{item.content}}....
							<el-button type="text" @click="getBlogArticleInfoPage(item.id)">阅读全文</el-button>
						</font>
					</div>
					<el-divider content-position="left">
						<font style="font-size: 10px;color: #7D6C6C;"><b>发表于{{item.gmtCreate}}</b></font>
					</el-divider>
					<div>
						<div style="width:25%;float: right;color: #8F8F8F;">
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-view">
									<font style="color: #33A9F3;font-size: 12px;"> 999+</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-thumb">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleLikeCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-star-off">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCollectionCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-chat-dot-round">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCommentCount}}</font>
								</i>
							</el-button>
						</div>
						<div>
							<el-tag v-for="(tag,tagIndex) in item.tags" type="success" :style="{color: tag.colour,margin: '0 1%'}" size="mini" :key="tagIndex">{{tag.name}}</el-tag>
						</div>
					</div>
				</el-card>
			</ul>
			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getArticleByAccount
	} from '../../js/blog/BlogAccountArticleList.js'
	import {
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				years: '123',
				searchKeyword: null, //关键字搜索
				year: this.$route.query.year, //归档年份查询条件
				classifyId: this.$route.query.classifyId, //文章分类查询条件
				articleType: this.$route.query.articleType, //文章类型查询条件
				collection: this.$route.query.collection, //是否是我的收藏
				total: null,
				pageNum: 1,
				pageSize: 4,
				visitAccountFrom: {
					account: this.$route.query.account, //获取路径参数中要访问的账户
					titleKeyword: null,
					year: null, //归档年份查询条件
					classifyId: null, //文章分类查询条件
					articleType: null, //文章类型查询条件
					pageNum: null,
					pageSize: null
				},
				//文章信息数据
				articleData: null
			}
		},
		watch: {
			//监听地址栏参数是否发生变化，发生变化则触发事件(第一次（页面加载）的时候不会触发)
			'$route.query'(newVal, oldVal) {
				this.year = newVal.year
				this.classifyId = newVal.classifyId
				this.articleType = newVal.articleType
				this.collection = newVal.collection
				oldVal

				//清除搜索框数据
				this.searchKeyword = null
				//重新加载数据
				this.page(1)
			},
		},
		methods: {
			goBlogHomePage() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.$route.query.account
					}
				})
			},
			//阅读全文事件
			getBlogArticleInfoPage(articleId) {
				this.$router.push({
					path: '/articleInfo',
					query: {
						account: this.$route.query.account,
						articleId: articleId
					}
				})
			},
			//分页查询账户数据列表
			page(currentPage) {
				this.visitAccountFrom.year = this.year
				this.visitAccountFrom.classifyId = this.classifyId
				this.visitAccountFrom.articleType = this.articleType
				this.visitAccountFrom.pageNum = currentPage
				this.visitAccountFrom.pageSize = this.pageSize
				this.visitAccountFrom.titleKeyword = this.searchKeyword
				getArticleByAccount(this.visitAccountFrom, this.collection).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (res.data.list != null) {
							//获取到mavon-editor对象，
							var MarkdwonIt = require('markdown-it'),
								md = new MarkdwonIt()
							var str = null
							var str2 = null
							var result = null
							//把每个文章的内容的mavon-editor符号都转换成html标签，因为是预览显示，不需
							//要样式，所以要把html标签去掉变成纯文本然后取出其中一部分内容作为预览显示
							res.data.list.forEach((item, index) => {
								//转换mavon-editor文本为html文本
								str = md.render(item.content)
								//替换文本内的所有html标签为空，使文本变回纯文本
								str2 = str.replace(/<[^>]+>/g, "  ")
								//切割纯文本的前一段文字作为预览显示的文本
								result = str2.substr(0, 110)
								//赋值修改后的数据
								res.data.list[index].content = result
							})
						}
						this.articleData = res.data.list
						this.total = res.data.total
						this.backTop()
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//回到顶部事件
			backTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		},
		created() {
			this.page(1)
		}
	}
</script>

<style>
</style>
