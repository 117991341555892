import {
	axiosBlogRequest
} from '../../network/request.js'


//用户是否对该文章已点赞
export function getAccountByKeyword(data) {
	return axiosBlogRequest({
		url: '/get/account/by/keyword',
		params: data,
		method: 'GET'
	})
}

//用户取消关注指定id的用户
export function cancelAccountAttention(data) {
	return axiosBlogRequest({
		url: '/cancel/account/attention',
		params: data,
		method: 'PUT'
	})
}

//用户关注指定id的用户
export function insertAccountAttention(data) {
	return axiosBlogRequest({
		url: '/insert/account/attention',
		params: data,
		method: 'POST'
	})
}
