import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载账户数据列表
export function getAccounts(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/accounts',
		params: data,
		method: 'GET'
	})
}

//修改指定账户状态
export function updateAccountStatus(data) {

	//状态取反，如果当前为正常，点击时选择锁定，如果当前为锁定，点击时选择解锁（0/锁定 1/正常）
	let statusEnum
	if (data.status == '账户正常') {
		statusEnum = "Lock"
	} else {
		statusEnum = "Female"
	}
	return axiosBlogBackgroundRequest({
		url: '/update/account/status',
		params: {
			account: data.account,
			statusEnum: statusEnum
		},
		method: 'PUT'
	})
}
