<template>
	<div>
		<div>
			<!-- 顶部组件 -->
			<BlogIndexHead />
		</div>
		<div>
			<keep-alive>
				<!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
				<router-view v-if="$route.meta.keepAlive" :key="$route.query.searchKeyword" />
			</keep-alive>
			<!-- 这里是不被缓存的视图组件，比如 page3 -->
			<router-view v-if="!$route.meta.keepAlive" :key="$route.query.searchKeyword" />
		</div>
		<div style="postion:relative;bottom:0px;margin:0px;float:left;width:100%;" align="center">
			<div>
				<el-link type="info" style="margin: 0 1%;">关于我们</el-link>
				<el-link type="info" style="margin: 0 1%;">广告服务</el-link>
				<el-link type="info" style="margin: 0 1%;">联系邮箱：1105570390@qq.com</el-link>
			</div>
			<div>
				<el-link type="info" style="margin: 0 1%;" href="https://beian.miit.gov.cn/#/Integrated/index">桂ICP备19008767号</el-link>
				<el-link type="info" style="margin: 0 1%;" href="http://www.cyberpolice.cn/wfjb/">网络110报警服务</el-link>
				<el-link type="info" style="margin: 0 1%;" href="https://www.12377.cn/">中国互联网举报中心</el-link>
				<el-link type="info" style="margin: 0 1%;" href="/rules">版权与免责声明</el-link>
			</div>
		</div>
	</div>
</template>

<script>
	import BlogIndexHead from './BlogIndexHead.vue'
	export default {
		components: {
			BlogIndexHead
		}
	}
</script>

<style>
</style>
