<template>
	<!-- el-container容器 -->
	<el-container style="height: 570px; border: 1px solid #eee">

		<!-- 页面头部 -->
		<el-header style="background-color: rgb(102, 177, 255);">
			<!-- 左侧 -->
			<div style="float: left; text-align: center;font-size: 25px;padding: 10px 10px 0 10px; color: darkgreen;">
				<i class="el-icon-setting"></i>
				博客后台管理
			</div>

			<!-- 右侧 -->
			<div style="float: right;padding: 10px 15px">
				<el-dropdown>
					<el-avatar style="size: 40px;" :src="accountData.imageUrl"></el-avatar><!-- 头像 -->
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>查看</el-dropdown-item>
						<el-dropdown-item>新增</el-dropdown-item>
						<el-dropdown-item>删除</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div style="float: right;margin: 10px 5px 0 5px"><span>{{accountData.nickname}}</span></div>
			</div>

		</el-header>
		<!-- 页面头部End -->

		<!-- el-container容器 -->
		<el-container>

			<!-- el-aside左侧导航 -->
			<el-aside width="200px" style="background-color: rgb(238, 241, 246)">

				<!-- 动态路由导航,要实现动态路由导航， el-menu标签需要加上 router-->
				<!-- el-menu左侧菜单内容  default-openeds：默认展开的菜单的index,unique-opened:是否只保持一个子菜单的展开-->
				<!-- <el-menu router :default-openeds="['0','1']" :unique-opened="true"> -->
				<el-menu router :unique-opened="true">
					<!-- 开启语法检错时v-for和v-if不能作用于同一标签 v-for输出路由作为菜单列表，路由的show判断该列表是否显示 -->
					<template v-for="(item,index) in $router.options.routes">
						<!-- el-submenu可展开菜单 -->
						<el-submenu :index="index+''" v-if="item.show" :key="index">
							<!-- template可展开菜单的名称 -->
							<template slot="title">
								<font style="font-size: 15px;"><b><i :class="item.icon"></i>{{item.name}}</b></font>
							</template>
							<template v-for="(item2,index2) in item.children">
								<!-- el-menu-item菜单列表 -->
								<el-menu-item v-if="item2.show" :index="item2.path" :key="index2" :class="$route.path==item2.path?'is-active':''">{{item2.name}}</el-menu-item>
							</template>
						</el-submenu>
						<!-- el-submenu可展开菜单End -->
					</template>
				</el-menu>
				<!-- el-menu左侧菜单内容End -->

			</el-aside>
			<!-- el-aside左侧导航End -->

			<!-- 显示主体数据 -->
			<el-main>
				<!-- <router-view是路由要跳转的页面的内容显示位置 -->
				<!-- 这里是不被缓存的视图组件，比如 page3 -->
				<router-view />
			</el-main>
			<!-- 显示主体数据 End-->
		</el-container>
		<!-- el-container容器End -->

	</el-container>
	<!-- el-container整个页面布局End -->
</template>

<script>
	import {
		axiosBlogBackgroundRequest,
		requestImage
	} from '../../network/request.js'
	import {
		warningPrompt
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				accountData: null,
				openMenuId: null //展开列表的id
			}
		},
		methods: {
			getAccountList() {
				axiosBlogBackgroundRequest({
					url: '/get/login/account',
					method: 'GET'
				}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData = res.data
						//转换图片地址为完整地址
						this.accountData.imageUrl = requestImage(this.accountData.imageUrl)
					} else {
						warningPrompt(res.message)
					}
				})
			},
		},
		created() {
			this.getAccountList()
		}
	}
</script>

<style>

</style>
