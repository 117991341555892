import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//分页查询指定条件的文章分类
export function getArticleClassifyByCondition(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/article/classify/by/condition',
		params: data,
		method: 'GET'
	})
}

//通过分类审核
export function updateArticleClassifyStatusPass(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/update/article/classify/status/pass',
		params: data,
		method: 'PUT'
	})
}

//拒绝分类审核
export function updateArticleClassifyStatusNotPass(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/update/article/classify/status/not/pass',
		params: data,
		method: 'PUT'
	})
}
