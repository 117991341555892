import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//查询账户详细信息事件
export function getAccountInfo(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/account/by/id',
		params: data,
		method: 'GET'
	})
}
//注意：发送请求时params和data的区别,在使用axios时，注意到配置选项中包含params和data两
//者，他们使用场景不同。1.params是添加到url的请求字符串中的，用于get请求。2.data是添加到
//请求体（body）中的，用于post请求,get请求中不存在data这个选项。
//上传用户头像图片事件
export function uploadImage(data) {
	return axiosBlogBackgroundRequest({
		url: '/update/account/profile/photo',
		data: data,
		method: 'POST'
	})
}

//重置账户密码事件
export function resetPassword(data) {
	return axiosBlogBackgroundRequest({
		url: '/reset/password',
		params: data,
		method: 'PUT'
	})
}

//修改指定账户状态
export function updateAccountStatus(data) {

	//状态取反，如果当前为正常，点击时选择锁定，如果当前为锁定，点击时选择解锁（0/锁定 1/正常）
	let statusEnum
	if (data.status == '账户正常') {
		statusEnum = "Lock"
	} else {
		statusEnum = "Female"
	}
	return axiosBlogBackgroundRequest({
		url: '/update/account/status',
		params: {
			account: data.account,
			statusEnum: statusEnum
		},
		method: 'PUT'
	})
}
