<template>
	<div>
		<div style="float: left;width: 52%;margin: 0 1%;">
			<div style="float: right;height: 45px;">
				<el-form :model="getPermissionsConditionForm">

					<div style="float: left;">
						<el-input style="width: 80%;" placeholder="权限名称" prefix-icon="el-icon-search" v-model="getPermissionsConditionForm.permissionName"></el-input>
					</div>
					<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
				</el-form>
			</div>

			<el-checkbox-group v-model="checkList">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column fixed prop="id" label="权限ID" width="70">
						<template slot-scope="scope">
							<el-checkbox :label="scope.row.id"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column fixed prop="permissionNum" label="权限编号" width="185">
					</el-table-column>
					<el-table-column fixed prop="permissionName" label="权限名称" width="185">
					</el-table-column>
					<el-table-column fixed label="操作" width="135">
						<template slot-scope="scope">
							<el-button @click="deletePermissionButton(scope.row)" type="text" size="small">删除权限</el-button>
							<el-button @click="updatePermissionButton(scope.row)" type="text" size="small">修改权限</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-checkbox-group>
			<div style="text-align: right;padding: 1%;">
				<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-remove" @click="deletePermissionsButton()">批量删除</el-button>
			</div>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getPermissions,
		deletePermissions
	} from '../../../js/background/system_account_manage/PermissionList.js'
	import {
		warningPrompt,
		successPrompt,
		optionPrompt,
		optionPrompt2
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			handleClick(row) {
				console.log(row);
			},
			//分页查询权限数据列表
			page(currentPage) {
				const _this = this
				this.getPermissionsConditionForm.pageNum = currentPage
				this.getPermissionsConditionForm.pageSize = this.pageSize
				getPermissions(this.getPermissionsConditionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//批量删除权限事件
			deletePermissionsButton() {
				//是否执行操作事件
				optionPrompt2().then(() => {
					//在axios中使用params传参，这里获取到权限id的数组对象时，赋值给另一个属性，作为要
					//传递的参数，但是这个参数如果不加上 '' 这个空符串在发起请求的时候url会变
					//成 url?ids[]=&account=** ，传递的ids参数后面多了[]符号，导致请求出错
					this.deletePermissionsForm.ids = this.checkList + ''
					if (this.checkList != '') {
						deletePermissions(this.deletePermissionsForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message);
								//删除成功重新查询显示数据
								this.page(this.getPermissionsConditionForm.pageNum)
							} else {
								warningPrompt(res.message)
							}
						})
					} else {
						warningPrompt("请选择要删除的权限!")
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//删除权限事件
			deletePermissionButton(row) {
				//是否执行操作事件
				optionPrompt(row.permissionName).then(() => {
					this.deletePermissionsForm.ids = row.id
					//发起删除请求
					deletePermissions(this.deletePermissionsForm).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message);
							//删除成功重新查询显示数据
							this.page(this.getPermissionsConditionForm.pageNum)
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//修改权限事件，加载子路由，同时传递参数过去
			updatePermissionButton(row) {
				this.$router.push({
					path: '/updatePermission',
					query: {
						id: row.id
					}
				})
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				total: null,
				checkList: [],
				pageSize: 5,
				//查询权限数据列表需要传递的参数对象
				getPermissionsConditionForm: {
					pageNum: null,
					pageSize: null,
					permissionName: null
				},
				deletePermissionsForm: {
					ids: null
				},
			}
		}
	}
</script>
