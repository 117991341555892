import {
	axiosBlogRequest
} from '../../network/request.js'


//博客首页搜索关键字文章
export function getTitleKeywordArticle(data) {
	return axiosBlogRequest({
		url: '/get/title/keyword/article/open',
		params: data,
		method: 'GET'
	})
}
