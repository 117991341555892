<template>
	<div>
		<div style="text-align: center;padding: 3% 30%;">
			<img style="width: 50%;height: 25%;" src="../../images/logo.png" />
		</div>
		<div style="text-align: center;padding: 0 30%;">
			<el-input placeholder="搜索您想知道的博客" v-model="searchKeyword" clearable>
				<template slot="append">
					<el-button icon="el-icon-search" @click="search()"></el-button>
				</template>
			</el-input>
		</div>
		<div style="text-align: left;padding: 5% 30%;">
			<div style="padding: 0 0 2% 0;">
				<i class="el-icon-view"></i> 热门搜索
			</div>
			<div style="padding-left: 3%;">
				<div style="padding: 1% 0;">
					<!-- <el-link href="#" target="_blank"> ...</el-link> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchKeyword: null
			}
		},
		methods: {
			search() {
				this.$router.push({
					path: '/blogSearch',
					query: {
						searchKeyword: this.searchKeyword
					}
				})
			}
		},
		created() {

		}
	}
</script>

<style>
</style>
