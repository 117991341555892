import {
	axiosBlogRequest
} from '../../network/request.js'


//获取用户文章评论未读读消息事件
export function getCommentNotReadMessages(data) {
	return axiosBlogRequest({
		url: '/get/account/article/comment/not/read/messages',
		params: data,
		method: 'GET'
	})
}

//获取用户文章评论已读读消息事件
export function getCommentReadMessages(data) {
	return axiosBlogRequest({
		url: '/get/account/article/comment/read/messages',
		params: data,
		method: 'GET'
	})
}


//把评论未读消息全部标为已读
export function updateCommentReadMessageStatusAll() {
	return axiosBlogRequest({
		url: '/update/comment/read/message/status/all',
		method: 'PUT'
	})
}

//把评论未读消息标为已读
export function updateCommentReadMessageStatus(data) {
	return axiosBlogRequest({
		url: '/update/comment/read/message/status',
		params: data,
		method: 'PUT'
	})
}
