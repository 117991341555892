import VueRouter from "vue-router"
import Vue from 'vue'
import App from '../App.vue'

//系统用户管理 路由
import BackgroundLogin from '../components/background/BackgroundLogin.vue'
import BackgroundIndex from "../components/background/BackgroundIndex.vue"
import AccountList from '../components/background/system_account_manage/AccountList.vue'
import AccountRolePermission from '../components/background/system_account_manage/AccountRolePermission.vue'
import AccountRolePermissionView from '../components/background/system_account_manage/AccountRolePermissionView.vue'
import AddAcountRoleView from '../components/background/system_account_manage/AddAccountRoleView.vue'
import AddRole from '../components/background/system_account_manage/AddRole.vue'
import RoleList from '../components/background/system_account_manage/RoleList.vue'
import UpdateRole from '../components/background/system_account_manage/UpdateRole.vue'
import RolePermissionView from '../components/background/system_account_manage/RolePermissionView.vue'
import AddRolePermissionView from '../components/background/system_account_manage/AddRolePermissionView.vue'
import AddPermission from '../components/background/system_account_manage/AddPermission.vue'
import PermissionList from '../components/background/system_account_manage/PermissionList.vue'
import UpdatePermission from '../components/background/system_account_manage/UpdatePermission.vue'
import AccountInfo from '../components/background/system_account_manage/AccountInfo.vue'
import UpdateAccount from '../components/background/system_account_manage/UpdateAccount.vue'


//博客用户管理 路由
import BlogAccountList from '../components/background/blog_account_manage/AccountList.vue'
import BlogAccountRolePermission from '../components/background/blog_account_manage/AccountRolePermission.vue'
import BlogAccountRolePermissionView from '../components/background/blog_account_manage/AccountRolePermissionView.vue'
import BlogAddAcountRoleView from '../components/background/blog_account_manage/AddAccountRoleView.vue'
import BlogAddRole from '../components/background/blog_account_manage/AddRole.vue'
import BlogRoleList from '../components/background/blog_account_manage/RoleList.vue'
import BlogUpdateRole from '../components/background/blog_account_manage/UpdateRole.vue'
import BlogRolePermissionView from '../components/background/blog_account_manage/RolePermissionView.vue'
import BlogAddRolePermissionView from '../components/background/blog_account_manage/AddRolePermissionView.vue'
import BlogAddPermission from '../components/background/blog_account_manage/AddPermission.vue'
import BlogPermissionList from '../components/background/blog_account_manage/PermissionList.vue'
import BlogUpdatePermission from '../components/background/blog_account_manage/UpdatePermission.vue'
import BlogAccountInfo from '../components/background/blog_account_manage/AccountInfo.vue'
import BlogUpdateAccount from '../components/background/blog_account_manage/UpdateAccount.vue'

//博客文章管理 路由
import ArticleManageList from '../components/background/article_manage/ArticleManageList.vue'
import ArticleAudit from '../components/background/article_manage/ArticleAudit.vue'
import ArticleAuditManageList from '../components/background/article_manage/ArticleAuditManageList.vue'
import ArticleClassifyAuditManageList from '../components/background/article_manage/ArticleClassifyAuditManageList.vue'

//博客路由
import Test2 from '../components/blog/Test2.vue'
import Rules from '../components/blog/Rules.vue'
import BlogBody from '../components/blog/BlogIndexBody.vue'
import BlogIndex from '../components/blog/BlogIndex.vue'
import BlogSearch from '../components/blog/BlogSearch.vue'
import BlogArticleEdit from '../components/blog/BlogArticleEdit.vue'
import BlogLogin from '../components/blog/BlogLogin.vue'
import RetrievePassword from '../components/blog/RetrievePassword.vue'
import BlogRegister from '../components/blog/BlogRegister.vue'
import BlogAccountIndex from '../components/blog/BlogAccountIndex.vue'
import CommentMessage from '../components/blog/CommentMessage.vue'
import BlogAccountArticleList from '../components/blog/BlogAccountArticleList.vue'
import ArticleInfo from '../components/blog/ArticleInfo.vue'
import BlogAccountArticleManage from '../components/blog/BlogAccountArticleManage.vue'
import BlogArticleUpdate from '../components/blog/BlogArticleUpdate.vue'
import BlogAccountSetting from '../components/blog/BlogAccountSetting.vue'
import BlogAccountMessage from '../components/blog/BlogAccountMessage.vue'
import BlogArticleClassifyManage from '../components/blog/BlogArticleClassifyManage.vue'
import BlogSearchAccount from '../components/blog/BlogSearchAccount.vue'
import BlogAttention from '../components/blog/BlogAttention.vue'
import BlogAttentionFans from '../components/blog/BlogAttentionFans.vue'

//通过Vue.use(插件)，安装插件
Vue.use(VueRouter)

//token储存在本地的字段名称
let BackgroundAuthorization = 'Authorization'
let BlogAuthorization = 'Blog-Authorization'

//创建VueRouter对象
const routers = [{
	path: '/navigatior',
	name: '系统用户管理',
	icon: 'el-icon-menu', //路由动态导航图标
	show: true, //是否显示该路由为动态导航
	component: BackgroundIndex,
	children: [{
		path: '/accountList',
		name: '用户列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: AccountList
	}, {
		path: "/roleList",
		name: '角色列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: RoleList,
		children: [{
			path: "/rolePermissionView",
			name: '角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: RolePermissionView
		}, {
			path: "/addRolePermissionView",
			name: '添加角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: AddRolePermissionView
		}]
	}, {
		path: "/permissionList",
		name: '权限列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: PermissionList
	}, {
		path: "/addRole",
		name: '添加角色',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: AddRole
	}, {
		path: "/addPermission",
		name: '添加权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: AddPermission
	}, {
		path: "/accountRolePermission",
		name: '用户角色权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: AccountRolePermission,
		children: [{
			path: "/accountRolePermissionView",
			name: '用户角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: AccountRolePermissionView
		}, {
			path: "/addAcountRoleView",
			name: '添加用户角色视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: AddAcountRoleView
		}]
	}, {
		path: "/accountInfo",
		name: '账户详情',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: AccountInfo
	}, {
		path: "/updateAccount",
		name: '修改账户信息',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: UpdateAccount
	}, {
		path: "/updateRole",
		name: '修改角色',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: UpdateRole
	}, {
		path: "/updatePermission",
		name: '修改权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: UpdatePermission
	}]
}, {
	path: '/navigatior',
	name: '博客用户管理',
	icon: 'el-icon-menu', //路由动态导航图标
	show: true, //是否显示该路由为动态导航
	component: BackgroundIndex,
	children: [{
		path: '/blogAccountList',
		name: '博客用户列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogAccountList
	}, {
		path: "/blogRoleList",
		name: '博客角色列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogRoleList,
		children: [{
			path: "/blogRolePermissionView",
			name: '博客角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: BlogRolePermissionView
		}, {
			path: "/blogAddRolePermissionView",
			name: '博客添加角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: BlogAddRolePermissionView
		}]
	}, {
		path: "/blogPermissionList",
		name: '博客权限列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogPermissionList
	}, {
		path: "/blogAddRole",
		name: '博客添加角色',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogAddRole
	}, {
		path: "/blogAddPermission",
		name: '博客添加权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogAddPermission
	}, {
		path: "/blogAccountRolePermission",
		name: '博客用户角色权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: BlogAccountRolePermission,
		children: [{
			path: "/blogAccountRolePermissionView",
			name: '博客用户角色权限视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: BlogAccountRolePermissionView
		}, {
			path: "/blogAddAcountRoleView",
			name: '博客添加用户角色视图',
			meta: {
				requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				keepAlive: true, //是否缓存此组件
				Authorization: BackgroundAuthorization //路由验证时需要的属性
			},
			component: BlogAddAcountRoleView
		}]
	}, {
		path: "/blogAccountInfo",
		name: '博客账户详情',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: BlogAccountInfo
	}, {
		path: "/blogUpdateAccount",
		name: '博客修改账户信息',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: BlogUpdateAccount
	}, {
		path: "/blogUpdateRole",
		name: '博客修改角色',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: BlogUpdateRole
	}, {
		path: "/blogUpdatePermission",
		name: '博客修改权限',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: BlogUpdatePermission
	}]
}, {
	path: '/navigatior',
	name: '博客文章管理',
	icon: 'el-icon-notebook-2', //路由动态导航图标
	show: true, //是否显示该路由为动态导航
	component: BackgroundIndex,
	children: [{
		path: "/articleManageList",
		name: '博客文章管理列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: ArticleManageList
	}, {
		path: "/articleAudit",
		name: '博客文章审核',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: false, //是否显示该路由为动态导航
		component: ArticleAudit
	}, {
		path: "/articleAuditManageList",
		name: '博客文章审核列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: ArticleAuditManageList
	}, {
		path: "/articleClassifyAuditManageList",
		name: '博客文章分类审核列表',
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true, //是否缓存此组件
			Authorization: BackgroundAuthorization //路由验证时需要的属性
		},
		show: true, //是否显示该路由为动态导航
		component: ArticleClassifyAuditManageList
	}]
}, {
	path: '/',
	name: '重定向到博客首页页',
	show: false, //是否显示该路由为动态导航
	component: App,
	redirect: '/blogIndex'
}, {
	path: '/backgroundLogin',
	name: '后台登录页面',
	show: false, //是否显示该路由为动态导航
	component: BackgroundLogin
}, {
	path: '/backgroundIndex',
	name: '后台登录首页',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		keepAlive: true //是否缓存此组件
	},
	component: BackgroundIndex
}, {
	path: '/blogLogin',
	name: '博客登录',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
	},
	component: BlogLogin
}, {
	path: '/blogBody',
	name: '博客首页主体',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
	},
	component: BlogBody,
	children: [{
		path: '/blogIndex',
		name: '博客首页',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false //是否缓存此组件
		},
		component: BlogIndex
	}, {
		path: '/blogSearch',
		name: '博客搜索',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true //是否缓存此组件
		},
		component: BlogSearch
	}]
}, {
	path: '/blogRegister',
	name: '博客注册',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
	},
	component: BlogRegister
}, {
	path: '/retrievePassword',
	name: '找回密码',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
		keepAlive: true //是否缓存此组件
	},
	component: RetrievePassword
}, {
	path: '/blogAccountIndex',
	name: '博客用户首页',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
		keepAlive: true //是否缓存此组件
	},
	component: BlogAccountIndex,
	children: [{
		path: '/commentMessage',
		name: '博客用户评论消息',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: CommentMessage
	}, {
		path: '/blogAccountArticleList',
		name: '博客用户文章页条件查询列表',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: true //是否缓存此组件
		},
		component: BlogAccountArticleList
	}, {
		path: '/articleInfo',
		name: '博客用户文章页详情',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
			keepAlive: false //是否缓存此组件
		},
		component: ArticleInfo
	}, {
		path: '/blogAttention',
		name: '用户关注',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogAttention
	}, {
		path: '/blogSearchAccount',
		name: '用户搜索',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogSearchAccount
	}, {
		path: '/blogAttentionFans',
		name: '用户粉丝',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogAttentionFans
	}, {
		path: '/blogAccountArticleManage',
		name: '博客用户文章管理',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogAccountArticleManage
	}, {
		path: '/blogArticleClassifyManage',
		name: '博客用户分类管理',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogArticleClassifyManage
	}, {
		path: '/blogAccountSetting',
		name: '账户设置',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogAccountSetting
	}, {
		path: '/blogAccountMessage',
		name: '关于账户',
		show: false, //是否显示该路由为动态导航
		meta: {
			requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
			Authorization: BlogAuthorization, //路由验证时需要的属性
			keepAlive: true //是否缓存此组件
		},
		component: BlogAccountMessage
	}]
}, {
	path: '/blogArticleEdit',
	name: '博客文章编辑',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		Authorization: BlogAuthorization, //路由验证时需要的属性
		keepAlive: true //是否缓存此组件
	},
	component: BlogArticleEdit
}, {
	path: '/blogArticleUpdate',
	name: '博客文章修改',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
		Authorization: BlogAuthorization, //路由验证时需要的属性
		keepAlive: false //是否缓存此组件
	},
	component: BlogArticleUpdate
}, {
	path: '/rules',
	name: '博客条例',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
		keepAlive: true //是否缓存此组件
	},
	component: Rules
}, {
	path: '/test2',
	name: '测试2',
	show: false, //是否显示该路由为动态导航
	meta: {
		requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
	},
	component: Test2
}]


const router = new VueRouter({
	//配置路由和组件之间的关系
	routes: routers,
	mode: 'history'
})


/*
 * 路由跳转前做一些验证
 * 路由routers中多添加一个自定义字段 requireAuth 用来判断是否需要登陆验证才能跳转的判断，如果token失效
 * 了，但是token依然保存在本地。这时候你去访问需要登录权限的路由时，实际上应该让用户重新登录。这时候就需
 * 要结合 http 拦截器 + 后端接口返回的http 状态码来判断。在发送请求之前或之后做一次token的验证，当后端
 * 接口返回 401 Unauthorized（未授权） ，让用户重新登录。
 */
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem(to.meta.Authorization) != null) { // 根据路由属性中token存储的名称获取到token，判断token是否存在
			next();
		} else {
			//根据路由属性中token存储的名称来决定token失效时要重定向的路径
			let redirectPath = null
			if (to.meta.Authorization == 'Authorization') {
				redirectPath = '/backgroundLogin'
			} else if (to.meta.Authorization == 'Blog-Authorization') {
				redirectPath = '/blogLogin'
			}

			//重定向页面
			next({
				path: redirectPath,
				query: {
					redirect: to.fullPath
				} // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})

		}
	} else {
		next();
	}
})

//导出路由，将路由对象传入到Vue实例中
export default router
