<template>
	<div>
		<el-form :model="articleDataFrom" :rules="rules" ref="articleDataFrom" label-width="100px" class="demo-ruleForm">
			<div>
				<div>
					<el-form-item prop="title">
						<el-button plain style="font-size: 15px;margin-right: 1%;" @click="goMyBlogPage()"><i class="el-icon-arrow-left"></i>我的博客</el-button>
						<el-input style="width: 60%;" v-model="articleDataFrom.title" show-word-limit maxlength="100" placeholder="欢迎使用markdwon文档编辑器"></el-input>
						<el-button type="primary" plain style="font-size: 15px;margin: 0% 1%;" @click="test()">保存草稿</el-button>
						<el-button type="primary" plain style="font-size: 15px;" @click="openPublishArticle = true">发布文章</el-button>
						<div style="float: right;padding: 0 3%;">
							<el-avatar :size="40" :src="accountImage"></el-avatar><!-- 头像 -->
						</div>
					</el-form-item>
				</div>
			</div>
			<div>
				<el-form-item prop="content">
					<mavon-editor style="height: 500px;white-space: pre-wrap;word-wrap: break-word;" v-model="articleDataFrom.content" ref="md" @imgAdd="imgAdd"></mavon-editor>
				</el-form-item>
			</div>

			<!-- 动态弹出发布文章元素 -->
			<el-dialog title="发布文章" :visible.sync="openPublishArticle">
				<div>
					<div>
						<el-tag type="danger"><i class="el-icon-warning-outline"></i>请勿发布涉及政治、广告、营销、翻墙、违反国家法律法规等内容，详见<a href="/rules">《博客资源共享规范》</a></el-tag>
					</div>
					<div style="margin: 3% 0%;">
						<el-form-item label="文章标签:">
							<template v-for="(item,index) in checkList">
								<div style="float: left;padding: 0 0.5%;" :key="index">
									<template v-for="(item2,index2) in tagData">
										<el-tag v-if="item == item2.id" :key="index2">{{item2.name}}</el-tag>
									</template>
								</div>
							</template>
							<el-button style="margin: 0 0.5%;" size="small" @click="openArticleTag = true"><i class="el-icon-minus"></i>/<i class="el-icon-plus"></i> 文章标签</el-button>
						</el-form-item>
					</div>
					<div style="margin: 3% 0%;text-align: left;">
						<el-form-item label="文章分类:" prop="classifyId">
							<el-select v-model="articleDataFrom.classifyId" size="small" placeholder="请选择">
								<el-option v-for="item in articleClassify" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
							<el-button style="margin: 0 0.5%;" size="small" @click="openArticleClassify = true"><i class="el-icon-plus"></i>创建文章分类</el-button>
						</el-form-item>
					</div>
					<div style="margin: 3% 0%;">
						<el-form-item label="文章类型:" prop="articleType">
							<el-select v-model="articleDataFrom.articleType" size="small" placeholder="请选择">
								<el-option v-for="item in articleType" :key="item.articleType" :label="item.name" :value="item.articleType">
								</el-option>
							</el-select>
							<el-tag style="margin: 0 1%;" type="info"><i class="el-icon-info"></i>如果不是原创文章，请选择转载。</el-tag>
						</el-form-item>
						<el-form-item v-if="articleDataFrom.articleType == 2" label="原文地址" prop="originalUrl">
							<el-input v-model="articleDataFrom.originalUrl" style="width: 80%;" size="small" show-word-limit maxlength="100"></el-input>
						</el-form-item>
					</div>
					<div style="margin: 3% 0%;">
						<el-form-item label="发布形式:" prop="publishType">
							<el-radio v-model="articleDataFrom.publishType" label="1">公开</el-radio>
							<el-radio v-model="articleDataFrom.publishType" label="2">私密</el-radio>
							<el-radio v-model="articleDataFrom.publishType" label="3">粉丝可见</el-radio>
						</el-form-item>
					</div>
					<div style="margin: 3% 0%;padding-right: 5%;text-align: right;">
						<el-button type="success" size="medium" @click="submitForm('articleDataFrom')">发布文章</el-button>
					</div>
				</div>
			</el-dialog>
			<!-- 动态弹出文章标签元素 -->
			<el-dialog title="选择/取消 文章标签" :visible.sync="openArticleTag">
				<el-checkbox-group v-model="checkList">
					<template v-for="(item,index) in tagData">
						<el-checkbox :label="item.id" :key="index">{{item.name}}</el-checkbox>
					</template>
				</el-checkbox-group>
			</el-dialog>
		</el-form>

		<!-- 动态弹出文章创建元素 -->
		<el-form class="demo-ruleForm" :model="articleClassifyFrom" label-width="100px" :rules="articleClassifyFromRules" ref="articleClassifyFrom">
			<el-dialog title="创建文章分类" :visible.sync="openArticleClassify">
				<el-form-item prop="name">
					<el-input style="width: 60%;" v-model="articleClassifyFrom.name" show-word-limit maxlength="6" placeholder="请输入文章分类名称"></el-input>
				</el-form-item>
				<div style="text-align: right;">
					<el-button type="primary" size="medium" @click="insertAccountArticleClassifyButton('articleClassifyFrom')">创建分类</el-button>
				</div>
			</el-dialog>
		</el-form>
	</div>
</template>

<script>
	import {
		getTags,
		getLoginAccount,
		getLoginArticleClassify,
		getArticleById,
		articleFromValidate,
		articleClassifyFromValidate,
		insertAccountArticleClassify,
		updateArticle,
		uploadArticleImage
	} from '../../js/blog/BlogArticleUpdate.js'
	import {
		requestBlogImage,
		requestBlogArticleTemporaryImage
	} from '../../network/request.js'
	import {
		warningPrompt,
		successPrompt,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				accountData: null,
				accountId: null,
				accountImage: null,
				html: null,
				//读取文章必要数据
				articleFrom: {
					articleId: null,
				},
				//文章提交必要数据
				articleDataFrom: {
					accountId: null,
					classifyId: null, //文章分类id
					articleType: null, //文章类型id
					originalUrl: null, //原文地址
					publishType: null, //文章发布形式id
					title: null,
					content: '# 欢迎使用Markdown编辑器 \n\n你好！ 如果你第一次使用 **Markdown编辑器** ，想学习如何使用Markdown编辑器, 可以仔细阅读右上角的markdown语法帮助，了解一下Markdown的基本语法知识。\n\n### 更多操作...',
					articleTag: null
				},
				//文章分类提交必要数据
				articleClassifyFrom: {
					name: null
				},
				articleClassifyFromRules: articleClassifyFromValidate(), //绑定articleClassifyFrom表单需要验证的参数验证
				tagData: null, //文章标签数据
				rules: articleFromValidate(), //绑定ruleForm表单需要验证的参数验证
				openPublishArticle: false, //是否打开发布文章元素
				openArticleTag: false, //是否打开添加文章标签元素
				openArticleClassify: false, //是否打开添加文章分类元素
				checkList: [],
				articleClassify: null, //文章分类数据
				//文章类型数据
				articleType: [{
					articleType: 1,
					name: '原创'
				}, {
					articleType: 2,
					name: '转载'
				}]
			}
		},
		methods: {
			test(){
				warningPromptOpen4('该功能暂未开放!')
			},
			goMyBlogPage() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.accountData.account
					}
				})
			},
			getLoginAccount() {
				//发送请求
				getLoginAccount().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData = res.data
						//转换图片地址为完整地址
						this.accountData.imageUrl = requestBlogImage(this.accountData.imageUrl)
						this.accountImage = this.accountData.imageUrl
						this.accountId = this.accountData.id
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定id文章事件
			getArticleByIdButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				getArticleById(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleDataFrom = res.data 
						this.articleDataFrom.publishType = res.data.publishType + ''
						//选中文章原来选取的标签
						res.data.tags.forEach(tag =>{
							this.checkList.push(tag.id)
						})
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取文章标签事件
			getTagsButton() {
				getTags().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.tagData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取文章分类事件
			getLoginArticleClassifyButton() {
				getLoginArticleClassify().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleClassify = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//添加文章分类事件
			insertAccountArticleClassifyButton(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						insertAccountArticleClassify(this.articleClassifyFrom).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								//重新查询登录用户文章分类
								this.getLoginArticleClassifyButton()
								successPrompt(res.message)
								this.openArticleClassify = false
							} else {
								warningPrompt(res.message)
							}
						})

					}
				});
			},
			// 将图片上传到服务器，返回地址替换到md中
			imgAdd(pos, $file) {
				let formdata = new FormData();
				formdata.append('image', $file);
				//访问后台服务器方法
				uploadArticleImage(formdata).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.$refs.md.$img2Url(pos, requestBlogArticleTemporaryImage(res.data));
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//发布文章事件
			submitForm(formName) {
				//如果文章是转载类型的文章，则原文地址不能为空
				if (this.articleDataFrom.articleType == 2) {
					if (this.articleDataFrom.originalUrl == null || this.articleDataFrom.originalUrl.trim() == '') {
						warningPrompt('转载文章请附上原文链接!')
						return
					}
				}
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//在axios中使用params传参，数组作为要传递的参数，但是这个参数如果不加上 '' 这个空符串在
						//发起请求的时候url会变成 url?ids[]=&account=** ，传递的ids参数后面多了[]符号，导致请求出错
						//在axios中使用data传参的时候参数包含数组不需要加上''空符串，同时后台要用集合接收，不然报错，原因未知
						this.articleDataFrom.articleTag = this.checkList
						this.articleDataFrom.accountId = this.accountId

						updateArticle(this.articleDataFrom).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
								this.openPublishArticle = false
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			}
		},
		created() {
			this.getArticleByIdButton()
			this.getTagsButton()
			this.getLoginArticleClassifyButton()
			this.getLoginAccount()
		}
	}
</script>

<style>
</style>
