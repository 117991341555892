<template>
	<div>
		<div>
			<div style="text-align: center;margin: 10% auto 2% auto;">
				<h2>博客后台管理系统</h2>
			</div>
			<div style="text-align: center; width: 35%;margin: 0 0 0 30%;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="账户" prop="account">
						<el-input prefix-icon="el-icon-user-solid" v-model="ruleForm.account"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="ruleForm.password"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即登录</el-button>
						<el-button @click="resetForm('ruleForm')">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		backroundLogin,LoginValidate
	} from '../../js/background/BackgroundLogin.js'
	import {
		warningPrompt
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				//ruleForm表单的参数对象
				ruleForm: {
					account: null,
					password: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: LoginValidate()
			};
		},
		methods: {
			tiaozhuan() {
				this.$router.push('/test') /* 通过代码跳转页面 */
			},
			
			//登录按钮事件
			submitForm(formName) {
				const _this = this
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {

						//发送请求
						backroundLogin(this.ruleForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								localStorage.setItem('Authorization', res.data)
								this.$router.push('/accountList') /* 通过代码跳转页面 */
							} else {
								warningPrompt(res.message)
							}
							_this.token = res.data
						})

					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		}
	}
</script>
<style>
</style>
