import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//根据权限id获取角色信息事件
export function getPermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/permission/by/permission/id',
		params: {
			id: data
		},
		method: 'get'
	})
}
//修改角色事件
export function updatePermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/update/permission',
		params: data,
		method: 'PUT'
	})
}


//添加角色数据验证
export function updatePermissionDataValidata() {
	return {
		permissionNum: [{
				required: true,
				message: '请填写角色编号',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		],
		permissionName: [{
				required: true,
				message: '请填写角色名称',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		]
	}
}
