import {
	axiosBlogRequest
} from '../../network/request.js'


//获取用户的粉丝列表事件
export function getAccountAttentionFans(data) {
	return axiosBlogRequest({
		url: '/get/account/attention/fans',
		params: data,
		method: 'GET'
	})
}

//获取指定用户被关注人数事件
export function getAccountFansSize() {
	return axiosBlogRequest({
		url: '/get/account/fans/size',
		method: 'GET'
	})
}

//用户取消关注指定id的用户
export function cancelAccountAttention(data) {
	return axiosBlogRequest({
		url: '/cancel/account/attention',
		params: data,
		method: 'PUT'
	})
}

//用户关注指定id的用户
export function insertAccountAttention(data) {
	return axiosBlogRequest({
		url: '/insert/account/attention',
		params: data,
		method: 'POST'
	})
}
