<template>
	<div>
		<div style="float: left;width: 34%;margin: 0 1%;">
			<div style="float: right;height: 45px;">
				<el-form :model="getPermissionForm">

					<div style="float: left;">
						<el-input style="width: 80%;" placeholder="权限名称" prefix-icon="el-icon-search" v-model="getPermissionForm.permissionName"></el-input>
					</div>
					<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
				</el-form>
			</div>

			<div>
				<el-checkbox-group v-model="checkList">
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column fixed label="权限ID" width="85">
							<template slot-scope="scope">
								<el-checkbox :label="scope.row.id"></el-checkbox>
							</template>
						</el-table-column>
						<el-table-column fixed prop="permissionNum" label="权限编号" width="145">
						</el-table-column>
						<el-table-column fixed prop="permissionName" label="权限名称" width="145">
						</el-table-column>
					</el-table>
				</el-checkbox-group>
				<div style="text-align: right;padding: 1%;">
					<font style="color: rgb(140, 197, 255);">当前角色:【<font style="color: blue">{{$route.query.roleName}}</font>】</font>
					<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-circle-plus-outline" @click="addRolePermissionButton()">添加</el-button>
				</div>
			</div>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getPermissionByPermissionName,
		addRolePermission
	} from '../../../js/background/system_account_manage/AddRolePermissionView.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5,
				checkList: [],
				addRolePermissionData: {
					ids: null, //权限id组
					id: this.$route.query.id //角色id
				},
				//查询权限数据列表需要传递的参数对象
				getPermissionForm: {
					pageNum: null,
					pageSize: null,
					permissionName: null
				}
			}
		},
		methods: {
			//按角色名称模糊分页查询角色数据
			page(currentPage) {
				const _this = this
				this.getPermissionForm.pageNum = currentPage
				this.getPermissionForm.pageSize = this.pageSize
				getPermissionByPermissionName(this.getPermissionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//添加账户角色
			addRolePermissionButton() {
				//在axios中使用params传参，这里获取到角色id的数组对象时，赋值给另一个属性，作为要
				//传递的参数，但是这个参数如果不加上 '' 这个空符串在发起请求的时候url会变
				//成 url?ids[]=&account=** ，传递的ids参数后面多了[]符号，导致请求出错
				this.addRolePermissionData.ids = this.checkList + ''
				if (this.checkList != '') {
					addRolePermission(this.addRolePermissionData).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message);
						} else {
							warningPrompt(res.message)
						}
					})
				} else {
					warningPrompt("请选择要添加的权限!")
				}
			}
		},
		created() {
			this.page(1)
		}
	}
</script>

<style>
</style>
