<template>
	<div style="height: 650px;background-color: #F5F6F7;">
		<div style="padding-top: 10%;margin: 0 30%;">
			<el-card>
				<div slot="header" class="clearfix">
					<span>找回密码</span>
					<el-button style="float: left;margin: -0.5% 2% 0 1%;" size="mini" icon="el-icon-arrow-left" @click="goLoginPage()">返回</el-button>
				</div>
				<div>
					<div>
						<el-steps :active="active" finish-status="success">
							<el-step title="验证邮箱"></el-step>
							<el-step title="获取验证码"></el-step>
							<el-step title="输入新的密码"></el-step>
						</el-steps>
					</div>
					<div style="margin: 3% 0;">
						<div v-if="active == 0">
							<el-input prefix-icon="el-icon-user-solid" v-model="ruleForm.account" placeholder="邮箱"></el-input>
						</div>
						<div v-if="active == 1">
							<el-input v-model="ruleForm.authCode" style="width: 72%;" placeholder="输入验证码"></el-input>
							<el-button type="primary" @click="getEmailAuthCodeButton()" style="margin: 0 2%;">
								<span v-if="!code">获取验证码</span>
								<span v-if="code">重新发送</span>
							</el-button>
						</div>
						<div v-if="active == 2">
							<el-input prefix-icon="el-icon-unlock" type="password" v-model="ruleForm.password" placeholder="新密码"></el-input>
						</div>
						<div v-if="active == 3" align="center">
							<span style="color: green;font-size: 20px;"><i class="el-icon-check"></i>密码修改成功!</span>
						</div>
					</div>
					<div align="right">
						<el-button  v-if="active != 3" style="margin-top: 12px;" @click="next">下一步</el-button>
					</div>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		validationEmail,
		getEmailAuthCode,
		validationCode,
		retrievePassword
	} from '../../js/blog/RetrievePassword.js'
	import {
		successPrompt,
		warningPrompt
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				active: 0,
				code: false,
				//ruleForm表单的参数对象
				ruleForm: {
					account: null,
					password: null,
					authCode: null
				},
			};
		},
		methods: {
			next() {
				if (this.active == 0) {
					this.validationEmailButton()
				} else if (this.active == 1) {
					this.validationCodeButton()
				} else if (this.active == 2) {
					this.retrievePasswordButton()
				}
			},
			goLoginPage() {
				this.$router.push({
					path: "/blogLogin"
				})
			},
			//验证邮箱
			validationEmailButton() {
				//发送请求
				validationEmail(this.ruleForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (this.active++ > 2) this.active = 0
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//验证邮箱
			validationCodeButton() {
				//发送请求
				validationCode(this.ruleForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (this.active++ > 2) this.active = 0
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//重置密码
			retrievePasswordButton() {
				//发送请求
				retrievePassword(this.ruleForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (this.active++ > 2) this.active = 0
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//获取验证码
			getEmailAuthCodeButton() {
				if (this.ruleForm.account == null) {
					warningPrompt("账户不能为空！")
				} else {
					getEmailAuthCode(this.ruleForm).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.code = true
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}
			}
		}
	}
</script>

<style>
</style>
