<template>
	<div style="overflow: hidden;position: relative;overflow-x: hidden;">
		<el-card class="box-card" style="margin: 1% 2%;">
			<div slot="header" class="clearfix">
				<span><b> 我的粉丝 </b></span>
				<span style="float: right; padding: 1%"><b> 共 {{accountAttentionFansSize}} 人 </b></span>
			</div>
			<div>
				<div v-for="(item,index) in accountAttentionFansData" :key="index">
					<div style="float: left;">
						<el-button type="text" @click="goBlogHomePage(item.account)">
							<el-avatar :size="40" :src="requestBlogImageButton(item.imageUrl)"></el-avatar><!-- 头像 -->
						</el-button>
					</div>
					<div style="float: left;padding: 1% 2%">
						<el-button type="text" @click="goBlogHomePage(item.account)">
							{{item.nickname}}
						</el-button>
					</div>
					<div style="text-align: right;">
						<el-button v-if="item.whetherAttention" type="danger" plain @click="cancelAccountAttentionButton(item.id,index)">
							取消关注
						</el-button>
						<el-button v-if="!item.whetherAttention" type="primary" plain @click="insertAccountAttentionButton(item.id,index)">
							互相关注
						</el-button>
					</div>
					<el-divider></el-divider>
				</div>
			</div>
			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getAccountAttentionFans,
		getAccountFansSize,
		cancelAccountAttention,
		insertAccountAttention
	} from '../../js/blog/BlogAttentionFans.js'
	import {
		requestBlogImage
	} from '../../network/request.js'
	import {
		warningPromptOpen4,
		successPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				total: null,
				accountAttentionFansSize: null,
				accountAttentionFansData: null,
				pageNum: 1,
				pageSize: 4,
				getAccountAttentionFansFrom: {
					pageNum: null,
					pageSize: null
				}
			}
		},
		methods: {
			goBlogHomePage(account) {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: account
					}
				})
				this.$router.go(0)
			},
			requestBlogImageButton(url) {
				return requestBlogImage(url)
			},
			//分页查询账户数据列表
			page(currentPage) {
				this.getAccountAttentionFansFrom.pageNum = currentPage
				this.getAccountAttentionFansFrom.pageSize = this.pageSize
				getAccountAttentionFans(this.getAccountAttentionFansFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountAttentionFansData = res.data.list
						this.total = res.data.total
						this.backTop()
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取用户粉丝人数
			getAccountFansSizeButton() {
				getAccountFansSize().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountAttentionFansSize = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//用户取消关注指定id的用户
			cancelAccountAttentionButton(id, index) {
				cancelAccountAttention({
					id: id
				}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.getAccountFansSizeButton()
						this.accountAttentionFansData[index].whetherAttention = false
						successPromptOpen4(res.message)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//用户关注指定id的用户
			insertAccountAttentionButton(id, index) {
				insertAccountAttention({
					id: id
				}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.getAccountFansSizeButton()
						this.accountAttentionFansData[index].whetherAttention = true
						successPromptOpen4(res.message)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//回到顶部事件
			backTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		},
		created() {
			this.page(1)
			this.getAccountFansSizeButton()
		}
	}
</script>

<style>
</style>
