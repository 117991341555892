import {
	axiosBlogRequest
} from '../../network/request.js'


//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}