<template>
	<div style="padding: 1% 2%;">
		<div style="overflow: hidden;position: relative;overflow-x: hidden;padding: 1% 2%;">
			<el-card class="box-card" v-if="articleData.id == null">
				<font style="color: #F56C6C;">啊哦~你想找的内容离你而去了哦！</font>
			</el-card>
			<el-card class="box-card" v-if="articleData.id != null" style="height: 730px;overflow: auto;margin-right: -5%;">
				<div>
					<font style="font-size: 25px;"><b>{{articleData.title}}</b></font>
				</div>
				<div>
					<el-collapse style="margin: 1% 0 1% 0;">
						<el-collapse-item>
							<template slot="title">
								<el-button v-if="articleData.articleType == 1" size="mini" type="danger" round>原创</el-button>
								<el-button v-if="articleData.articleType == 2" size="mini" type="warning" round>转载</el-button>
								<el-link :underline="false" type="primary" style="margin: 0 1% 0 1%;">{{articleData.nickname}}</el-link>
								<font style="font-size: 11px;color: #7D6C6C;margin: 0 1% 0 1%;"><b>{{articleData.gmtCreate}}</b></font>
								<font style="margin: 0 0 0 1%;color: #999AAA;font-size: 11px;">文章标签:</font>
								<el-tag v-for="(item,index) in articleData.tags" type="success" :style="{color: item.colour,margin: '0 0.5%'}" :key="index">{{item.name}}</el-tag>
								<div style="float: right;margin-left: 5%;">
									<i class="el-icon-view">
										<font style="color: #33A9F3;font-size: 12px;"> 9999+</font>
									</i>
									<i class="el-icon-thumb">
										<font style="color: #33A9F3;font-size: 12px;"> 9999+</font>
									</i>
									<i class="el-icon-chat-dot-round">
										<font style="color: #33A9F3;font-size: 12px;"> 9999+</font>
									</i>
									<i class="el-icon-star-off">
										<font style="color: #33A9F3;font-size: 12px;"> 9999+</font>
									</i>
								</div>
							</template>
							<div>
								<font style="color: #6F6F82;">
									版权声明：本文为博主原创文章，遵重版权，转载请附上原文出处链接和本声明。
									<br />
									本文链接： <el-link type="primary" :underline="false" href="#"></el-link>
								</font>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>

				<!-- 文章内容 -->
				<div class="content markdown-body" style="white-space: pre-wrap;word-wrap: break-word;" v-html="articleData.content"></div>

				<!-- 分割线 -->
				<el-divider content-position="left">
					<font style="font-size: 10px;color: #7D6C6C;"></font>
				</el-divider>

				<!-- 底部栏 -->
				<div style="text-align: right;padding: 0 5%;">
					<el-button type="primary" @click="centerDialogVisible = true">审核</el-button>
					<el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
						<span v-if="sensitive != 0" style="color: red;"><b>文章存在以下敏感词汇：</b>{{sensitive}}</span>
						<span v-if="sensitive == 0" style="color: green;">未扫描到该文章存在敏感词!</span>
						<span slot="footer" class="dialog-footer">
							<el-button type="danger" @click="articleAuditNotPassButton()">拒绝通过</el-button>
							<el-button type="primary" @click="articleAuditPassButton()">审核通过</el-button>
						</span>
					</el-dialog>
				</div>
			</el-card>
			<!-- visibility-height：滚动高度达到此参数值才出现 right、bottom控制其显示位置 -->
			<el-backtop :bottom="50">
				<div @mouseenter="mouseenter()" @mouseleave="mouseleave()" style="{height: 250px;width: 100%;background-color: #f2f5f6;box-shadow: 0 0 6px rgba(0,0,0, .12);text-align: center;line-height: 40px;color: #1989fa;}">
					<div v-html="top"></div>
				</div>
			</el-backtop>
		</div>
	</div>
</template>

<script>
	import 'github-markdown-css/github-markdown.css'
	import {
		getArticleById,
		getArticleSensitiveById,
		articleAuditPass,
		articleAuditNotPass
	} from '../../../js/background/article_manage/ArticleAudit.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				top: '<i class="el-icon-upload2"></i>',
				centerDialogVisible: false,
				sensitive: null,
				//文章数据
				articleData: {
					id: null,
					accountId: null,
					nickname: null,
					title: null,
					content: null,
					status: null,
					classifyId: null,
					articleType: null,
					publishType: null,
					gmtCreate: null,
					gmtModify: null,
					tags: null
				},
				//读取文章必要数据
				articleFrom: {
					articleId: null,
				}
			}
		},
		methods: {
			mouseenter() {
				this.top = '<font style="font-size: 70%;">回到顶部</font>'
			},
			mouseleave() {
				this.top = '<i class="el-icon-upload2"></i>'
			},
			//文章内容格式转换方法
			markdownCompile(content) {
				var MarkdwonIt = require('markdown-it'),
					md = new MarkdwonIt()

				//转换mavon-editor文本为html文本
				var result = md.render(content)
				return result
			},
			//获取指定id文章事件
			getArticleByIdButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				getArticleById(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						res.data.content = this.markdownCompile(res.data.content)
						this.articleData = res.data
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//获取指定id文章敏感词事件
			getArticleSensitiveByIdButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				getArticleSensitiveById(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.sensitive = res.data
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//文章审核通过同时把文章内容存在的敏感词替换
			articleAuditPassButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				articleAuditPass(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						successPrompt(res.message)
						this.centerDialogVisible = false
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//拒绝通过审核
			articleAuditNotPassButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				articleAuditNotPass(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						successPrompt(res.message)
						this.centerDialogVisible = false
					} else {
						warningPrompt(res.message)
					}
				})
			}
		},
		created() {
			this.getArticleByIdButton()
			this.getArticleSensitiveByIdButton()
		}
	}
</script>

<style>
</style>
