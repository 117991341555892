<template>
	<div id="app">
		<header class="header_home user_reg" v-title data-title="mglby博客" />
			<keep-alive>
				<!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
				<router-view v-if="$route.meta.keepAlive" :key="$route.params.id" />
			</keep-alive>
			<!-- 这里是不被缓存的视图组件，比如 page3 -->
			<router-view v-if="!$route.meta.keepAlive" :key="$route.params.id" />
	</div>
</template>

<script>
	export default {
		name: "App"
	}
</script>

<style>
</style>
