import {
	axiosBlogRequest
} from '../../network/request.js'

//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}

//文章分类获取事件
export function getLoginArticleClassify() {
	return axiosBlogRequest({
		url: '/get/login/account/article/classify/by/status',
		method: 'GET'
	})
}

//添加文章分类事件
export function insertAccountArticleClassify(data) {
	return axiosBlogRequest({
		url: '/insert/account/article/classify',
		params: data,
		method: 'POST'
	})
}

//修改文章分类事件
export function updateArticleClassify(data) {
	return axiosBlogRequest({
		url: '/update/article/classify',
		params: data,
		method: 'PUT'
	})
}

//文章分类删除事件
export function deleteAccountArticleClassify(data) {
	return axiosBlogRequest({
		url: '/delete/account/article/classify',
		params: data,
		method: 'PUT'
	})
}

//文章分类数据验证
export function articleClassifyFromValidate() {
	return {
		name: [{
				required: true,
				message: '文章分类不能为空',
				trigger: 'blur'
			},
			{
				min: 1,
				max: 6,
				message: '长度在 1 到 6 个字符',
				trigger: 'blur'
			}
		]
	}
}

