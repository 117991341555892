import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载账户角色数据列表
export function getAccountRoles(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/roles/by/account',
		params: data,
		method: 'GET'
	})
}

//按条件分页加载角色权限数据列表
export function getAccountRolePermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/select/role/permission',
		params: data,
		method: 'GET'
	})
}

//删除账户角色
export function deleteRole(data) {
	return axiosBlogBackgroundRequest({
		url: '/delete/account/role',
		params: data,
		method: 'PUT'
	})
}
