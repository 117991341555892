import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载角色数据列表
export function getPermissions(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/permissions',
		params: data,
		method: 'GET'
	})
}


//删除角色
export function deletePermissions(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/delete/permission',
		params: data,
		method: 'PUT'
	})
}
