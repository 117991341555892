<template>
	<div>
		<div style="float: left;width: 56%;margin: 0 1%;">
			<div style="float: right;height: 45px;">
				<el-form :model="getRolesConditionForm">

					<div style="float: left;">
						<el-input style="width: 80%;" placeholder="角色名称" prefix-icon="el-icon-search" v-model="getRolesConditionForm.roleName"></el-input>
					</div>
					<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
				</el-form>
			</div>

			<el-checkbox-group v-model="checkList">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column fixed prop="id" label="角色ID" width="65">
						<template slot-scope="scope">
							<el-checkbox :label="scope.row.id"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column fixed prop="roleNum" label="角色编号" width="150">
					</el-table-column>
					<el-table-column fixed prop="roleName" label="角色名称" width="150">
					</el-table-column>
					<el-table-column fixed label="操作" width="255">
						<template slot-scope="scope">
							<el-button @click="deleteRoleButton(scope.row)" type="text" size="small">删除角色</el-button>
							<el-button @click="updateRoleButton(scope.row)" type="text" size="small">修改角色</el-button>
							<el-button @click="insertRolePermissionButton(scope.row)" type="text" size="small">添加权限</el-button>
							<el-button @click="getRolePermissionButton(scope.row)" type="text" size="small">查询权限</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-checkbox-group>
			<div style="text-align: right;padding: 1%;">
				<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-remove" @click="deleteRolesButton()">批量删除</el-button>
			</div>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>

		<!-- 这里是不被缓存的视图组件，比如 page3 -->
		<router-view :key="$route.query.id" />

	</div>
</template>

<script>
	import {
		getRoles,
		deleteRoles
	} from '../../../js/background/system_account_manage/RoleList.js'
	import {
		warningPrompt,
		successPrompt,
		optionPrompt,
		optionPrompt2
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			handleClick(row) {
				console.log(row);
			},
			//分页查询账户数据列表
			page(currentPage) {
				const _this = this
				this.getRolesConditionForm.pageNum = currentPage
				this.getRolesConditionForm.pageSize = this.pageSize
				getRoles(this.getRolesConditionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//批量删除角色事件
			deleteRolesButton() {
				//是否执行操作事件
				optionPrompt2().then(() => {
					//在axios中使用params传参，这里获取到角色id的数组对象时，赋值给另一个属性，作为要
					//传递的参数，但是这个参数如果不加上 '' 这个空符串在发起请求的时候url会变
					//成 url?ids[]=&account=** ，传递的ids参数后面多了[]符号，导致请求出错
					this.deleteRolesForm.ids = this.checkList + ''
					if (this.checkList != '') {
						deleteRoles(this.deleteRolesForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message);
								//删除成功重新查询显示数据
								this.page(this.getRolesConditionForm.pageNum)
								//删除角色数据后面显示了视图，把显示的视图重新查询
								this.getRolePermissionButton(this.tableData[0])

							} else {
								warningPrompt(res.message)
							}
						})
					} else {
						warningPrompt("请选择要删除的角色!")
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//删除角色事件
			deleteRoleButton(row) {
				//是否执行操作事件
				optionPrompt(row.roleName).then(() => {
					this.deleteRolesForm.ids = row.id
					//发起删除请求
					deleteRoles(this.deleteRolesForm).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message);
							//删除成功重新查询显示数据
							this.page(this.getRolesConditionForm.pageNum)
							//删除角色数据后面显示了视图，把显示的视图重新查询
							this.getRolePermissionButton(this.tableData[0])
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//修改角色事件，加载子路由，同时传递参数过去
			updateRoleButton(row) {
				this.$router.push({
					path: '/updateRole',
					query: {
						id: row.id
					}
				})
			},
			//添加角色权限事件，加载子路由，同时传递参数过去
			insertRolePermissionButton(row) {
				this.$router.push({
					path: '/addRolePermissionView',
					query: {
						id: row.id,
						roleName: row.roleName
					}
				})
			},
			//查询角色权限事件，加载子路由，同时传递参数过去
			getRolePermissionButton(row) {
				this.$router.push({
					path: '/rolePermissionView',
					query: {
						id: row.id,
						roleName: row.roleName
					}
				})
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				total: null,
				checkList: [],
				pageSize: 5,
				//查询账户数据列表需要传递的参数对象
				getRolesConditionForm: {
					pageNum: null,
					pageSize: null,
					roleName: null
				},
				deleteRolesForm: {
					ids: null
				},
			}
		}
	}
</script>
