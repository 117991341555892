<template>
	<div>
		<div style="float: left;width: 32%;margin: 0 1%;">
			<div style="float: right;height: 45px;">
				<el-form :model="getAccountsConditionForm">

					<div style="float: left;">
						<el-input style="width: 80%;" placeholder="账户" prefix-icon="el-icon-search" v-model="getAccountsConditionForm.account"></el-input>
					</div>
					<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
				</el-form>
			</div>

			<el-table :data="tableData" border style="width: 100%">
				<el-table-column fixed prop="id" label="账户ID" width="65">
				</el-table-column>
				<el-table-column fixed prop="account" label="用户账户" width="150">
				</el-table-column>
				<el-table-column fixed label="操作" width="139">
					<template slot-scope="scope">
						<el-button @click="addAccountRole(scope.row)" type="text" size="small">添加角色</el-button>
						<el-button @click="getAccountRoles(scope.row)" type="text" size="small">查询角色</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>

		<!-- 这里是不被缓存的视图组件，比如 page3 -->
		<router-view :key="$route.query.account" />

	</div>
</template>

<script>
	import {
		getAccounts,
	} from '../../../js/background/system_account_manage/AccountRolePermission.js'
	import {
		warningPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			handleClick(row) {
				console.log(row);
			},
			//分页查询账户数据列表
			page(currentPage) {
				const _this = this
				this.getAccountsConditionForm.pageNum = currentPage
				this.getAccountsConditionForm.pageSize = this.pageSize
				getAccounts(this.getAccountsConditionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//查询账户角色事件，加载子路由，同时传递参数过去
			getAccountRoles(row) {
				this.$router.push({
					path: '/accountRolePermissionView',
					query: {
						account: row.account
					}
				})
			},
			//添加账户角色事件，加载子路由，同时传递参数过去
			addAccountRole(row) {
				this.$router.push({
					path: '/addAcountRoleView',
					query: {
						account: row.account
					}
				})
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5,
				//查询账户数据列表需要传递的参数对象
				getAccountsConditionForm: {
					pageNum: null,
					pageSize: null,
					account: null,
					nickname: null
				}
			}
		}
	}
</script>
