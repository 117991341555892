import {
	axiosBlogRequest
} from '../../network/request.js'


//注册用户事件
export function registerAccount(data) {
	return axiosBlogRequest({
		url: '/register/account/open',
		params: data,
		method: 'POST'
	})
}

//获取验证码事件
export function getEmailAuthCode(data) {
	return axiosBlogRequest({
		url: '/get/email/auth/code/open',
		params: data,
		method: 'GET'
	})
}

//登录数据验证
export function registerAccountValidate() {
	return {
		account: [{
				required: true,
				message: '请输入账户',
				trigger: 'blur'
			},
			{
				min: 6,
				max: 32,
				message: '长度在 8 到 32 个字符',
				trigger: 'blur'
			}
		],
		password: [{
				required: true,
				message: '请输入密码',
				trigger: 'blur'
			},
			{
				min: 6,
				max: 32,
				message: '长度在 6 到 32 个字符',
				trigger: 'blur'
			}
		],
		authCode: [{
			required: true,
			message: '请输入验证码',
			trigger: 'blur'
		}]
	}
}
