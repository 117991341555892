<template>
	<div>
		<div style="float: right;">
			<el-form :model="getAccountsConditionForm">

				<div style="float: left;padding: 1%;width: 35%;">
					<el-input style="width: 80%;" placeholder="账户" prefix-icon="el-icon-search" v-model="getAccountsConditionForm.account"></el-input>
				</div>
				<div style="float: left;padding: 1%;width: 35%;">
					<el-input style="width: 80%;" placeholder="昵称" prefix-icon="el-icon-search" v-model="getAccountsConditionForm.nickname"></el-input>
				</div>
				<el-button style="margin-top: 0.7%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
			</el-form>
		</div>

		<el-table :data="tableData" border style="width: 100%">
			<el-table-column fixed prop="id" label="编号" width="60">
			</el-table-column>
			<el-table-column fixed prop="account" label="账户" width="180">
			</el-table-column>
			<el-table-column prop="nickname" label="昵称" width="150">
			</el-table-column>
			<el-table-column prop="username" label="用户名" width="120">
			</el-table-column>
			<el-table-column prop="phoneNum" label="手机号码" width="120">
			</el-table-column>
			<el-table-column prop="address" label="地址" width="270">
			</el-table-column>
			<el-table-column prop="status" label="状态" width="80">
				<template slot-scope="scope">
					<span v-if="scope.row.status==='账户正常'" style="color: green">{{scope.row.status}}</span>
					<span v-else style="color: red">{{scope.row.status}}</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click="accountInfo(scope.row)" type="text" size="small">详情</el-button>
					<el-button @click="updateAccountStatusButton(scope.row)" type="text" size="small">锁定/解锁</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: center;margin: 1% 0;">
			<!-- 分页插件 -->
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getAccounts,
		updateAccountStatus
	} from '../../../js/background/system_account_manage/AccountList.js'
	import {
		warningPrompt,
		successPrompt,
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			handleClick(row) {

				console.log(row);
			},
			//分页查询账户数据列表
			page(currentPage) {
				const _this = this
				this.getAccountsConditionForm.pageNum = currentPage
				this.getAccountsConditionForm.pageSize = this.pageSize
				getAccounts(this.getAccountsConditionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//修改账户状态
			updateAccountStatusButton(row) {
				updateAccountStatus(row).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.page(this.getAccountsConditionForm.pageNum) //重新加载数据
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			accountInfo(row) {
				this.$router.push({
					path: '/accountInfo',
					query: {
						id: row.id
					}
				})
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5,
				//分页查询账户列表数据需要传递的参数
				getAccountsConditionForm: {
					pageNum: null,
					pageSize: null,
					account: null,
					nickname: null
				}
			}
		}
	}
</script>
