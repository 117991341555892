import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//根据角色id获取角色信息事件
export function getRole(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/roles/by/role/id',
		params: {
			id: data
		},
		method: 'get'
	})
}
//修改角色事件
export function updateRole(data) {
	return axiosBlogBackgroundRequest({
		url: '/update/role',
		params: data,
		method: 'PUT'
	})
}


//添加角色数据验证
export function updateRoleDataValidata() {
	return {
		roleNum: [{
				required: true,
				message: '请填写角色编号',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		],
		roleName: [{
				required: true,
				message: '请填写角色名称',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		]
	}
}
