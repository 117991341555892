<template>
	<div>
		<div>
			<el-form :model="getArticleClassifyByConditionFrom">
				<div style="float: left;padding: 1%;width: 15%;">
					<el-input placeholder="账户" prefix-icon="el-icon-search" v-model="getArticleClassifyByConditionFrom.account"></el-input>
				</div>
				<div style="float: left;padding: 1%;width: 15%;">
					<el-input placeholder="分类名称" prefix-icon="el-icon-search" v-model="getArticleClassifyByConditionFrom.nameKeyword"></el-input>
				</div>
				<el-button style="margin-top: 0.7%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
			</el-form>
		</div>

		<el-table :data="tableData" border style="width: 100%">
			<el-table-column fixed prop="id" label="编号" width="60">
			</el-table-column>
			<el-table-column fixed prop="accountId" label="文章分类所属用户id" width="150">
			</el-table-column>
			<el-table-column prop="name" label="分类名称" width="330">
			</el-table-column>
			<el-table-column prop="status" label="分类状态" width="100">
			</el-table-column>
			<el-table-column prop="remark" label="备注" width="200">
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button @click="updateArticleClassifyStatusNotPassButton(scope.row)" type="danger" size="small">拒绝</el-button>
					<el-button @click="updateArticleClassifyStatusPassButton(scope.row)" type="primary" size="small">通过</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: center;margin: 1% 0;">
			<!-- 分页插件 -->
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getArticleClassifyByCondition,
		updateArticleClassifyStatusPass,
		updateArticleClassifyStatusNotPass
	} from '../../../js/background/article_manage/ArticleClassifyAuditManageList.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5,
				//分页查询文章分类数据需要传递的参数
				getArticleClassifyByConditionFrom: {
					pageNum: null,
					pageSize: null,
					account: null,
					nameKeyword: null,
					status: 0,
				},
				//修改文章分类状态数据需要传递的参数
				updataArticleClassifyStatusFrom: {
					id: null
				}
			}
		},
		methods: {
			//分页查询文章分类数据列表
			page(currentPage) {
				const _this = this
				this.getArticleClassifyByConditionFrom.pageNum = currentPage
				this.getArticleClassifyByConditionFrom.pageSize = this.pageSize
				getArticleClassifyByCondition(this.getArticleClassifyByConditionFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//通过分类审核事件
			updateArticleClassifyStatusPassButton(row) {
				this.updataArticleClassifyStatusFrom.id = row.id
				updateArticleClassifyStatusPass(this.updataArticleClassifyStatusFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//不通过分类审核事件
			updateArticleClassifyStatusNotPassButton(row) {
				this.updataArticleClassifyStatusFrom.id = row.id
				updateArticleClassifyStatusNotPass(this.updataArticleClassifyStatusFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			}
		},
		created() {
			this.page(1);
		}
	}
</script>
