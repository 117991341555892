import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'

//按条件分页加载账户数据列表
export function getAccounts(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/accounts',
		params: data,
		method: 'GET'
	})
}
