<template>
	<div style="padding: 1% 2%;">
		<div style="overflow: hidden;position: relative;overflow-x: hidden;padding: 1% 2%;">
			<el-card class="box-card" v-if="articleData.id == null">
				<font style="color: #F56C6C;">啊哦~你想找的内容离你而去了哦！</font>
			</el-card>
			<el-card class="box-card" v-if="articleData.id != null">
				<div>
					<font style="font-size: 25px;"><b>{{articleData.title}}</b></font>
				</div>
				<div>
					<el-collapse style="margin: 1% 0 1% 0;">
						<el-collapse-item>
							<template slot="title">
								<div style="width: 75%;">
									<el-button v-if="articleData.articleType == 1" size="mini" type="danger" round>原创</el-button>
									<el-button v-if="articleData.articleType == 2" size="mini" type="warning" round>转载</el-button>
									<el-link :underline="false" type="primary" style="margin: 0 1% 0 1%;" @click="goBlogHomePage(articleData.account)">{{articleData.nickname}}</el-link>
									<font style="font-size: 11px;color: #7D6C6C;margin: 0 1% 0 1%;"><b>{{articleData.gmtCreate}}</b></font>
									<font v-if="articleData.tags != 0" style="margin: 0 0 0 1%;color: #999AAA;font-size: 11px;">文章标签:</font>
									<el-tag v-for="(item,index) in articleData.tags" type="success" :style="{color: item.colour,margin: '0 0.5%'}" :key="index">{{item.name}}</el-tag>
								</div>
								<div style="float: right;width: 20%;text-align: right;">
									<el-button v-if="loginAccount.id == articleData.accountId" size="mini" type="text" @click="goArticleUpdatePage()">编辑</el-button>
								</div>
							</template>
							<div v-if="articleData.articleType == 1">
								<font style="color: #6F6F82;">
									版权声明：本文为博主原创文章，遵重版权，转载请附上原文出处链接和本声明。
									<br />
									本文链接： <el-link type="primary" :underline="false" :href="'http://mglby.com/articleInfo?account='+articleData.account + '&articleId=' + articleData.id">http://mglby.com/articleInfo?account={{articleData.account}}&articleId={{articleData.id}}</el-link>
								</font>
							</div>
							<div v-if="articleData.articleType == 2">
								<font style="color: #6F6F82;">
									原文链接： <el-link type="primary" :underline="false" :href="articleData.originalUrl">{{articleData.originalUrl}}</el-link>
								</font>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>

				<!-- 文章内容 -->
				<div class="content markdown-body" style="white-space: pre-wrap;word-wrap: break-word;" v-html="articleData.content"></div>

				<!-- 分割线 -->
				<el-divider content-position="left">
					<font style="font-size: 10px;color: #7D6C6C;"></font>
				</el-divider>

				<!-- 底部栏 -->
				<div style="text-align: right;padding: 0 5%;">
					<el-button type="text" style="color: #6B6B6B;font-size: 15px;" @click="articleLikeButton()">
						<i v-if="whetherLike == false" class="el-icon-thumb" style="font-size: 20px;" />
						<i v-if="whetherLike" class="el-icon-thumb" style="color: #000080;font-size: 20px;" />
						<font v-if="whetherLike == false" style="color: #33A9F3;margin: 0 0 0 6px;">点赞{{articleLikeSize == 0 ? null:articleLikeSize}}</font>
						<font v-if="whetherLike" style="color: #33A9F3;margin: 0 0 0 6px;">已赞{{articleLikeSize}}</font>
					</el-button>
					<el-button type="text" style="color: #6B6B6B;font-size: 15px;" @click="articleCollectionButton()">
						<i v-if="whetherCollection == false" class="el-icon-star-off" style="font-size: 20px;" />
						<i v-if="whetherCollection" class="el-icon-star-off" style="color: #000080;font-size: 20px;" />
						<font v-if="whetherCollection == false" style="color: #33A9F3;margin: 0 0 0 6px;">收藏{{articleCollectionSize == 0 ? null:articleCollectionSize}}</font>
						<font v-if="whetherCollection" style="color: #33A9F3;margin: 0 0 0 6px;">已收藏{{articleCollectionSize}}</font>
					</el-button>
					<el-button type="text" style="color: #6B6B6B;font-size: 15px;">
						<i class="el-icon-chat-dot-round" style="font-size: 20px;" />
						<font style="color: #33A9F3;margin: 0 0 0 6px;">评论{{articleCommentSize == 0 ? null:articleCommentSize}}</font>
					</el-button>
					<el-button type="info" size="mini" round> <i class="el-icon-info" />举报</el-button>
				</div>

				<!-- 评论 -->
				<div style="padding: 1% 0;">
					<el-input autosize type="textarea" placeholder="请输入内容" v-model="commentFrom.content" maxlength="1000" show-word-limit style="width: 80%;"></el-input>
					<el-button style="margin: 0 2%;" type="primary" size="medium" round @click="insertArticleCommentButton()">评论</el-button>
				</div>
				<!-- 评论区 -->
				<div>
					<template v-for="(item,index) in articleCommentsData">
						<CommentComponent :data="item" :id="item.id" :commentBoxId="commentBoxId" :loginAccountId="loginAccount.id" :articleAccountId="articleData.accountId" :key="index" />
						<el-divider :key="index"></el-divider>
					</template>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import CommentComponent from './CommentComponent.vue'
	//导入子组件存储数据中间件
	import Component from '../../js/public/PublicComponent.js'
	import 'github-markdown-css/github-markdown.css'
	import {
		getLoginAccount,
		getArticleById,
		whetherLike,
		articleLike,
		getArticleLikeSize,
		whetherCollection,
		articleCollection,
		getArticleCollectionSize,
		getArticleCommentSize,
		getArticleCommentsById,
		insertArticleComment,
		deleteArticleComment
	} from '../../js/blog/ArticleInfo.js'
	import {
		successPrompt,
		warningPrompt,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		components: {
			CommentComponent,
		},
		data() {
			return {
				commentBoxId: null, //评论递归组件回复框显示条件，此数据来源子组件
				whetherLike: false, //是否点赞
				whetherCollection: false, //是否收藏
				articleLikeSize: 0, //文章点赞次数
				articleCollectionSize: 0, //文章收藏次数
				articleCommentSize: 0, //文章评论次数
				//已登录的账户
				loginAccount: {
					id: null,
					account: null
				},
				//文章数据
				articleData: {
					id: null,
					account: null,
					accountId: null,
					nickname: null,
					title: null,
					content: null,
					status: null,
					classifyId: null,
					articleType: null,
					publishType: null,
					originalUrl: null,
					gmtCreate: null,
					gmtModify: null,
					tags: null
				},
				articleCommentsData: null, //用户评论数据
				//读取文章必要数据
				articleFrom: {
					articleId: null,
				},
				//回复评论所需参数
				commentFrom: {
					parentId: 0, //评论父级id
					articleId: this.$route.query.articleId, //评论所属文章id
					content: null, //评论内容
				},
				//文章点赞收藏所需参数
				articleIdFrom: {
					id: this.$route.query.articleId
				}
			}
		},
		methods: {
			//点击作者跳转到作者博客事件
			goBlogHomePage(account) {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: account
					}
				})
				this.$router.go(0)
			},
			//获取已登录用户账户
			getLoginAccountButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取改token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					getLoginAccount().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.loginAccount = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//修改文章
			goArticleUpdatePage() {
				this.$router.push({
					path: '/blogArticleUpdate',
					query: {
						account: this.loginAccount.account,
						articleId: this.$route.query.articleId
					}
				})
			},
			//用户是否对该文章已点赞
			whetherLikeButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取改token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					whetherLike(this.articleIdFrom).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.whetherLike = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//用户是否已收藏指定id文章
			whetherCollectionButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取改token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					whetherCollection(this.articleIdFrom).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.whetherCollection = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//用户对指定id文章点赞或取消点赞
			articleLikeButton() {
				//发送请求
				articleLike(this.articleIdFrom, this.whetherLike).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.whetherLikeButton(this.articleIdFrom)
						this.getArticleLikeSizeButton(this.articleIdFrom)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//用户对指定id文章收藏或取消收藏
			articleCollectionButton() {
				//发送请求
				articleCollection(this.articleIdFrom, this.whetherCollection).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.whetherCollectionButton(this.articleIdFrom)
						this.getArticleCollectionSizeButton(this.articleIdFrom)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定id文章被点赞次数
			getArticleLikeSizeButton() {
				//发送请求
				getArticleLikeSize(this.articleIdFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleLikeSize = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定id文章被收藏次数
			getArticleCollectionSizeButton() {
				//发送请求
				getArticleCollectionSize(this.articleIdFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleCollectionSize = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定id文章被评论次数
			getArticleCommentSizeButton() {
				//发送请求
				getArticleCommentSize(this.articleIdFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleCommentSize = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//文章内容格式转换方法
			markdownCompile(content) {
				var MarkdwonIt = require('markdown-it'),
					md = new MarkdwonIt()

				//转换mavon-editor文本为html文本
				var result = md.render(content)
				return result
			},
			//获取指定id文章事件
			getArticleByIdButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				getArticleById(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						res.data.content = this.markdownCompile(res.data.content)
						this.articleData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定id文章评论事件
			getArticleCommentsByIdButton() {
				this.articleFrom.articleId = this.$route.query.articleId
				//发送请求
				getArticleCommentsById(this.articleFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleCommentsData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//添加用户评论
			insertArticleCommentButton() {
				this.insertArticleCommentPublic(this.commentFrom)
			},
			//添加用户评论公共方法
			insertArticleCommentPublic(data) {
				if (data.content == null || data.content.trim() == '') {
					warningPrompt('评论内容不能为空')
					return
				}
				//发送请求
				insertArticleComment(data).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						//重新加载评论数据
						this.getArticleCommentsByIdButton()
						//回复框显示条件修改
						this.commentBoxId = null
						//清空数据
						this.commentFrom.content = null
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//删除用户评论公共方法
			deleteArticleCommentPublic(data) {
				//发送请求
				deleteArticleComment(data).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						//重新加载评论数据
						this.getArticleCommentsByIdButton()
						//回复框显示条件修改
						this.commentBoxId = null
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
		},
		created() {
			this.getArticleByIdButton()
			this.getArticleCommentsByIdButton()
			this.getLoginAccountButton()
			this.whetherLikeButton()
			this.getArticleLikeSizeButton()
			this.whetherCollectionButton()
			this.getArticleCollectionSizeButton()
			this.getArticleCommentSizeButton()
			//使用监听事件监听递归子组件的数据
			//监听回复框显示条件
			Component.$on('commentBox', commentBoxId => {
				this.commentBoxId = commentBoxId
			});
			//监听回复评论
			/* Component.$on('postComment', commentFrom => {
				this.insertArticleCommentPublic(commentFrom)
			}); */
			//监听删除评论
			/* Component.$on('deleteComment', deleteCommentFrom => {
				this.deleteArticleCommentPublic(deleteCommentFrom)
			}); */
			Component.$on('reloadComenet', () => {
				this.getArticleCommentsByIdButton()
				//回复框显示条件修改
				this.commentBoxId = null
				this.commentFrom.content = null
			});
		}
	}
</script>

<style>
</style>
