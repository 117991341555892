import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//添加角色事件
export function addRole(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/insert/role',
		params: data,
		method: 'POST'
	})
}


//添加角色数据验证
export function addRoleDataValidata() {
	return {
		roleNum: [{
				required: true,
				message: '请填写角色编号',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		],
		roleName: [{
				required: true,
				message: '请填写角色名称',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		]
	}
}
