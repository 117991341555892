<template>
	<div>
		<div style="width: 60%;padding-left: 15%;">
			<el-form :model="addRoleForm" :rules="rules" ref="addRoleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="角色编号" prop="roleNum">
					<el-input placeholder="请填写角色编号" maxlength="16" show-word-limit v-model="addRoleForm.roleNum"></el-input>
				</el-form-item>
				<el-form-item label="角色名称" prop="roleName">
					<el-input placeholder="请填写角色名称" maxlength="16" show-word-limit v-model="addRoleForm.roleName"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-form-item>
						<el-button type="primary" @click="submitForm('addRoleForm')">立即创建</el-button>
						<el-button @click="resetForm('addRoleForm')">重置</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		addRoleDataValidata,
		addRole
	} from '../../../js/background/blog_account_manage/AddRole.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				//addRoleForm表单的参数对象
				addRoleForm: {
					roleNum: null,
					roleName: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: addRoleDataValidata()
			};
		},
		methods: {
			//添加角色方法
			submitForm(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						addRole(this.addRoleForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		}
	}
</script>

<style>
</style>
