<template>
	<div>
		<div>
			<div>
				<div style="float: left;">
					<el-avatar :size="30" :src="requestBlogImageButton(data.imageUrl)"></el-avatar>
				</div>
				<div style="padding: 0px 10px 0 10px;">
					<font>
						<el-button type="text" @click="goBlogHomePage(data.account)">{{data.nickname}}:</el-button>
					</font>
					<font v-if="data.parentId != 0" style="color: #555666;padding: 0 1% 0 0;">
						<b style="font-size: 13px;">回复</b>
						<font>
							<el-button type="text" @click="goBlogHomePage(data.parentAccount)">{{data.parentAccountNickname}}:</el-button>
						</font>
					</font>
					<font style="color: #000000;padding: 0 1% 0 0;">{{data.content}}</font>
					<font style="font-size: 11px;color: #7D6C6C;padding: 0 1%;"><b>{{data.gmtCreate}}</b></font>
					<el-button type="text" @click="replyButton(data.id)">回复</el-button>
					<el-button v-if="data.accountId == loginAccountId || articleAccountId == loginAccountId" type="text" style="color: #F56C6C;" @click="deleteCommentButton(data.id)">删除</el-button>
				</div>
				<div v-if="data.id == commentBoxId">
					<div>
						<el-input type="textarea" placeholder="请输入内容" v-model="commentFrom.content" maxlength="1000" show-word-limit></el-input>
						<div style="text-align: right;margin: 0.5%;">
							<el-button type="infor" @click="cancelReplyButton()">取消评论</el-button>
							<el-button type="primary" @click="commentButton(data.id,data.accountId)">发表评论</el-button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="data.articleCommentVOList != 0" :style="{padding:(data.id==id?'0 0 0 4%':'0')}">
				<CommentComponent v-for="(item,index) in data.articleCommentVOList" :data="item" :commentBoxId="commentBoxId" :loginAccountId="loginAccountId" :articleAccountId="articleAccountId" :key="index"></CommentComponent>
			</div>
		</div>

	</div>

</template>

<script>
	import {
		insertArticleComment,
		deleteArticleComment
	} from '../../js/blog/CommentCompoent.js'
	import {
		requestBlogImage
	} from '../../network/request.js'
	//导入子组件存储数据中间件
	import Component from '../../js/public/PublicComponent.js'
	import {
		successPrompt,
		warningPrompt,
		optionPrompt3
	} from '../../js/public/PopUpBox.js'
	export default {
		//递归组件需要设置名称,才能间接或者直接调用自身
		name: 'CommentComponent',
		props: ['data', 'id', 'commentBoxId', 'loginAccountId', 'articleAccountId'], //这些数据是当调用本组件时刻通过 :数据名称传递
		data() {
			return {
				test: null,
				//回复评论所需参数
				commentFrom: {
					parentId: null, //评论父级id
					parentAccountId: null, //评论父级用户id
					articleId: this.$route.query.articleId, //评论所属文章id
					content: null, //评论内容
				},
				//删除评论所需参数
				deleteCommentFrom: {
					id: null //评论id
				}
			}
		},
		methods: {
			//点击评论者跳转到他的博客事件
			goBlogHomePage(account) {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: account
					}
				})
				this.$router.go(0)
			},
			requestBlogImageButton(url) {
				return requestBlogImage(url)
			},
			//设置回复框显示条件，由于组件是递归组件，所以每次递归时，组件都会是一个新的组件，数据无法
			//通用，导致显示回复框条件无法判断，因此要把子组件的显示回复框条件传递到父组件，再由父组件
			//传递下来给子组件，在组件递归里面再继续传下下去，实现了一个条件递归里面的所有组件通用
			replyButton(commentBoxId) {
				//像父组件发送数据，使得回复框显示
				Component.$emit('commentBox', commentBoxId);
			},
			//隐藏回复框
			cancelReplyButton() {
				//清除数据
				this.commentFrom.content = null

				//向父组件传递修改过的回复框显示条件
				Component.$emit('commentBox', null);
			},
			//向父组件发送重新加载评论
			reloadComenetButton() {
				//向父组件传递修改过的回复框显示条件
				Component.$emit('reloadComenet', null);
			},
			//发布评论
			commentButton(id, accountId) {
				//提交评论数据到表单
				this.commentFrom.parentId = id
				this.commentFrom.parentAccountId = accountId

				//向父组件传递评论数据，由父组件执行评论请求
				/* Component.$emit('postComment', this.commentFrom); */


				if (this.commentFrom.content == null || this.commentFrom.content.trim() == '') {
					warningPrompt('评论内容不能为空')
					return
				}
				//发送请求
				insertArticleComment(this.commentFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						//重新加载评论数据
						this.reloadComenetButton()
						//清空数据
						this.commentFrom.content = null
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//删除评论及评论下的回复
			deleteCommentButton(id) {
				//是否执行操作事件
				optionPrompt3('是否删除评论及该评论下的回复?').then(() => {
					this.deleteCommentFrom.id = id
					//向父组件传递删除评论数据，由父组件执行删除评论请求
					/* Component.$emit('deleteComment', this.deleteCommentFrom); */
					//发送请求
					deleteArticleComment(this.deleteCommentFrom).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							//重新加载评论数据
							this.reloadComenetButton()
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			}
		},
	}
</script>

<style>

</style>
