import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'

import router from './router/index.js'

//注册全局element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//注册全局 mavon-editor文本编辑
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(mavonEditor)

import VueParticles from 'vue-particles'  
Vue.use(VueParticles)  

// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

new Vue({
	router: router,
	render: h => h(App),
}).$mount('#app')

//alert(router.options.routes[0].children[0].name)