<template>
	<div>
		<div style="overflow: hidden;position: relative;overflow-x: hidden;">
			<div>
				<div style="text-align: left;margin: 1% 1% 1% 6%">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item @click.native="goBlogHomePage()"><i class="el-icon-house"></i><a> 主页 </a></el-breadcrumb-item>
						<el-breadcrumb-item><a> 文章管理 </a></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div style="text-align: right;margin: 1% 2%;">
				<el-form>
					<el-select v-model="visitAccountFrom.articleStatus" placeholder="文章状态" size="mini" style="width: 17%;padding: 0.5%;">
						<el-option v-for="item in articleStatusData" :key="item.articleStatus" :label="item.name" :value="item.articleStatus">
						</el-option>
					</el-select>
					<el-select v-model="visitAccountFrom.articleType" placeholder="文章类型" size="mini" style="width: 17%;padding: 0.5%;">
						<el-option v-for="item in articleTypeData" :key="item.articleType" :label="item.name" :value="item.articleType">
						</el-option>
					</el-select>
					<el-select v-model="visitAccountFrom.classifyId" placeholder="文章分类" size="mini" style="width: 17%;padding: 0.5%;">
						<el-option :label="null" :value="null">
						</el-option>
						<el-option v-for="item in articleClassifyData" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="visitAccountFrom.publishType" placeholder="发布类型" size="mini" style="width: 17%;padding: 0.5%;">
						<el-option v-for="item in publishTypeData" :key="item.publishType" :label="item.name" :value="item.publishType">
						</el-option>
					</el-select>
					<el-input v-model="visitAccountFrom.titleKeyword" placeholder="文章标题关键字" size="mini" style="width: 16%;padding: 0.5%;" />
					<el-button style="margin-top: 0.7%;" size="mini" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
				</el-form>
			</div>
			<ul>
				<el-card v-if="articleData == 0 || articleData == null">
					没有找到相关数据!
				</el-card>
				<el-card v-for="(item,index) in articleData" class="box-card" :key="index" style="margin: 1% 2%;">
					<div style="clear: both;">
						<div style="text-align: left;">
							<el-button v-if="item.status == 0" size="mini" type="infor" style="margin: 0 1%;">草稿</el-button>
							<el-button v-if="item.status == 1" size="mini" type="warning" style="margin: 0 1%;">待审核</el-button>
							<el-button v-if="item.status == 2" size="mini" type="success" style="margin: 0 1%;">已审核</el-button>
							<el-button v-if="item.status == 3" size="mini" type="danger" style="margin: 0 1%;">未通过审核</el-button>
							<font style="font-size: 16px;color: #3F51B5;"><b>{{item.title}}</b></font>
							<div style="float: right;">
								<el-tooltip class="item" effect="dark" content="修改" placement="top">
									<el-button type="primary" size="mini" icon="el-icon-edit" circle @click="goArticleUpdatePage(item.id)"></el-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="删除" placement="top">
									<el-button type="danger" size="mini" icon="el-icon-delete" circle @click="deleteAccountArticleByidButton(item.id)"></el-button>
								</el-tooltip>
							</div>
						</div>
					</div>
					<div style="text-align: left;">
						<font style="font-size: 12px;color: #000000;">
							{{item.content}}....
							<el-button type="text" @click="getBlogArticleInfoPage(item.id)">阅读全文</el-button>
						</font>
					</div>
					<el-divider content-position="left">
						<font style="font-size: 10px;color: #7D6C6C;"><b>发表于{{item.gmtCreate}}</b></font>
					</el-divider>
					<div>
						<div style="width:25%;float: right;color: #8F8F8F;">
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-view">
									<font style="color: #33A9F3;font-size: 12px;"> 999+</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-thumb">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleLikeCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-star-off">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCollectionCount}}</font>
								</i>
							</el-button>
							<el-button type="text" style="color: #6B6B6B;padding: 0;">
								<i class="el-icon-chat-dot-round">
									<font style="color: #33A9F3;font-size: 12px;"> {{item.articleCommentCount}}</font>
								</i>
							</el-button>
						</div>
						<div>
							<el-tag v-for="(tag,tagIndex) in item.tags" type="success" :style="{color: tag.colour,margin: '0 1%'}" size="mini" :key="tagIndex">{{tag.name}}</el-tag>
						</div>
					</div>
				</el-card>
			</ul>
			
			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAccountArticleManage,
		getLoginArticleClassify,
		deleteAccountArticleByid
	} from '../../js/blog/BlogAccountArticleManage.js'
	import {
		warningPrompt,
		optionPrompt3,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				years: '123',
				total: null,
				pageNum: 1,
				pageSize: 4,
				visitAccountFrom: {
					articleStatus: null, //文章状态查询条件
					articleType: null, //文章类型查询条件
					classifyId: null, //文章分类查询条件
					publishType: null, //文章发布类型查询条件
					titleKeyword: null, //文章标题关键字查询条件
					pageNum: null,
					pageSize: null
				},
				//删除文章需要提交的数据
				articleIdFrom: {
					id: null
				},
				//文章状态类型数据
				articleStatusData: [{
					articleStatus: null,
					name: null
				}, {
					articleStatus: 0,
					name: '草稿'
				}, {
					articleStatus: 1,
					name: '待审核'
				}, {
					articleStatus: 2,
					name: '已审核'
				}, {
					articleStatus: 3,
					name: '未通过审核'
				}],
				//文章类型数据
				articleTypeData: [{
					articleType: null,
					name: null
				}, {
					articleType: 1,
					name: '原创'
				}, {
					articleType: 2,
					name: '转载'
				}],
				//文章发布类型数据
				publishTypeData: [{
					publishType: null,
					name: null
				}, {
					publishType: 1,
					name: '公开'
				}, {
					publishType: 2,
					name: '私密'
				}],
				//文章分类数据
				articleClassifyData: null,
				//文章信息数据
				articleData: null,
			}
		},
		methods: {
			goBlogHomePage() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.$route.query.account
					}
				})
			},
			//修改文章
			goArticleUpdatePage(articleId) {
				this.$router.push({
					path: '/blogArticleUpdate',
					query: {
						account: this.$route.query.account,
						articleId: articleId
					}
				})
			},
			//删除文章事件
			deleteAccountArticleByidButton(articleId) {
				optionPrompt3("是否删除该文章?").then(() => {
					this.articleIdFrom.id = articleId
					deleteAccountArticleByid(this.articleIdFrom).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.page(this.visitAccountFrom.pageNum)
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			//阅读全文事件
			getBlogArticleInfoPage(articleId) {
				this.$router.push({
					path: '/articleInfo',
					query: {
						account: this.$route.query.account,
						articleId: articleId
					}
				})
			},
			//获取文章分类事件
			getLoginArticleClassifyButton() {
				getLoginArticleClassify().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleClassifyData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//分页查询账户数据列表
			page(currentPage) {
				this.visitAccountFrom.pageNum = currentPage
				this.visitAccountFrom.pageSize = this.pageSize
				getAccountArticleManage(this.visitAccountFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						if (res.data.list != null) {
							//获取到mavon-editor对象，
							var MarkdwonIt = require('markdown-it'),
								md = new MarkdwonIt()
							var str = null
							var str2 = null
							var result = null
							//把每个文章的内容的mavon-editor符号都转换成html标签，因为是预览显示，不需
							//要样式，所以要把html标签去掉变成纯文本然后取出其中一部分内容作为预览显示
							res.data.list.forEach((item, index) => {
								//转换mavon-editor文本为html文本
								str = md.render(item.content)
								//替换文本内的所有html标签为空，使文本变回纯文本
								str2 = str.replace(/<[^>]+>/g, "  ")
								//切割纯文本的前一段文字作为预览显示的文本
								result = str2.substr(0, 110)
								//赋值修改后的数据
								res.data.list[index].content = result
							})
						}
						this.articleData = res.data.list
						this.total = res.data.total
						this.backTop()
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//回到顶部事件
			backTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		},
		created() {
			this.page(1)
			this.getLoginArticleClassifyButton()
		}
	}
</script>

<style>
</style>
