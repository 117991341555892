import {
	axiosBlogBackgroundRequest
} from '../../network/request.js'


//登录按钮事件
export function backroundLogin(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/token/open',
		params: data,
		method: 'POST'
	})
}

//登录数据验证
export function LoginValidate() {
	return {
		account: [{
				required: true,
				message: '请输入账户',
				trigger: 'blur'
			},
			{
				min: 6,
				max: 32,
				message: '长度在 8 到 32 个字符',
				trigger: 'blur'
			}
		],
		password: [{
				required: true,
				message: '请输入密码',
				trigger: 'blur'
			},
			{
				min: 6,
				max: 32,
				message: '长度在 6 到 32 个字符',
				trigger: 'blur'
			}
		]
	}
}
