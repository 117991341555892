import {
	axiosBlogRequest
} from '../../network/request.js'


//查询用户文章管理列表
export function getAccountArticleManage(data) {
	return axiosBlogRequest({
		url: '/get/account/article/manage',
		params: data,
		method: 'GET'
	})
}

//删除用户指定id文章
export function deleteAccountArticleByid(data) {
	return axiosBlogRequest({
		url: '/delete/article',
		params: data,
		method: 'PUT'
	})
}

//文章分类获取事件
export function getLoginArticleClassify() {
	return axiosBlogRequest({
		url: '/get/login/account/article/classify/by/status',
		method: 'GET'
	})
}
