import {
	axiosBlogRequest
} from '../../network/request.js'


//文章标签获取事件
export function getTags() {
	return axiosBlogRequest({
		url: '/get/tags/open',
		method: 'GET'
	})
}

//文章分类获取事件
export function getLoginArticleClassify() {
	return axiosBlogRequest({
		url: '/get/login/account/article/classify/by/status',
		method: 'GET'
	})
}

//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}

//添加文章事件
export function insertArticle(data) {
	
	return axiosBlogRequest({
		url: '/insert/article',
		data: data,
		method: 'POST'
	})
}

//添加文章分类事件
export function insertAccountArticleClassify(data) {
	return axiosBlogRequest({
		url: '/insert/account/article/classify',
		params: data,
		method: 'POST'
	})
}

//注意：发送请求时params和data的区别,在使用axios时，注意到配置选项中包含params和data两
//者，他们使用场景不同。1.params是添加到url的请求字符串中的，用于get请求。2.data是添加到
//请求体（body）中的，用于post请求,get请求中不存在data这个选项。
//文章上传图片事件
export function uploadArticleImage(data) {
	return axiosBlogRequest({
		url: '/upload/article/image',
		data: data,
		method: 'POST'
	})
}

//文章分类数据验证
export function articleClassifyFromValidate() {
	return {
		name: [{
				required: true,
				message: '文章分类不能为空',
				trigger: 'blur'
			},
			{
				min: 1,
				max: 6,
				message: '长度在 1 到 6 个字符',
				trigger: 'blur'
			}
		]
	}
}

//文章提交数据验证
export function articleFromValidate() {
	return {
		title: [{
				required: true,
				message: '文章标题不能为空',
				trigger: 'blur'
			},
			{
				min: 1,
				max: 100,
				message: '长度在  到 100 个字符',
				trigger: 'blur'
			}
		],
		classifyId: [{
			required: true,
			message: '请选择一个分类',
			trigger: 'blur'
		}],
		articleType: [{
			required: true,
			message: '请选择一项类型',
			trigger: 'blur'
		}],
		content: [{
			required: true,
			message: '不能为空',
			trigger: 'blur'
		}],
		publishType: [{
			required: true,
			message: '请选择其中一项',
			trigger: 'blur'
		}],
	}
}
