import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//添加权限事件
export function addPermission(data) {
	return axiosBlogBackgroundRequest({
		url: '/insert/permission',
		params: data,
		method: 'POST'
	})
}


//添加权限数据验证
export function addPermissionDataValidata() {
	return {
		permissionNum: [{
				required: true,
				message: '请填写权限编号',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		],
		permissionName: [{
				required: true,
				message: '请填写权限名称',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 16,
				message: '长度在 2 到 16 个字符',
				trigger: 'blur'
			}
		]
	}
}
