<template>
	<div>
		<div style="width: 60%;padding-left: 15%;">
			<el-form :model="addPermissionForm" :rules="rules" ref="addPermissionForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="权限编号" prop="permissionNum">
					<el-input placeholder="请填写权限编号" maxlength="16" show-word-limit v-model="addPermissionForm.permissionNum"></el-input>
				</el-form-item>
				<el-form-item label="权限名称" prop="permissionName">
					<el-input placeholder="请填写权限名称" maxlength="16" show-word-limit v-model="addPermissionForm.permissionName"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-form-item>
						<el-button type="primary" @click="submitForm('addPermissionForm')">立即创建</el-button>
						<el-button @click="resetForm('addPermissionForm')">重置</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		addPermissionDataValidata,
		addPermission
	} from '../../../js/background/blog_account_manage/AddPermission.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				//addPermissionForm表单的参数对象
				addPermissionForm: {
					permissionNum: null,
					permissionName: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: addPermissionDataValidata()
			};
		},
		methods: {
			//添加角色方法
			submitForm(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						addPermission(this.addPermissionForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		}
	}
</script>

<style>
</style>
