<template>
	<div>
		<div style="width: 60%;padding-left: 15%;">
			<el-form :model="updatePermissionForm" :rules="rules" ref="updatePermissionForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="权限ID" prop="id">
					<el-input placeholder="请填写权限ID" :readonly="true" maxlength="16" show-word-limit v-model="updatePermissionForm.id"></el-input>
				</el-form-item>
				<el-form-item label="权限编号" prop="permissionNum">
					<el-input placeholder="请填写权限编号" maxlength="16" show-word-limit v-model="updatePermissionForm.permissionNum"></el-input>
				</el-form-item>
				<el-form-item label="权限名称" prop="permissionName">
					<el-input placeholder="请填写权限名称" maxlength="16" show-word-limit v-model="updatePermissionForm.permissionName"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-form-item>
						<el-button type="primary" @click="submitForm('updatePermissionForm')">立即修改</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		updatePermissionDataValidata,
		updatePermission,
		getPermission
	} from '../../../js/background/system_account_manage/UpdatePermission.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				//getPermission表单的参数对象
				updatePermissionForm: {
					id: null,
					permissionNum: null,
					permissionName: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: updatePermissionDataValidata()
			};
		},
		methods: {
			//添加角色方法
			submitForm(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						updatePermission(this.updatePermissionForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			},
			getPermission() {
				//发送请求
				getPermission(this.$route.query.id).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.updatePermissionForm.id = res.data.id
						this.updatePermissionForm.permissionNum = res.data.permissionNum
						this.updatePermissionForm.permissionName = res.data.permissionName
					} else {
						warningPrompt(res.message)
					}
				})
			}
		},
		created() {
			this.getPermission()
		}
	}
</script>

<style>
</style>
