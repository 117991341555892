<template>
	<div style="overflow: hidden;position: relative;overflow-x: hidden;">
		<el-card class="box-card" style="margin: 1% 2%;">
			<div slot="header" class="clearfix">
				<span><b> 搜索用户 </b></span>
				<el-input style="float: right;width: 30%;" v-model="accountKeyword" placeholder="请输入搜索的用户账户或昵称" size="mini">
					<el-button @click="page(1)" slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div>
			<div v-if="accountData == 0 || accountData == null">
				<span v-if="!show">右上角输入您想要寻找的用户的账户或昵称~~~</span>
				<span v-if="show">抱歉没有找到与“<b>{{getAccountFrom.accountKeyword}}</b>”相关的用户。</span>
			</div>
			<div>
				<div v-for="(item,index) in accountData" :key="index">
					<div style="float: left;">
						<el-button type="text" @click="goBlogHomePage(item.account)">
							<el-avatar :size="40" :src="requestBlogImageButton(item.imageUrl)"></el-avatar><!-- 头像 -->
						</el-button>
					</div>
					<div style="float: left;">
						<div>
							<el-button size="mini" type="text" @click="goBlogHomePage(item.account)">
								昵称：{{item.nickname}}
							</el-button>
						</div>
						<div>
							<el-button type="text" @click="goBlogHomePage(item.account)">
								账户：{{item.account}}
							</el-button>
						</div>
					</div>
					<div style="text-align: right;">
						<el-button v-if="item.whetherAttention" type="danger" plain @click="cancelAccountAttentionButton(item.id,index)">
							取消关注
						</el-button>
						<el-button v-if="!item.whetherAttention" type="primary" plain @click="insertAccountAttentionButton(item.id,index)">
							关注
						</el-button>
					</div>
					<el-divider></el-divider>
				</div>
			</div>
			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getAccountByKeyword,
		cancelAccountAttention,
		insertAccountAttention
	} from '../../js/blog/BlogSearchAccount.js'
	import {
		requestBlogImage
	} from '../../network/request.js'
	import {
		warningPromptOpen4,
		successPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				show: false,
				total: null,
				pageNum: 1,
				pageSize: 4,
				accountKeyword: null,
				getAccountFrom: {
					accountKeyword: null,
					pageNum: null,
					pageSize: null
				},
				//用户信息数据
				accountData: null
			}
		},
		methods: {
			goBlogHomePage(account) {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: account
					}
				})
				this.$router.go(0)
			},
			requestBlogImageButton(url) {
				return requestBlogImage(url)
			},
			//分页查询账户数据列表
			page(currentPage) {
				this.getAccountFrom.pageNum = currentPage
				this.getAccountFrom.pageSize = this.pageSize
				this.getAccountFrom.accountKeyword = this.accountKeyword
				getAccountByKeyword(this.getAccountFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData = res.data.list
						this.total = res.data.total
						this.show = true
						this.backTop()
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//用户取消关注指定id的用户
			cancelAccountAttentionButton(id, index) {
				cancelAccountAttention({
					id: id
				}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData[index].whetherAttention = false
						successPromptOpen4(res.message)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//用户关注指定id的用户
			insertAccountAttentionButton(id, index) {
				insertAccountAttention({
					id: id
				}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData[index].whetherAttention = true
						successPromptOpen4(res.message)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//回到顶部事件
			backTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}
		}
	}
</script>

<style>
</style>
