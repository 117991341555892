<template>
	<div>
		<div style="width: 60%;padding-left: 15%;">
			<el-form :model="updateRoleForm" :rules="rules" ref="updateRoleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="角色ID" prop="id">
					<el-input placeholder="请填写角色ID" :readonly="true" maxlength="16" show-word-limit v-model="updateRoleForm.id"></el-input>
				</el-form-item>
				<el-form-item label="角色编号" prop="roleNum">
					<el-input placeholder="请填写角色编号" maxlength="16" show-word-limit v-model="updateRoleForm.roleNum"></el-input>
				</el-form-item>
				<el-form-item label="角色名称" prop="roleName">
					<el-input placeholder="请填写角色名称" maxlength="16" show-word-limit v-model="updateRoleForm.roleName"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-form-item>
						<el-button type="primary" @click="submitForm('updateRoleForm')">立即修改</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		updateRoleDataValidata,
		updateRole,
		getRole
	} from '../../../js/background/blog_account_manage/UpdateRole.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				//addRoleForm表单的参数对象
				updateRoleForm: {
					id: null,
					roleNum: null,
					roleName: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: updateRoleDataValidata()
			};
		},
		methods: {
			//添加角色方法
			submitForm(formName) {
				console.log(this.updateRoleForm)
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						updateRole(this.updateRoleForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getRole() {
				//发送请求
				getRole(this.$route.query.id).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.updateRoleForm.id = res.data.id
						this.updateRoleForm.roleNum = res.data.roleNum
						this.updateRoleForm.roleName = res.data.roleName
					} else {
						warningPrompt(res.message)
					}
				})
			}
		},
		created() {
			this.getRole()
		}
	}
</script>

<style>
</style>
