import Vue from 'vue'
import axios from 'axios'
import router from '../router/index.js'
//import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//let ip = 'localhost'
//let ip = '120.77.202.165'
//let ip = '116.62.5.94'
let ip = 'https://mglby.com'


/** 创建封装请求模块，所有的请求都是用模块的封装方法进行请求 */

//使用export function可以导出多个
export function request(config) {
	return new Promise((resolve, reject) => {
		/* 创建axios实例，使用该实例发送的请求会使用默认使用此实例的配置 */
		const instance01 = axios.create({
			/* 全局url前缀 */
			baseURL: 'http://' + ip + ':8080',
			/* 全局超时时间（毫秒） */
			timeout: 120000
		})

		//请求拦截，在发起请求时进行拦截处理
		instance01.interceptors.request.use(config => {
			//1.拦截config中不符合服务器的信息
			//2.比如每次发送请求时都希望在界面中显示一个请求图标
			//3.某些网络请求必须携带一些特殊信息（如登录的token）
			return config
		}, err => {
			return err
		})

		//响应拦截，请求发送成功之后进行拦截处理
		instance01.interceptors.response.use(res => {
			//res：请求成功后会返回一个自带data属性的res对象，请求得到的数据都在data属性中,提取data属性数据，其他的过滤掉
			return res.data
		}, err => {
			return err
		})

		//调用axios发送请求
		instance01(config).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
	//或者可以直接 return instance01(config) 因为创建的const instance01 返回的值本质上也是Promise
}

//博客后台系统请求
//使用export function可以导出多个
export function axiosBlogBackgroundRequest(config) {
	/* 创建axios实例，使用该实例发送的请求会使用默认使用此实例的配置 */
	const instanceAxiosRequest = axios.create({
		/* 全局url前缀 */
		baseURL: ip + '/blog/background',
		/* 全局超时时间（毫秒） */
		timeout: 120000
	})

	//请求拦截，在发起请求时进行拦截处理
	instanceAxiosRequest.interceptors.request.use(config => {
		showFullScreenLoading() //发起请求时加载Loding加载动画

		//1.拦截config中不符合服务器的信息
		//2.比如每次发送请求时都希望在界面中显示一个请求图标
		//3.某些网络请求必须携带一些特殊信息（如登录的token）

		//判断是否存在token，如果存在的话，则每个http header都加上token
		if (localStorage.getItem('Authorization') != null) {
			config.headers.Authorization = localStorage.getItem('Authorization');
		}

		return config
	}, err => {
		hideFullScreenLoading() // 发送出现错误关闭loading
		return err
	})

	//响应拦截，请求发送成功之后进行拦截处理
	instanceAxiosRequest.interceptors.response.use(res => {
		hideFullScreenLoading() // 响应成功关闭loading

		//res：请求成功后会返回一个自带data属性的res对象，请求得到的数据都在data属性中,提取data属性数据，其他的过滤掉

		//判断token是否失效,失效则移除
		if (res.data.code == 401) {
			localStorage.removeItem('Authorization')
			/* 通过代码跳转页面*/
			router.push('/backgroundLogin')
		}

		//判断token是否刷新，后台设置返回的包含token的请求头是Authorization，但是在前端获取请求头时要以authorization为key才能获取，不知道为什么
		//而且访问请求在后台如果是远程调用其他服务的请求，会导致在jwt验证时进行多次验证，如果token刚好需要进行刷新，会导致token刷新多次，后台出现错误
		//所以这里理论上没有错误，但是其实暂时没有用处，如果后台解决了远程调用多次验证问题就可以使用
		if (res.headers["authorization"] != null) {
			localStorage.setItem('Authorization', res.headers["authorization"])
		}
		return res.data
	}, err => {
		hideFullScreenLoading() // 响应出现错误关闭loading
		return err
	})

	return instanceAxiosRequest(config)
}

//博客请求
//使用export function可以导出多个
export function axiosBlogRequest(config) {
	/* 创建axios实例，使用该实例发送的请求会使用默认使用此实例的配置 */
	const instanceAxiosRequest = axios.create({
		/* 全局url前缀 */
		baseURL: ip + '/blog',
		/* 全局超时时间（毫秒） */
		timeout: 120000
	})

	//请求拦截，在发起请求时进行拦截处理
	instanceAxiosRequest.interceptors.request.use(config => {
		showFullScreenLoading() //发起请求时加载Loding加载动画

		//1.拦截config中不符合服务器的信息
		//2.比如每次发送请求时都希望在界面中显示一个请求图标
		//3.某些网络请求必须携带一些特殊信息（如登录的token）

		//判断是否存在token，如果存在的话，则每个http header都加上token
		if (localStorage.getItem('Blog-Authorization') != null) {
			config.headers.Authorization = localStorage.getItem('Blog-Authorization');
		}

		return config
	}, err => {
		hideFullScreenLoading() // 发送出现错误关闭loading
		return err
	})

	//响应拦截，请求发送成功之后进行拦截处理
	instanceAxiosRequest.interceptors.response.use(res => {
		hideFullScreenLoading() // 响应成功关闭loading

		//res：请求成功后会返回一个自带data属性的res对象，请求得到的数据都在data属性中,提取data属性数据，其他的过滤掉

		//判断token是否失效,失效则移除
		if (res.data.code == 401) {
			localStorage.removeItem('Blog-Authorization')
			/* 通过代码跳转页面*/
			router.push('/blogLogin')
		}

		//判断token是否刷新，后台设置返回的包含token的请求头是Authorization，但是在前端获取请求头时要以authorization为key才能获取，不知道为什么
		//而且访问请求在后台如果是远程调用其他服务的请求，会导致在jwt验证时进行多次验证，如果token刚好需要进行刷新，会导致token刷新多次，后台出现错误
		//所以这里理论上没有错误，但是其实暂时没有用处，如果后台解决了远程调用多次验证问题就可以使用
		if (res.headers["authorization"] != null) {
			localStorage.setItem('Blog-Authorization', res.headers["authorization"])
		}
		return res.data
	}, err => {
		hideFullScreenLoading() // 响应出现错误关闭loading
		return err
	})

	return instanceAxiosRequest(config)
}

//发送博客后台系统图片请求
export function requestImage(data) {
	return ip + '/blog/background/images' + data
}

//发送博客用户头像图片请求
export function requestBlogImage(data) {
	return ip + '/blog/images' + data
}

//发送博客用户背景图片请求
export function requestAccountBackgroundImage(data) {
	return ip + '/blog/background-images' + data
}

//发送博客用户文章图片请求
export function requestBlogArticleImage(data) {
	return ip + '/blog/article-images/' + data
}

//发送博客用户文章临时图片请求
export function requestBlogArticleTemporaryImage(data) {
	return ip + '/blog/article-temporary-images/' + data
}


/* 加载球事件 */
// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading
let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
function startLoading() {
	loading = Vue.prototype.$loading({
		lock: true,
		text: '努力加载中...',
		background: 'rgba(0,0,0,0.5)',
		target: document.querySelector('.loading-area') // 设置加载动画区域
	})
}

function endLoading() {
	loading.close()
}

function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading()
	}
	needLoadingRequestCount++
}

function hideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}
