import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//分页查询指定条件的文章
export function getArticleByCondition(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/article/by/condition',
		params: data,
		method: 'GET'
	})
}
