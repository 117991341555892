<template>
	<div>
		<div style="float: left;width: 60%;"></div>
		<div style="float: right;width: 30%;text-align: right;padding: 1% 2%;">
			<el-button v-if="loginAccount.id == null" style="margin: 1% 5%;" type="primary" size="mini" round @click="goLoginPage()">登录</el-button>
			<div v-if="loginAccount.id != null">
				<el-dropdown placement="bottom-end">
					<span class="el-dropdown-link">
						<el-avatar :size="35" :src="loginAccount.imageUrl"></el-avatar><!-- 头像 -->
						<div style="float: right;padding-top: 8%;">{{loginAccount.nickname}}</div>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="goMyBlogPage()">我的博客</el-dropdown-item>
						<el-dropdown-item @click.native="loginOutButton()">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getLoginAccount
	} from '../../js/blog/BlogIndexHead.js'
	import {
		requestBlogImage
	} from '../../network/request.js'
	import {
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		//组件需要设置名称,才能间接或者直接调用自身
		name: 'BlogIndexHead',
		data() {
			return {
				loginAccount: {
					id: null,
					nickname: null,
					account: null,
					imageUrl: null,
				},
			}
		},
		methods: {
			goLoginPage() {
				this.$router.push({
					path: "/blogLogin"
				})
			},
			goMyBlogPage() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.loginAccount.account
					}
				})
			},
			//获取已登录用户账户
			getLoginAccountButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					getLoginAccount().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.loginAccount = res.data
							//转换图片地址为完整地址
							this.loginAccount.imageUrl = requestBlogImage(this.loginAccount.imageUrl)
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//退出登录
			loginOutButton() {
				localStorage.removeItem('Blog-Authorization')
				this.$router.go(0)
			}
		},
		activated() {
			this.getLoginAccountButton()
		},
		created() {
			this.getLoginAccountButton()
		}
	}
</script>

<style>

</style>
