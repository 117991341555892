import {
	axiosBlogRequest
} from '../../network/request.js'


//获取关注的用户列表事件
export function getAccountAttention(data) {
	return axiosBlogRequest({
		url: '/get/account/attention',
		params: data,
		method: 'GET'
	})
}

//获取用户关注人数事件
export function getAccountAttentionSize() {
	return axiosBlogRequest({
		url: '/get/account/attention/size',
		method: 'GET'
	})
}

//用户取消关注指定id的用户
export function cancelAccountAttention(data) {
	return axiosBlogRequest({
		url: '/cancel/account/attention',
		params: data,
		method: 'PUT'
	})
}

//用户关注指定id的用户
export function insertAccountAttention(data) {
	return axiosBlogRequest({
		url: '/insert/account/attention',
		params: data,
		method: 'POST'
	})
}
