import {
	axiosBlogRequest
} from '../../network/request.js'


//发布文章评论事件
export function insertArticleComment(data) {
	return axiosBlogRequest({
		url: '/insert/article/comment',
		params: data,
		method: 'POST'
	})
}

//删除文章评论事件
export function deleteArticleComment(data) {
	return axiosBlogRequest({
		url: '/delete/article/comment',
		params: data,
		method: 'PUT'
	})
}
