<template>
	<div>
		<div style="width: 60%;padding-left: 15%;">
			<el-form :model="updateAccountForm" :rules="rules" ref="updateAccountForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="用户ID" prop="id">
					<el-input :disabled="true" show-word-limit v-model="updateAccountForm.id"></el-input>
				</el-form-item>
				<el-form-item label="账户">
					<el-input  :disabled="true" show-word-limit v-model="updateAccountForm.account"></el-input>
				</el-form-item>
				<el-form-item label="昵称" prop="nickname">
					<el-input placeholder="请填写昵称" maxlength="32" show-word-limit v-model="updateAccountForm.nickname"></el-input>
				</el-form-item>
				<el-form-item label="用户名" prop="username">
					<el-input placeholder="请填写用户名" maxlength="32" show-word-limit v-model="updateAccountForm.username"></el-input>
				</el-form-item>
				<el-form-item label="年龄" prop="age">
					<el-input placeholder="请填写年龄" maxlength="3" show-word-limit v-model="updateAccountForm.age"></el-input>
				</el-form-item>
				<el-form-item>
					<template>
						<el-radio v-model="updateAccountForm.sex" label="男">男</el-radio>
						<el-radio v-model="updateAccountForm.sex" label="女">女</el-radio>
					</template>
				</el-form-item>
				<el-form-item label="号码" prop="phoneNum">
					<el-input placeholder="请填写号码" maxlength="32" show-word-limit v-model="updateAccountForm.phoneNum"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input placeholder="请填写地址" maxlength="64" show-word-limit v-model="updateAccountForm.address"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-form-item>
						<el-button type="primary" @click="submitForm('updateAccountForm')">立即修改</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		updateAccountDataValidata,
		updateAccount,
		getAccountInfo
	} from '../../../js/background/blog_account_manage/UpdateAccount.js'
	import {
		warningPrompt,
		successPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				accountCondition: {
					id: null
				},
				//addRoleForm表单的参数对象
				updateAccountForm: {
					id: null,
					account: null,
					nickname: null,
					username: null,
					phoneNum: null,
					address: null,
					sex: null,
					age: null
				},
				//绑定ruleForm表单需要验证的参数验证
				rules: updateAccountDataValidata()
			};
		},
		methods: {
			//添加角色方法
			submitForm(formName) {
				console.log(this.updateAccountForm)
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						//发送请求
						updateAccount(this.updateAccountForm).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								this.getAccountInfo()
								successPrompt(res.message)
							} else {
								warningPrompt(res.message)
							}
						})
					}
				});
			},
			//重置表单数据
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getAccountInfo() {
				//发送请求
				this.accountCondition.id = this.$route.query.id
				getAccountInfo(this.accountCondition).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.updateAccountForm = res.data
					} else {
						warningPrompt(res.message)
					}
				})
			}
		},
		created() {
			this.getAccountInfo()
		}
	}
</script>

<style>
</style>
