import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//获取指定id文章事件
export function getArticleById(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/manage/article/by/id',
		params: data,
		method: 'GET'
	})
}

//获取指定id文章敏感词事件
export function getArticleSensitiveById(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/get/article/sensitive/by/id',
		params: data,
		method: 'GET'
	})
}

//文章审核通过同时把文章内容存在的敏感词替换
export function articleAuditPass(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/article/audit/pass',
		params: data,
		method: 'PUT'
	})
}

//拒绝通过审核
export function articleAuditNotPass(data) {
	return axiosBlogBackgroundRequest({
		url: '/blog/api/article/audit/not/pass',
		params: data,
		method: 'PUT'
	})
}
