import {
	axiosBlogBackgroundRequest
} from '../../../network/request.js'


//查询账户详细信息事件
export function getAccountInfo(data) {
	return axiosBlogBackgroundRequest({
		url: '/get/account/by/id',
		params: data,
		method: 'GET'
	})
}

//修改账户事件
export function updateAccount(data) {
	if (data.sex == '男') {
		data.sex = 'Man'
	} else if (data.sex == '女') {
		data.sex = 'Female'
	}
	return axiosBlogBackgroundRequest({
		url: '/update/account/by/id',
		params: data,
		method: 'PUT'
	})
}


//账户数据验证
export function updateAccountDataValidata() {
	return {
		nickname: [{
				required: true,
				message: '请填写昵称',
				trigger: 'blur'
			},
			{
				min: 3,
				max: 16,
				message: '长度在 3 到 32 个字符',
				trigger: 'blur'
			}
		],
		username: [{
				required: true,
				message: '请填写用户名',
				trigger: 'blur'
			},
			{
				min: 2,
				max: 32,
				message: '长度在 2 到 32 个字符',
				trigger: 'blur'
			}
		],
		age: [{
				required: true,
				message: '请填写年龄',
				trigger: 'blur'
			}
		]
	}
}
