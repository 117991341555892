import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'

//弹出   警告  提示框 
export function warningPrompt(message) {
	Vue.prototype.$alert(message, '提示', {
		type: 'warning',
		center: true
	})
}

//弹出   成功  提示框 
export function successPrompt(message) {
	Vue.prototype.$alert(message, '提示', {
		type: 'success',
		center: true
	})
}

//弹出   info  提示框 
export function infoPrompt(message) {
	Vue.prototype.$alert(message, '提示', {
		type: 'info',
		center: true
	})
}

//弹出   error  提示框 
export function errorPrompt(message) {
	Vue.prototype.$alert(message, '提示', {
		type: 'error',
		center: true
	})
}



export function optionPrompt(message) {
	return Vue.prototype.$confirm('此操作将删除【' + message + '】, 是否继续?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	})
}

export function optionPrompt2() {
	return Vue.prototype.$confirm('此操作将删除数据, 是否继续?', '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	})
}

export function optionPrompt3(messageInfo) {
	return Vue.prototype.$confirm(messageInfo, '提示', {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	})
}

export function warningPromptOpen4(messageInfo) {
	return Vue.prototype.$message.error(messageInfo);
}

export function successPromptOpen4(messageInfo) {
	return Vue.prototype.$message.success(messageInfo);
}
