<template>
	<div>
		<div>
			<el-form :model="getArticleByConditionForm">
				<div style="float: left;padding: 1%;width: 15%;">
					<el-input placeholder="指定账户文章" prefix-icon="el-icon-search" v-model="getArticleByConditionForm.account"></el-input>
				</div>
				<div style="float: left;padding: 1%;width: 15%;">
					<el-input placeholder="文章标题关键字" prefix-icon="el-icon-search" v-model="getArticleByConditionForm.titleKeyword"></el-input>
				</div>
				<div style="float: left;padding: 1%;width: 15%;">
					<el-select v-model="getArticleByConditionForm.articleType" placeholder="文章类型">
						<el-option v-for="item in articleTypeData" :key="item.articleType" :label="item.name" :value="item.articleType">
						</el-option>
					</el-select>
				</div>
				<div style="float: left;padding: 1%;width: 15%;">
					<el-select v-model="getArticleByConditionForm.publishType" placeholder="发布类型">
						<el-option v-for="item in publishTypeData" :key="item.publishType" :label="item.name" :value="item.publishType">
						</el-option>
					</el-select>
				</div>
				<el-button style="margin-top: 0.7%;" type="primary" icon="el-icon-search" @click="page(1)">查找</el-button>
			</el-form>
		</div>

		<el-table :data="tableData" border style="width: 100%">
			<el-table-column fixed prop="id" label="编号" width="60">
			</el-table-column>
			<el-table-column fixed prop="account" label="文章所属用户" width="200">
			</el-table-column>
			<el-table-column prop="title" label="文章标题" width="330">
			</el-table-column>
			<el-table-column prop="status" label="文章状态" width="80">
				<template slot-scope="scope">
					<span v-if="scope.row.status == 1" style="color: #E6A23C;">待审</span>
					<span v-if="scope.row.status == 2" style="color: #67C23A;">已审核</span>
					<span v-if="scope.row.status == 3" style="color: #F56C6C;">未通过</span>
				</template>
			</el-table-column>
			<el-table-column prop="classifyId" label="分类id" width="80">
			</el-table-column>
			<el-table-column prop="publishType" label="发布类型" width="80">
				<template slot-scope="scope">
					<span v-if="scope.row.publishType == 1" style="color: #409EFF;">公开</span>
					<span v-if="scope.row.publishType == 2" style="color: #F56C6C;">私密</span>
				</template>
			</el-table-column>
			<el-table-column prop="gmtCreate" label="录入时间" width="150">
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="80">
				<template slot-scope="scope">
					<el-button @click="goAuditPage(scope.row)" type="text" size="small">进入审核</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: center;margin: 1% 0;">
			<!-- 分页插件 -->
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		getArticleByCondition
	} from '../../../js/background/article_manage/ArticleAuditManageList.js'
	import {
		warningPrompt,
	} from '../../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5,
				//分页查询文章数据需要传递的参数
				getArticleByConditionForm: {
					pageNum: null,
					pageSize: null,
					account: null,
					titleKeyword: null,
					articleStatus: 1,
					articleType: null,
					publishType: null
				},
				//文章类型数据
				articleTypeData: [{
					articleType: null,
					name: null
				}, {
					articleType: 1,
					name: '原创'
				}, {
					articleType: 2,
					name: '转载'
				}],
				//文章发布类型数据
				publishTypeData: [{
					publishType: null,
					name: null
				}, {
					publishType: 1,
					name: '公开'
				}, {
					publishType: 2,
					name: '私密'
				}]
			}
		},
		methods: {
			//分页查询账户数据列表
			page(currentPage) {
				const _this = this
				this.getArticleByConditionForm.pageNum = currentPage
				this.getArticleByConditionForm.pageSize = this.pageSize
				getArticleByCondition(this.getArticleByConditionForm).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			goAuditPage(row) {
				this.$router.push({
					path: '/articleAudit',
					query: {
						articleId: row.id
					}
				})
			}
		},
		created() {
			this.page(1);
		}
	}
</script>
