import {
	axiosBlogRequest
} from '../../network/request.js'


//获取访问用户主页文章事件
export function getArticleByAccount(data, falg) {
	if (falg) {
		//我的收藏文章请求
		return axiosBlogRequest({
			url: '/get/account/collection/article',
			params: data,
			method: 'GET'
		})
	} else {
		//普通文章列表访问请求
		return axiosBlogRequest({
			url: '/get/article/by/account/open',
			params: data,
			method: 'GET'
		})
	}
}
