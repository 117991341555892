import {
	axiosBlogRequest
} from '../../network/request.js'


//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}

//用户是否对该文章已点赞
export function whetherLike(data) {
	return axiosBlogRequest({
		url: '/whether/like',
		params: data,
		method: 'GET'
	})
}

//用户是否已收藏指定id文章
export function whetherCollection(data) {
	return axiosBlogRequest({
		url: '/whether/collection',
		params: data,
		method: 'GET'
	})
}

//用户对指定id文章点赞或取消点赞
export function articleLike(data, flag) {
	if (flag) {
		return axiosBlogRequest({
			url: '/cancel/article/like',
			params: data,
			method: 'PUT'
		})
	} else {
		return axiosBlogRequest({
			url: '/insert/article/like',
			params: data,
			method: 'POST'
		})
	}
}

//用户对指定id文章收藏或取消收藏
export function articleCollection(data, flag) {
	if (flag) {
		return axiosBlogRequest({
			url: '/cancel/article/collection',
			params: data,
			method: 'PUT'
		})
	} else {
		return axiosBlogRequest({
			url: '/insert/article/collection',
			params: data,
			method: 'POST'
		})
	}
}


//获取指定id文章被点赞次数
export function getArticleLikeSize(data) {
	return axiosBlogRequest({
		url: '/get/article/like/size/open',
		params: data,
		method: 'GET'
	})
}

//获取指定id文章被收藏次数
export function getArticleCollectionSize(data) {
	return axiosBlogRequest({
		url: '/get/article/collection/size/open',
		params: data,
		method: 'GET'
	})
}

//获取指定id文章被评论次数
export function getArticleCommentSize(data) {
	return axiosBlogRequest({
		url: '/count/article/comment/by/id/open',
		params: data,
		method: 'GET'
	})
}

//获取指定id文章事件
export function getArticleById(data) {
	return axiosBlogRequest({
		url: '/get/article/by/id/open',
		params: data,
		method: 'GET'
	})
}

//获取指定id文章评论事件
export function getArticleCommentsById(data) {
	return axiosBlogRequest({
		url: '/get/article/comment/by/id/open',
		params: data,
		method: 'GET'
	})
}

//发布文章评论事件
export function insertArticleComment(data) {
	return axiosBlogRequest({
		url: '/insert/article/comment',
		params: data,
		method: 'POST'
	})
}

//删除文章评论事件
export function deleteArticleComment(data) {
	return axiosBlogRequest({
		url: '/delete/article/comment',
		params: data,
		method: 'PUT'
	})
}
