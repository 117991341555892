<template>
	<div>
		<div style="float: left;width: 32%;margin: 0 0.5%;">
			<div style="text-align: center;height: 45px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
				<div style="padding-top: 5%;">
					<font style="color: rgb(140, 197, 255);">【<font style="color: blue">{{$route.query.account}}</font>】
						账户的角色</font>
				</div>
			</div>

			<el-table :data="tableData" border style="width: 100%">
				<el-table-column fixed prop="roleId" label="角色ID" width="65">
				</el-table-column>
				<el-table-column fixed prop="roleNum" label="角色编号" width="90">
				</el-table-column>

				<el-table-column fixed prop="roleName" label="角色名称" width="90">
				</el-table-column>
				<el-table-column fixed label="操作" width="108">
					<template slot-scope="scope">
						<el-button @click="deleteRoleButton(scope.row)" type="text" size="small">移除</el-button>
						<el-button @click="getAccountRolePermissionsButton(scope.row)" type="text" size="small">查询权限</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>


		<!-- ************************************** -->
		<div style="float: left;width: 27%;margin: 0 1%;">
			<div style="text-align: center;height: 45px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
				<div style="padding-top: 5%;">
					<font style="color: rgb(140, 197, 255);">【<font style="color: blue">{{roleName}}</font>】
						角色的权限</font>
				</div>
			</div>

			<el-table :data="tableData2" border style="width: 100%">
				<el-table-column fixed prop="id" label="权限ID" width="65">
				</el-table-column>
				<el-table-column fixed prop="permissionNum" label="权限编号" width="118">
				</el-table-column>

				<el-table-column fixed prop="permissionName" label="权限名称" width="115">
				</el-table-column>
			</el-table>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total2" :page-size="pageSize" @current-change="page2">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAccountRoles,
		getAccountRolePermission,
		deleteRole
	} from '../../../js/background/blog_account_manage/AccountRolePermissionView.js'
	import {
		warningPrompt,
		successPrompt,
		optionPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			getAccountRolePermissionsButton(row) {
				//查询角色权限时需要到角色id，查询账户角色时返回的对象中roleId才是角色的id，而id是账户角色关联表的id
				this.accountRolePermissionsData.id = row.roleId
				this.roleName = row.roleName
				this.page2(1)
			},
			//分页查询账户角色的请求
			page(currentPage) {
				const _this = this
				this.accountRolesData.pageNum = currentPage
				this.accountRolesData.pageSize = this.pageSize
				getAccountRoles(this.accountRolesData).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},

			//分页查询角色权限的请求
			page2(currentPage) {
				const _this = this
				console.log(currentPage)
				this.accountRolePermissionsData.pageNum = currentPage
				this.accountRolePermissionsData.pageSize = this.pageSize
				getAccountRolePermission(this.accountRolePermissionsData).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData2 = res.data.list
						_this.total2 = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},
			deleteRoleButton(row) {
				this.deleteAccountRoleData.ids = row.roleId
				this.deleteAccountRoleData.account = this.$route.query.account
				optionPrompt(row.roleName).then(() => {
					deleteRole(this.deleteAccountRoleData).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message)
							this.page(this.accountRolesData.pageNum)
							//如果删除的角色数据后面显示了视图，就把显示的视图清空
							if (row.roleId == this.accountRolePermissionsData.id) {
								this.tableData2 = null
								this.roleName = null
							}
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				tableData2: null,
				total: null,
				total2: null,
				pageSize: 5, //公共的分页查询每页条数,
				roleName: null,
				//查询账户角色时要传递的参数对象
				accountRolesData: {
					pageNum: null,
					pageSize: null,
					account: this.$route.query.account, //在上级路由跳转到子路由时传递了参数过来
				},
				//查询角色权限时要传递的参数对象
				accountRolePermissionsData: {
					pageNum: null,
					pageSize: null,
					id: null
				},
				//删除账户角色时要传递的参数对象
				deleteAccountRoleData: {
					ids: null,
					account: null
				}
			}
		}
	}
</script>
