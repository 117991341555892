<template>
	<div style="overflow: hidden;position: relative;overflow-x: hidden;">
		<div style="margin: 1% 2%;">
			<el-tabs>
				<el-tab-pane label="未阅读评论消息">
					<div>
						<el-card v-if="notReadData == 0">
							没有未阅读的评论消息哦~~
						</el-card>
						<div v-if="notReadData != 0" style="text-align: right;">
							<el-button size="mini" style="padding: 0.2%;" @click="updateCommentReadMessageStatusAllButton()">全部标为已读</el-button>
						</div>
						<el-card v-for="(item,index) in notReadData" class="box-card" :key="index" :body-style="{ padding: '0' }" style="margin: 1% 0;padding: 1% 0;">
							<div style="font-size: 13px;">
								<font style="border: 1px #000000 solid;margin: 0 0.5%;padding: 0.2%;">评论未读消息</font>标题： <el-link type="primary" style="padding-bottom: 0.6%;" @click="getBlogArticleInfoPage(item.articleId)">{{item.title}}</el-link>
								<el-button size="mini" style="float: right;padding: 0.2%;margin: 0 0.5%;" @click="updateCommentReadMessageStatusButton(item.id)">标为已读</el-button>
								<font style="font-size: 11px;color: #7D6C6C;float: right;padding: 0 4%;"><b>{{item.gmtCreate}}</b></font>
							</div>
							<hr color=#987cb9 size=1 />
							<div>
								<div>
									<font style="color: #555666;padding: 0 1%;">{{item.commentAccountNickname}}:</font>
									<font v-if="item.parentCommentAccount != null" style="color: #555666;padding: 0 1% 0 0;">
										<b style="font-size: 13px;">回复</b>
										<font style="color: #555666;padding: 0 1%;">{{item.parentCommentAccountNickname}}:</font>
									</font>
									<font style="color: #000000;padding: 0 1% 0 0;">{{item.commentContent}}</font>
								</div>
							</div>
						</el-card>
					</div>
					<div style="text-align: center;margin: 1% 0;">
						<!-- 分页插件 -->
						<el-pagination background layout="prev, pager, next" :total="notReadDataFrom.total" :page-size="notReadDataFrom.pageSize" @current-change="page">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="已阅读评论消息">
					<div>
						<el-card v-if="readData == 0">
							没有历史评论消息哦~~
						</el-card>
						<el-card v-for="(item,index) in readData" class="box-card" :key="index" :body-style="{ padding: '0' }" style="margin: 1% 0;padding: 1% 0;">
							<div style="font-size: 13px;">
								<font style="border: 1px #000000 solid;margin: 0 0.5%;padding: 0.2%;">评论历史消息</font>标题： <el-link type="primary" style="padding-bottom: 0.6%;"  @click="getBlogArticleInfoPage(item.articleId)">{{item.title}}</el-link>
								<font style="font-size: 11px;color: #7D6C6C;float: right;padding: 0 5%;"><b>{{item.gmtCreate}}</b></font>
							</div>
							<hr color=#987cb9 size=1 />
							<div>
								<div>
									<font style="color: #555666;padding: 0 1%;">{{item.commentAccountNickname}}:</font>
									<font v-if="item.parentCommentAccount != null" style="color: #555666;padding: 0 1% 0 0;">
										<b style="font-size: 13px;">回复</b>
										<font style="color: #555666;padding: 0 1%;">{{item.parentCommentAccountNickname}}:</font>
									</font>
									<font style="color: #000000;padding: 0 1% 0 0;">{{item.commentContent}}</font>
								</div>
							</div>
						</el-card>
					</div>
					<div style="text-align: center;margin: 1% 0;">
						<!-- 分页插件 -->
						<el-pagination background layout="prev, pager, next" :total="readDataFrom.total" :page-size="readDataFrom.pageSize" @current-change="page2">
						</el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import {
		getCommentNotReadMessages,
		getCommentReadMessages,
		updateCommentReadMessageStatusAll,
		updateCommentReadMessageStatus
	} from '../../js/blog/CommentMessage.js'
	import {
		warningPrompt,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				notReadData: null,
				readData: null,
				//未阅读评论消息表单
				notReadDataFrom: {
					total: null,
					pageNum: 1,
					pageSize: 4
				},
				//已阅读评论消息表单
				readDataFrom: {
					total: null,
					pageNum: 1,
					pageSize: 4
				},
				//修改阅读状态表单
				notReadStatusFrom: {
					id: null
				}
			}
		},
		methods: {
			//分页查询未阅读评论消息列表
			page(currentPage) {
				this.notReadDataFrom.pageNum = currentPage
				getCommentNotReadMessages(this.notReadDataFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.notReadData = res.data.list
						this.notReadDataFrom.total = res.data.total
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//分页查询已阅读评论消息列表
			page2(currentPage) {
				this.readDataFrom.pageNum = currentPage
				getCommentReadMessages(this.readDataFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.readData = res.data.list
						this.readDataFrom.total = res.data.total
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//全部标记为已读
			updateCommentReadMessageStatusAllButton() {
				updateCommentReadMessageStatusAll().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.page(1)
						this.page2(1)
						this.$message({
							message: '评论消息全部标为已读操作成功！',
							type: 'success'
						});
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//标记为已读
			updateCommentReadMessageStatusButton(id) {
				this.notReadStatusFrom.id = id
				updateCommentReadMessageStatus(this.notReadStatusFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.page(this.notReadDataFrom.pageNum)
						this.page2(1)
						this.$message({
							message: '评论消息标为已读操作成功！',
							type: 'success'
						});
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//标题跳转到文章事件
			getBlogArticleInfoPage(articleId) {
				this.$router.push({
					path: '/articleInfo',
					query: {
						account: this.$route.query.account,
						articleId: articleId
					}
				})
			}
		},
		created() {
			this.page(1)
			this.page2(1)
		}
	}
</script>

<style>
</style>
