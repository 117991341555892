import {
	axiosBlogRequest
} from '../../network/request.js'


//获取登录用户信息事件
export function getLoginAccount() {
	return axiosBlogRequest({
		url: '/get/login/account',
		method: 'GET'
	})
}


//获取指定账户信息事件
export function getAccount(data) {
	return axiosBlogRequest({
		url: '/get/account/open',
		params: data,
		method: 'GET'
	})
}

//修改关于账号备注信息事件
export function updateAccountRemark(data) {
	return axiosBlogRequest({
		url: '/update/account/remark',
		data: data,
		method: 'PUT'
	})
}