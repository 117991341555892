<template>
	<div>
		<div style="overflow: hidden;padding: 1% 2%;">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span v-if="loginAccount.id == accountData.id"><b><i class="el-icon-info"></i> 关于我</b></span>
					<span v-if="loginAccount.id != accountData.id"><b><i class="el-icon-info"></i> 关于TA</b></span>
					<el-button v-if="!openEdit && loginAccount.id == accountData.id" style="float: right; padding: 1%" type="text" @click="openEdit = true">编辑</el-button>
					<el-button v-if="openEdit" style="float: right; padding: 1%" type="text" @click="updateAccountRemarkButton()">保存</el-button>
					<el-button v-if="openEdit" style="float: right; padding: 1%" type="text" @click="openEdit = false">取消</el-button>
				</div>
				<div class="text item">
					<div v-if="accountData.remark == null || accountData.remark == ''">
						<div v-if="!openEdit">
							<span v-if="accountData.id != loginAccount.id">TA很懒，啥也没留下~~~</span>
							<span v-if="accountData.id == loginAccount.id">马上点击右上角编辑按钮，留下你的足迹吧~~~</span>
						</div>
					</div>
					<div v-if="!openEdit" style="white-space: pre-wrap;word-wrap: break-word;">{{accountData.remark}}</div>
					<el-input v-if="openEdit" type="textarea" :rows="20" v-model="remarkFrom.remark" maxlength="6000" show-word-limit>
					</el-input>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		getLoginAccount,
		getAccount,
		updateAccountRemark
	} from '../../js/blog/BlogAccountMessage.js'
	import {
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	export default {
		data() {
			return {
				openEdit: false,
				//当前访问页面账户基础数据
				accountData: {
					id: null,
					nickname: null,
					account: null,
					username: null,
					phoneNum: null,
					address: null,
					sex: null,
					age: null,
					status: null,
					imageUrl: null,
					backgroundImageUrl: null,
					remark: null
				},
				//已登录的账户
				loginAccount: {
					id: null,
					account: null
				},
				//信息修改
				remarkFrom: {
					remark: null
				}
			}
		},
		methods: {
			//获取到指定账户用户信息
			getAccountButton() {
				//发送请求
				getAccount({account: this.$route.query.account}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData = res.data
						this.remarkFrom.remark = this.accountData.remark
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取已登录用户账户
			getLoginAccountButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					getLoginAccount().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.loginAccount = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			updateAccountRemarkButton() {
				//发送请求
				updateAccountRemark(this.remarkFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.getAccountButton()
						this.openEdit = false
					} else {
						warningPromptOpen4(res.message)
					}
				})
			}
		},
		created() {
			this.getAccountButton()
			this.getLoginAccountButton()
		}
	}
</script>

<style>
</style>
