<template>
	<div style="overflow: hidden;position: relative;overflow-x: hidden;">
		<el-card class="box-card" style="margin: 1% 2%;">
			<div slot="header" class="clearfix">
				<span> <b>账户信息设置</b></span>
				<el-button v-if="!openAccountUpdate" style="float: right; padding: 1% 0" type="text" @click="openAccountUpdateButton(true)">修改信息</el-button>
				<el-button v-if="openAccountUpdate" style="float: right; padding: 1%" type="text" @click="openAccountUpdateButton(false)">取消</el-button>
				<el-button v-if="openAccountUpdate" style="float: right; padding: 1%" type="text" @click="updateLoginAccountButton()">保存</el-button>
			</div>
			<div class="text item">
				<div>
					<el-form :model="loginAccount" ref="loginAccount" label-width="100px" class="demo-ruleForm">
						<div style="float: left;width: 40%;">
							<div>
								<el-button type="text" @click="openPhotoUpdate = true">
									<el-tooltip class="item" effect="dark" content="点击更换头像" placement="right">
										<el-avatar :size="50" :src="loginAccount.imageUrl"></el-avatar>
									</el-tooltip>
								</el-button>
							</div>
							<div>
								<el-button type="text" icon="el-icon-user" style="font-size: 20px;"></el-button>
								账户：
								<span style="font-size: 15px;"> {{loginAccount.account}}</span>
							</div>
							<div>
								<el-button type="text" icon="el-icon-postcard" style="font-size: 20px;"></el-button>
								昵称：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.nickname}}</span>
								<el-input v-if="openAccountUpdate" style="width: 60%;" size="mini" type="text" v-model="loginAccount.nickname" maxlength="32" show-word-limit />
							</div>
							<div>
								<el-button type="text" icon="el-icon-user-solid" style="font-size: 20px;"></el-button>
								姓名：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.username}}</span>
								<el-input v-if="openAccountUpdate" style="width: 60%;" size="mini" type="text" v-model="loginAccount.username" maxlength="32" show-word-limit />
							</div>
							<div>
								<el-button type="text" icon="el-icon-phone-outline" style="font-size: 20px;"></el-button>
								手机号码：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.phoneNum}}</span>
								<el-input v-if="openAccountUpdate" style="width: 60%;" size="mini" type="text" v-model="loginAccount.phoneNum" maxlength="32" show-word-limit />
							</div>
						</div>
						<div>
							<div>
								<el-button type="text" icon="el-icon-location-information" style="font-size: 20px;"></el-button>
								地址：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.address}}</span>
								<el-input v-if="openAccountUpdate" style="width: 40%;" size="mini" type="text" v-model="loginAccount.address" maxlength="64" show-word-limit />
							</div>
							<div>
								<el-button type="text" icon="el-icon-male" style="font-size: 20px;"></el-button>
								性别：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.sex}}</span>
								<template v-if="openAccountUpdate">
									<el-radio v-model="loginAccount.sex" label="男">男</el-radio>
									<el-radio v-model="loginAccount.sex" label="女">女</el-radio>
								</template>
							</div>
							<div>
								<el-button type="text" icon="el-icon-more-outline" style="font-size: 20px;"></el-button>
								年龄：
								<span v-if="!openAccountUpdate" style="font-size: 15px;"> {{loginAccount.age}}</span>
								<el-input v-if="openAccountUpdate" style="width: 40%;" size="mini" type="text" v-model="loginAccount.age" maxlength="3" show-word-limit />
							</div>
							<div>
								<el-button type="text" icon="el-icon-warning-outline" style="font-size: 20px;"></el-button>
								<span style="font-size: 15px;">账户状态： {{loginAccount.status}}</span>
							</div>
							<div>
								<el-button type="text" icon="el-icon-place" style="font-size: 20px;"></el-button>
								<span style="font-size: 15px;">最近登录地点： <i class="el-icon-loading"></i></span>
							</div>
						</div>
					</el-form>
				</div>
			</div>
		</el-card>
		
		<el-card class="box-card" style="margin: 1% 2%;">
			<div slot="header" class="clearfix">
				<span> <b>账户背景设置</b></span>
				<el-button v-if="!openAccountBackgroundImageUpdate" style="float: right; padding: 1% 0" type="text" @click="openAccountBackgroundImageUpdateButton()">上传自定义背景图</el-button>
				<input v-show="false" type="file" accept="image/*" @change="tirggerFile2($event)" id="updateBackgroundImage" ref="updateBackgroundImage" />
				<el-button v-if="openAccountBackgroundImageUpdate" style="float: right; padding: 1%" type="text" @click="cancelAccountBackgroundImageUpdateButton()">取消</el-button>
				<el-button v-if="openAccountBackgroundImageUpdate" style="float: right; padding: 1%" type="text" @click="uploadBackgroundImageButton()">保存</el-button>
			</div>
			<div>
				<div style="float: left;width: 40%;">
					<div>
						<span v-if="backgroundimageData == null">当前背景图</span>
						<span v-if="backgroundimageData != null">自定义背景图预览</span>
					</div>
					<div>
						<img :src="backgroundimageDataUrl" style="width: 350px;height: 200px;" />
					</div>
				</div>
				<div style="float: left;width: 50%;">
					<div>系统推荐背景图</div>
					<div style="text-align: left;">
						<el-popconfirm title="确定要选择它作为您的背景图吗?" v-for="(item,index) in sysBackgroundImageList" @confirm="optionBackgroundImageUpdateButton(item)" :key="index">
							<el-button slot="reference" type="text" style="margin-right: 2%;">
									<div>
										<img :src="requestAccountBackgroundImageButton(item)" style="width: 150px;height: 75px;" />
									</div>
									{{sysBackgroundImageNameList[index]}}
							</el-button>
						</el-popconfirm>
					</div>
				</div>
			</div>
		</el-card>	

		<!-- 修改头像元素 -->
		<el-dialog :visible.sync="openPhotoUpdate" @open="open()" top="1%" width="30%">
			<template slot="title">更换头像</template>
			<div style="margin-top: -8%">
				<el-button icon="el-icon-upload2" style="margin: 0 2% 2% 0;" @click="openImgButton()">上传头像</el-button>
				<input v-show="false" type="file" accept="image/*" @change="tirggerFile($event)" id="updateImage" ref="updateImage" />
				<el-button icon="el-icon-picture-outline" style="margin: 0 2% 2% 0;" @click="photoSelected()">推荐头像</el-button>
			</div>
			<div style="text-align: center;background-color: #000000;padding: 2% 0;height: 350px;line-height: 340px">
				<el-avatar :size="imageSize" shape="square" :src="imageDataUrl"></el-avatar>
			</div>
			<div class="block">
				<el-slider v-model="imageSize" :min="50" :max="350"></el-slider>
			</div>
			<div style="text-align: right;">
				<el-button type="primary" @click="uploadImageButton()">确定</el-button>
				<el-button @click="openPhotoUpdate = false">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLoginAccount,
		updateLoginAccount,
		uploadImage,
		getSysBackgroundImage,
		uploadBackgroundImage,
		updateAccountBackgroundSysImage
	} from '../../js/blog/BlogAccountSetting.js'
	import {
		successPrompt,
		warningPrompt,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'
	import {
		requestBlogImage,
		requestAccountBackgroundImage
	} from '../../network/request.js'
	export default {
		data() {
			return {
				//登录账户数据
				loginAccount: {
					id: null,
					nickname: null,
					account: null,
					username: null,
					phoneNum: null,
					address: null,
					sex: null,
					age: null,
					status: null,
					imageUrl: null,
					backgroundImageUrl: null
				},
				//是否打开账户信息修改
				openAccountUpdate: false,
				//是否打开账户背景图修改按钮
				openAccountBackgroundImageUpdate: false,
				//是否打开头像信息修改
				openPhotoUpdate: false,
				//修改头像元素图片预览大小
				imageSize: 350,
				//要修改的图片数据
				imageData: null,
				//要修改的图片预览
				imageDataUrl: null,
				//系统推荐背景图列表
				sysBackgroundImageList: null,
				sysBackgroundImageNameList: ["麦田夕阳","红枫林路","向日葵群","海上落日","碧海蓝天","寂静夜晚"],
				//要修改的背景图片数据
				backgroundimageData: null,
				//要修改的背景图片预览
				backgroundimageDataUrl: null,
			}
		},
		methods: {
			photoSelected() {
				this.$message({
					showClose: true,
					message: '该功能暂未开放！'
				});
			},
			//打开关闭修改用户信息
			openAccountUpdateButton(data) {
				this.openAccountUpdate = data
			},
			//推荐背景图列表请求转换
			requestAccountBackgroundImageButton(url){
				return requestAccountBackgroundImage(url)
			},
			//上传账户背景图按钮
			openAccountBackgroundImageUpdateButton() {
				//触发id为updateBackgroundImage的标签点击事件
				this.$refs.updateBackgroundImage.click();
			},
			//取消上传账户背景图按钮
			cancelAccountBackgroundImageUpdateButton() {
				this.openAccountBackgroundImageUpdate = false
				this.backgroundimageData = null
				this.backgroundimageDataUrl = this.loginAccount.backgroundImageUrl
			},
			//选择系统推荐背景图
			optionBackgroundImageUpdateButton(urlName) {
				updateAccountBackgroundSysImage({sysImage: urlName}).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.openAccountBackgroundImageUpdate = false
						this.backgroundimageData = null
						this.getLoginAccountButton()
						successPrompt(res.message)
					} else {
						warningPrompt(res.message)
					}
				})
			},
			//选取背景图后预显示图片及图片数据赋值事件
			tirggerFile2(event) {
				this.backgroundimageData = event
				this.openAccountBackgroundImageUpdate = true
			
				let file = event.target.files[0];
				let url = "";
				var reader = new FileReader(); //html5读文件
				reader.readAsDataURL(file); //转BASE64
				let that = this;
			
				//读取完毕调用接口
				reader.onload = function() {
					//获取图片url
					url = this.result.substring(this.result.indexOf(",") + 1);
					//更改原有头像图片的路径，预显示要更改的头像图片
					that.backgroundimageDataUrl = "data:image/png;base64," + url;
				};
			},
			//上传背景图片事件
			uploadBackgroundImageButton() {
				if (this.backgroundimageData != null) {
					let formData = new FormData(); //通过formData上传
					formData.append('photoFile', this.backgroundimageData.target.files[0])
			
					uploadBackgroundImage(formData).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.openAccountBackgroundImageUpdate = false
							this.backgroundimageData = null
							this.getLoginAccountButton()
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}
			},
			//打开修改用户头像信息触发事件
			open() {
				this.imageDataUrl = this.loginAccount.imageUrl
				this.imageData = null
			},
			//获取已登录用户账户
			getLoginAccountButton() {
				//发送请求
				getLoginAccount().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.loginAccount = res.data
						this.loginAccount.remark = null
						//转换图片地址为完整地址
						this.loginAccount.imageUrl = requestBlogImage(this.loginAccount.imageUrl)
						this.loginAccount.backgroundImageUrl = requestAccountBackgroundImage(this.loginAccount.backgroundImageUrl)
						this.backgroundimageDataUrl = this.loginAccount.backgroundImageUrl
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//修改已登录用户账户信息
			updateLoginAccountButton() {
				//发送请求
				updateLoginAccount(this.loginAccount).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.openAccountUpdate = false
						this.getLoginAccountButton()
						successPrompt(res.message)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//打开选取头像图片事件
			openImgButton() {
				//触发id为updateImage的标签点击事件
				this.$refs.updateImage.click();
			},
			//选取头像图片后预显示图片及图片数据赋值事件
			tirggerFile(event) {
				this.imageData = event

				let file = event.target.files[0];
				let url = "";
				var reader = new FileReader(); //html5读文件
				reader.readAsDataURL(file); //转BASE64
				let that = this;

				//读取完毕调用接口
				reader.onload = function() {
					//获取图片url
					url = this.result.substring(this.result.indexOf(",") + 1);
					//更改原有头像图片的路径，预显示要更改的头像图片
					that.imageDataUrl = "data:image/png;base64," + url;
				};
			},
			//上传头像图片事件
			uploadImageButton() {
				if (this.imageData != null) {
					let formData = new FormData(); //通过formData上传
					formData.append('photoFile', this.imageData.target.files[0])

					uploadImage(formData).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.openPhotoUpdate = false
							this.getLoginAccountButton()
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}
			},
			//获取系统背景图列表
			getSysBackgroundImageButton() {
				//发送请求
				getSysBackgroundImage().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.sysBackgroundImageList = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
		},
		created() {
			this.getLoginAccountButton()
			this.getSysBackgroundImageButton()
		}
	}
</script>

<style>
</style>
