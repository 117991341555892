<template>
	<div>
		<div style="float: left;width: 40%;margin: 0 0.5%;">
			<div style="text-align: center;height: 45px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
				<div style="padding-top: 5%;">
					<font style="color: rgb(140, 197, 255);">【<font style="color: blue">{{$route.query.roleName}}</font>】
						角色的权限</font>
				</div>
			</div>

			<el-checkbox-group v-model="checkList">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column fixed prop="id" label="权限ID" width="65">
						<template slot-scope="scope">
							<el-checkbox :label="scope.row.id"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column fixed prop="permissionNum" label="权限编号" width="150">
					</el-table-column>

					<el-table-column fixed prop="permissionName" label="权限名称" width="150">
					</el-table-column>
					<el-table-column fixed label="操作" width="77">
						<template slot-scope="scope">
							<el-button @click="removeRolePermissionButton(scope.row)" type="text" size="small">移除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-checkbox-group>
			<div style="text-align: right;padding: 1%;">
				<el-button style="margin-bottom: 1%;" type="primary" icon="el-icon-remove" @click="removeRolePermissionsButton()">批量移除</el-button>
			</div>

			<div style="text-align: center;margin: 1% 0;">
				<!-- 分页插件 -->
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize" @current-change="page">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		getRolePermissions,
		removeRolePermission
	} from '../../../js/background/system_account_manage/RolePermissionView.js'
	import {
		warningPrompt,
		successPrompt,
		optionPrompt2,
		optionPrompt
	} from '../../../js/public/PopUpBox.js'
	export default {
		methods: {
			//分页查询账户角色的请求
			page(currentPage) {
				const _this = this
				this.rolePermissionsData.pageNum = currentPage
				this.rolePermissionsData.pageSize = this.pageSize
				getRolePermissions(this.rolePermissionsData).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						_this.tableData = res.data.list
						_this.total = res.data.total
					} else {
						warningPrompt(res.message)
					}
				})
			},

			//移除角色权限事件
			removeRolePermissionButton(row) {
				this.removeRolePermissionData.ids = row.id
				this.removeRolePermissionData.id = this.$route.query.id
				optionPrompt(row.permissionName).then(() => {
					removeRolePermission(this.removeRolePermissionData).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPrompt(res.message)
							this.page(this.rolePermissionsData.pageNum)
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//批量移除角色权限事件
			removeRolePermissionsButton() {
				//在axios中使用params传参，这这里获取到权限id的数组对象时，赋值给另一个属性，作为要
				//传递的参数，但是这个参数如果不加上 '' 这个空符串在发起请求的时候url会变
				//成 url?ids[]=&account=** ，传递的ids参数后面多了[]符号，导致请求出错
				this.removeRolePermissionData.ids = this.checkList + ''
				this.removeRolePermissionData.id = this.$route.query.id
				optionPrompt2().then(() => {
					if (this.checkList != '') {
						removeRolePermission(this.removeRolePermissionData).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								successPrompt(res.message)
								this.page(this.rolePermissionsData.pageNum)
							} else {
								warningPrompt(res.message)
							}
						})
					} else {
						warningPrompt("请选择要删除的权限!")
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
		},
		created() {
			this.page(1);
		},
		data() {
			return {
				tableData: null,
				total: null,
				pageSize: 5, //公共的分页查询每页条数,
				roleName: null,
				checkList: [],
				//查询角色权限时要传递的参数对象
				rolePermissionsData: {
					pageNum: null,
					pageSize: null,
					id: this.$route.query.id //在上级路由跳转到子路由时传递了参数过来
				},
				//删除角色权限时要传递的参数对象
				removeRolePermissionData: {
					ids: null,
					id: null
				}
			}
		}
	}
</script>
