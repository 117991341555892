<template>
	<div>
		<div style="background-color: #F5F6F7;">
			<!-- <div style="float: left;position:fixed;z-index: 2;"> -->
			<div :style="{'margin-left': (isCollapse==true ? '-70px':'1px')}">
				<div style="float: left;overflow: hidden;position: relative;overflow-x: hidden;">
					<div style="overflow: auto;height: 900px;margin-bottom: -16px;">
						<!--  @open="":打开菜单时执行方法 @close="":关闭菜单时执行的方法    :collapse="true":是否隐藏菜单 -->
						<el-menu class="el-menu-vertical-demo" :collapse="isCollapse" :unique-opened="true">
							<el-card class="box-card">
								<div :style="{margin: '-20px',height: '230px','background-image':'url('+ accountData.backgroundImageUrl+')','background-size':'cover'}">
									<div style="text-align: center;padding: 70px 70px 0 0;">
										<!-- <el-avatar :size="60" :src="accountData.imageUrl"></el-avatar> -->
										<!-- 头像 -->
										<div style="position:relative;">
											<img :src="accountData.imageUrl" style="width: 70px;height: 70px;border-radius:60px" />
											<div v-if="!whetherAttention && loginAccount.account != accountData.account && loginAccount.id != null" style="position:absolute; z-index:2; left:65px; top:55px">
												<el-tooltip class="item" effect="dark" content="点击关注" placement="bottom">
													<el-button icon="el-icon-plus" circle size="mini" @click="accountAttentionButton()"></el-button>
												</el-tooltip>
											</div>
											<div v-if="whetherAttentionCheck" style="position:absolute; z-index:2; left:65px; top:55px">
												<el-button type="success" size="mini" icon="el-icon-check" circle></el-button>
											</div>
										</div>
									</div>
									<div>
										<div style="padding: 0 0 0 45px;">
											<font size="3" color="white">{{accountData.nickname}}</font>
										</div>
										<div style="padding: 0 0 0 45px;">
											<font size="2" color="white">{{accountData.account}}</font>
										</div>
										<!-- <div v-if="loginAccount.account != accountData.account">
										<div style="float: left;width: 40%;text-align: center;padding-left: 5%;">
											<button type="primary" plain round style="width:65px;height:20px;border-radius: 9px;cursor:pointer;" onmouseover="this.style.backgroundColor='#409EFF';" onmouseout="this.style.backgroundColor='';">
												<span style="font-size: 10px;">私信</span>
											</button>
										</div>
										<div style="float: right;width: 40%;text-align: center;padding-right: 5%;">
											<button v-if="!whetherAttention" type="primary" plain round style="width:65px;height:20px;border-radius: 9px; cursor:pointer;" onmouseover="this.style.backgroundColor='#409EFF';" onmouseout="this.style.backgroundColor='';" @click="accountAttentionButton()">
												<span style="font-size: 10px;">关注</span>
											</button>
											<button v-if="whetherAttention" type="primary" plain round style="width:65px;height:20px;border-radius: 9px; cursor:pointer;" onmouseover="this.style.backgroundColor='#409EFF';" onmouseout="this.style.backgroundColor='';"  @mouseenter="mouseenterAttention()" @mouseleave="mouseleaveAttention()" @click="accountAttentionButton()">
												<span style="font-size: 10px;">{{attention}}</span>
											</button>
										</div>
									</div> -->
									</div>
								</div>
							</el-card>
							<el-card class="box-card">
								<el-menu-item index="1" @click="goBlogHomePage($route.query.account)">
									<i class="el-icon-s-home" style="padding-bottom: 5px;"></i>
									<span v-if="loginAccount.account == accountData.account"><b>我的主页</b></span>
									<span v-if="loginAccount.account != accountData.account"><b>TA的主页</b></span>
								</el-menu-item>
								<el-submenu index="2">
									<template slot="title">
										<i class="el-icon-collection"></i>
										<span slot="title"><b>分类</b></span>
									</template>
									<el-menu-item v-for="(item,index) in articleClassifyListData" :index="'2-' + index" :key="index" @click="classifyList(item.id,item.name)">{{item.name}}
										<el-badge class="mark" style="padding-bottom: 5%;" :value="item.coun" :max="999" />
									</el-menu-item>
								</el-submenu>
								<el-submenu index="3">
									<template slot="title">
										<i class="el-icon-folder-opened"></i>
										<span slot="title"><b>归档</b></span>
									</template>
									<el-menu-item v-for="(item,index) in articleRecordListData" :index="'3-' + index" :key="index" @click="archiveList(item.gmtCreate)">{{item.gmtCreate}}年档
										<el-badge class="mark" style="padding-bottom: 5%;" :value="item.coun" :max="999" />
									</el-menu-item>
								</el-submenu>
								<el-submenu index="4">
									<template slot="title">
										<i class="el-icon-star-off"></i>
										<span slot="title"><b>其他</b></span>
									</template>
									<el-menu-item index="4-1" @click="originalAndReprint(1,'原创')">原创</el-menu-item>
									<el-menu-item index="4-2" @click="originalAndReprint(2,'转载')">转载</el-menu-item>
									<el-menu-item index="4-3" v-if="loginAccount.account == accountData.account" @click="searchAccount()">搜索用户</el-menu-item>
									<el-menu-item index="4-4" v-if="loginAccount.account == accountData.account" @click="myCollection()">我的收藏</el-menu-item>
									<el-menu-item index="4-5" v-if="loginAccount.account == accountData.account" @click="myAttention()">我的关注</el-menu-item>
									<el-menu-item index="4-6" v-if="loginAccount.account == accountData.account" @click="myAttentionFans()">我的粉丝</el-menu-item>
								</el-submenu>
								<el-submenu index="5" v-if="loginAccount.account == accountData.account">
									<template slot="title">
										<i class="el-icon-receiving"></i>
										<span slot="title"><b>内容管理</b></span>
									</template>
									<el-menu-item index="5-1" @click="articleManage()">文章管理</el-menu-item>
									<el-menu-item index="5-2" @click="classifyManage()">分类管理</el-menu-item>
								</el-submenu>
								<el-menu-item index="6" v-if="loginAccount.account == accountData.account" @click="goAccountSettingPage()">
									<i class="el-icon-s-tools" style="padding-bottom: 5px;"></i>
									<b>账户设置</b>
								</el-menu-item>
								<el-menu-item index="7" @click="goBlogAccountMessagePage()">
									<i class="el-icon-s-custom" style="padding-bottom: 5px;"></i>
									<span v-if="loginAccount.account == accountData.account"><b>关于我</b></span>
									<span v-if="loginAccount.account != accountData.account"><b>关于TA</b></span>
								</el-menu-item>
							</el-card>
						</el-menu>
					</div>
				</div>
			</div>

			<div>
				<el-card class="box-card" style=" background-color:#3F51B5;height: 65px;">
					<div style="float: left;">
						<el-button icon="el-icon-s-unfold" size="mini" circle v-if="isCollapse" @click="openMenu(false)"></el-button>
						<el-button icon="el-icon-close" size="mini" circle v-if="!isCollapse" @click="openMenu(true)"></el-button>
					</div>
					<div>
						<div style="float: left;margin-left: 6%;padding-top: 0.5%;">
							<font size="4" color="white">{{accountData.nickname}} 的博客</font>
						</div>
						<div v-if="loginAccount.id == null" style="float: right;padding: 0 1% 0 1%;">
							<el-button type="text" @click="goLoginPage()">登录</el-button>
						</div>
						<div style="float: right;padding: 0 1% 0 1%;">
							<el-tooltip class="item" effect="dark" content="分享" placement="bottom">
								<el-button type="text" size="small" @click="share()"><i class="el-icon-share" style="font-size: 23px;color: white;"></i></el-button>
							</el-tooltip>
						</div>
						<div v-if="loginAccount.id != null" style="float: right;padding: 0 1% 0 1%;">
							<el-tooltip class="item" effect="dark" content="写博客" placement="bottom">
								<el-button type="text" @click="editArticle()" size="small"><i class="el-icon-edit" style="font-size: 20px;color: white;"></i></el-button>
							</el-tooltip>
						</div>
						<div v-if="loginAccount.id != null" style="float: right;padding: 0 1% 0 1%;">
							<el-badge :value="allMessageCount != 0 ? allMessageCount:null" class="item">
								<el-button type="text" size="small">
									<el-dropdown>
										<span class="el-dropdown-link">
											<i class="el-icon-message" style="font-size: 23px;color: white;"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<!-- <el-dropdown-item>私信</el-dropdown-item> -->
											<el-dropdown-item @click.native="goCommentMessagePage()">
												评论
												<el-badge :value="commentMessageCount != 0 ?commentMessageCount : null" class="item"></el-badge>
											</el-dropdown-item>
											<el-dropdown-item @click.native="sysMessage()">系统通知</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</el-button>
							</el-badge>
						</div>
						<div v-if="loginAccount.id != null && loginAccount.account != accountData.account" style="float: right;padding: 0 1% 0 1%;">
							<el-tooltip class="item" effect="dark" content="我的博客" placement="bottom">
								<el-button type="text" @click="goMyBlogPage()" size="small"><i class="el-icon-s-home" style="font-size: 23px;color: white;"></i></el-button>
							</el-tooltip>
						</div>
						<div style="float: right;padding: 0 1% 0 1%;">
							<el-tooltip class="item" effect="dark" content="搜索全站" placement="bottom">
								<el-button type="text" @click="search()" size="small"><i class="el-icon-search" style="font-size: 23px;color: white;"></i></el-button>
							</el-tooltip>
						</div>
					</div>
				</el-card>

				<div>
					<keep-alive>
						<!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
						<router-view v-if="$route.meta.keepAlive" :key="$route.params.query" />
					</keep-alive>
					<!-- 这里是不被缓存的视图组件，比如 page3 -->
					<router-view v-if="!$route.meta.keepAlive" :key="$route.params.query" />
				</div>
			</div>

			<!-- visibility-height：滚动高度达到此参数值才出现 right、bottom控制其显示位置 -->
			<el-backtop :bottom="50">
				<div @mouseenter="mouseenter()" @mouseleave="mouseleave()" style="{visibility-height: 10px;width: 100%;background-color: #f2f5f6;box-shadow: 0 0 6px rgba(0,0,0, .12);text-align: center;line-height: 40px;color: #1989fa;}">
					<div v-html="top"></div>
				</div>
			</el-backtop>
		</div>
		<div style="postion:relative;bottom:0px;margin:0px;float:left;width:100%;" align="center">
			<div>
				<el-link type="info" style="margin: 0 1%;">关于我们</el-link>
				<el-link type="info" style="margin: 0 1%;">广告服务</el-link>
				<el-link type="info" style="margin: 0 1%;">联系邮箱：1105570390@qq.com</el-link>
			</div>
			<div>
				<el-link type="info" style="margin: 0 1%;" href="https://beian.miit.gov.cn/#/Integrated/index">桂ICP备19008767号</el-link>
				<el-link type="info" style="margin: 0 1%;" href="http://www.cyberpolice.cn/wfjb/">网络110报警服务</el-link>
				<el-link type="info" style="margin: 0 1%;" href="https://www.12377.cn/">中国互联网举报中心</el-link>
				<el-link type="info" style="margin: 0 1%;" href="/rules">版权与免责声明</el-link>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAccount,
		whetherAttention,
		accountAttention,
		getArticleRecord,
		getArticleClassifyList,
		getLoginAccount,
		countCommentNotReadMessage
	} from '../../js/blog/BlogAccountIndex.js'
	import {
		requestBlogImage,
		requestAccountBackgroundImage
	} from '../../network/request.js'
	import {
		warningPromptOpen4,
		successPromptOpen4
	} from '../../js/public/PopUpBox.js'

	export default {
		data() {
			return {
				top: '<i class="el-icon-upload2"></i>',
				attention: "已关注",
				//当前访问页面账户基础数据
				//当前访问页面账户基础数据
				accountData: {
					id: null,
					nickname: null,
					account: null,
					username: null,
					phoneNum: null,
					address: null,
					sex: null,
					age: null,
					status: null,
					imageUrl: null,
					backgroundImageUrl: null
				},
				visitAccountFrom: {
					account: this.$route.query.account
				},
				//已登录的账户
				loginAccount: {
					id: null,
					account: null
				},
				articleRecordListData: null, //归档列表
				articleClassifyListData: null, //分类列表
				isCollapse: false, //是否隐藏菜单
				isSearch: false, //是否显示搜索框
				allMessageCount: 0, //所有消息统计
				commentMessageCount: 0, //评论消息统计
				whetherAttention: false, //是否已关注
				whetherAttentionCheck: false
			}
		},
		methods: {
			mouseenter() {
				this.top = '<font style="font-size: 70%;">回到顶部</font>'
			},
			mouseleave() {
				this.top = '<i class="el-icon-upload2"></i>'
			},
			mouseenterAttention() {
				this.attention = '取消关注'
			},
			mouseleaveAttention() {
				this.attention = '已关注'
			},
			openMenu(data) {
				this.isCollapse = data
			},
			openSearchu(data) {
				this.isSearch = data
			},
			//图片地址转换
			requestBlogImageButton(url) {
				return requestBlogImage(url)
			},
			sysMessage(){
				this.$message({
					showClose: true,
					message: '该功能暂未开放！'
				});
			},
			share(){
				successPromptOpen4('直接负责地址栏地址即可分享!')
			},
			goLoginPage() {
				this.$router.push({
					path: "/blogLogin"
				})
			},
			goCommentMessagePage() {
				this.$router.push({
					path: '/commentMessage',
					query: {
						account: this.loginAccount.account
					}
				})
				this.$router.go(0)
			},
			goMyBlogPage() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.loginAccount.account
					}
				})
				this.$router.go(0)
			},
			//账户设置
			goAccountSettingPage() {
				this.$router.push({
					path: '/blogAccountSetting',
					query: {
						account: this.loginAccount.account
					}
				})
			},
			//关于账户
			goBlogAccountMessagePage() {
				this.$router.push({
					path: '/blogAccountMessage',
					query: {
						account: this.$route.query.account
					}
				})
			},
			//博客首页
			search() {
				this.$router.push({
					path: '/blogIndex',
					query: {
						account: this.$route.query.account
					}
				})
			},
			goBlogHomePage(account) {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: account
					}
				})
			},
			//归档列表事件
			archiveList(year) {
				//打开新的页面跳转方式
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						year: year,
						account: this.$route.query.account
					}
				});
			},
			//分类列表事件
			classifyList(classifyId, classifyName) {
				//打开新的页面跳转方式
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						classifyId: classifyId,
						classifyName: classifyName,
						account: this.$route.query.account
					}
				});
			},
			//原创、转载列表事件
			originalAndReprint(articleType, articleTypeName) {
				//打开新的页面跳转方式
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						articleType: articleType,
						articleTypeName: articleTypeName,
						account: this.$route.query.account
					}
				});
			},
			//我的收藏列表事件
			myCollection() {
				this.$router.push({
					path: '/blogAccountArticleList',
					query: {
						account: this.loginAccount.account,
						collection: true
					}
				});
			},
			//搜索用户事件
			searchAccount() {
				this.$router.push({
					path: '/blogSearchAccount',
					query: {
						account: this.loginAccount.account,
					}
				});
			},
			//我的关注事件
			myAttention() {
				this.$router.push({
					path: '/blogAttention',
					query: {
						account: this.loginAccount.account,
					}
				});
			},
			//我的粉丝事件
			myAttentionFans() {
				this.$router.push({
					path: '/blogAttentionFans',
					query: {
						account: this.loginAccount.account,
					}
				});
			},
			//文章管理列表事件
			articleManage() {
				this.$router.push({
					path: '/blogAccountArticleManage',
					query: {
						account: this.loginAccount.account
					}
				});
			},
			//分类管理列表事件
			classifyManage() {
				this.$router.push({
					path: '/blogArticleClassifyManage',
					query: {
						account: this.loginAccount.account
					}
				});
			},
			//获取已登录用户账户
			getLoginAccountButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					getLoginAccount().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.loginAccount = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//用户是否对指定id用户已关注
			whetherAttentionButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					whetherAttention({
						accountBy: this.$route.query.account
					}).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.whetherAttention = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//用户关注或取消指定id的用户
			accountAttentionButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					accountAttention({
						id: this.accountData.id
					}, this.whetherAttention).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							successPromptOpen4(res.message)
							let this_ = this
							this.whetherAttentionButton()
							this.whetherAttentionCheck = true
							setTimeout(function() {
								this_.whetherAttentionCheck = false
							}, 2500)
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//统计未阅读用户消息
			countCommentNotReadMessageButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该账户的通知消息
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					countCommentNotReadMessage().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.commentMessageCount = res.data
							this.allMessageCount = this.allMessageCount + res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//获取到指定账户用户信息
			getAccountButton() {
				//发送请求
				getAccount(this.visitAccountFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.accountData = res.data
						//转换图片地址为完整地址
						this.accountData.imageUrl = requestBlogImage(this.accountData.imageUrl)
						this.accountData.backgroundImageUrl = requestAccountBackgroundImage(this.accountData.backgroundImageUrl)
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定账户归档列表查询统计事件
			getArticleRecordButton() {
				//发送请求
				getArticleRecord(this.visitAccountFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleRecordListData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//获取指定账户文章分类及分类文章统计列表事件
			getArticleClassifyListButton() {
				//发送请求
				getArticleClassifyList(this.visitAccountFrom).then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.articleClassifyListData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			editArticle() {
				this.$router.push({
					path: "/blogArticleEdit"
				})
			},
		},
		created() {
			this.getAccountButton()
			this.whetherAttentionButton()
			this.getLoginAccountButton()
			this.getArticleRecordButton()
			this.getArticleClassifyListButton()
			this.countCommentNotReadMessageButton()
		},
	}
</script>

<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 230px;
		min-height: 600px;
	}
</style>
