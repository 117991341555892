<template>
	<div style="overflow: hidden;padding: 1% 2%;">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span><b>文章分类管理</b></span>
				<el-button style="float: right; padding: 1%" type="text" @click="openArticleClassify = true">新增</el-button>
			</div>
			<div>
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="id" label="ID" width="180">
					</el-table-column>
					<el-table-column prop="name" label="分类名称" width="180">
					</el-table-column>
					<el-table-column prop="status" label="状态" width="180">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 0" style="color: #474949">待审核</span>
							<span v-if="scope.row.status == 1" style="color: green">已审核</span>
							<span v-if="scope.row.status == 2" style="color: red">审核未通过</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="180">
						<template slot-scope="scope">
							<el-button size="mini" type="warning" @click="openUpdateArticleClassifyButton(scope.row)">修改</el-button>
							<el-button size="mini" type="danger" @click="deleteArticleClassifyButton(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-card>


		<!-- 动态弹出文章分类创建元素 -->
		<el-form class="demo-ruleForm" :model="articleClassifyFrom" label-width="100px" :rules="articleClassifyFromRules" ref="articleClassifyFrom">
			<el-dialog title="创建文章分类" :visible.sync="openArticleClassify">
				<el-form-item prop="name">
					<el-input style="width: 60%;" v-model="articleClassifyFrom.name" show-word-limit maxlength="6" placeholder="请输入文章分类名称"></el-input>
				</el-form-item>
				<div style="text-align: right;">
					<el-button type="primary" size="medium" @click="insertAccountArticleClassifyButton('articleClassifyFrom')">创建分类</el-button>
				</div>
			</el-dialog>
		</el-form>

		<!-- 动态弹出文章分类修改创建元素 -->
		<el-form class="demo-ruleForm" :model="updateArticleClassifyFrom" label-width="100px" :rules="articleClassifyFromRules"  ref="updateArticleClassifyFrom">
			<el-dialog title="修改文章分类" :visible.sync="openUpdateArticleClassify">
				<el-form-item prop="name">
					<el-input style="width: 60%;" v-model="updateArticleClassifyFrom.name" show-word-limit maxlength="6" placeholder="请输入文章分类名称"></el-input>
				</el-form-item>
				<div style="text-align: right;">
					<el-button type="primary" size="medium" @click="updateAccountArticleClassifyButton('updateArticleClassifyFrom')">确定修改</el-button>
				</div>
			</el-dialog>
		</el-form>
	</div>
</template>

<script>
	import {
		getLoginAccount,
		getLoginArticleClassify,
		insertAccountArticleClassify,
		articleClassifyFromValidate,
		updateArticleClassify,
		deleteAccountArticleClassify
	} from '../../js/blog/BlogArticleClassifyManage.js'
	import {
		successPrompt,
		warningPrompt,
		optionPrompt3,
		warningPromptOpen4
	} from '../../js/public/PopUpBox.js'

	export default {
		data() {
			return {
				tableData: null,
				//文章分类提交必要数据
				articleClassifyFrom: {
					name: null
				},
				//文章分类修改提交必要数据
				updateArticleClassifyFrom: {
					id: null,
					name: null
				},
				//删除文章分类必要数据
				deleteArticleClassifyFrom: {
					id: null
				},
				articleClassifyFromRules: articleClassifyFromValidate(), //绑定articleClassifyFrom表单需要验证的参数验证
				openArticleClassify: false, //是否打开添加文章分类元素
				openUpdateArticleClassify: false, //是否打开修改文章分类元素
			}
		},
		methods: {
			//获取已登录用户账户
			getLoginAccountButton() {
				//判断浏览器是否已经存在登录用户的token，如果存在则请求获取该token的账户
				if (localStorage.getItem('Blog-Authorization') != null) {
					//发送请求
					getLoginAccount().then(res => {
						//判断服务请求是否成功
						if (res.success) {
							this.loginAccount = res.data
						} else {
							warningPromptOpen4(res.message)
						}
					})
				}
			},
			//获取文章分类事件
			getLoginArticleClassifyButton() {
				getLoginArticleClassify().then(res => {
					//判断服务请求是否成功
					if (res.success) {
						this.tableData = res.data
					} else {
						warningPromptOpen4(res.message)
					}
				})
			},
			//添加文章分类事件
			insertAccountArticleClassifyButton(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						insertAccountArticleClassify(this.articleClassifyFrom).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								//重新查询登录用户文章分类
								this.getLoginArticleClassifyButton()
								successPrompt(res.message)
								this.openArticleClassify = false
							} else {
								warningPrompt(res.message)
							}
						})

					}
				});
			},
			//打开修改文章分类元素
			openUpdateArticleClassifyButton(data) {
				this.updateArticleClassifyFrom.id = data.id
				this.updateArticleClassifyFrom.name = data.name
				this.openUpdateArticleClassify = true
			},
			//修改文章分类事件
			updateAccountArticleClassifyButton(formName) {
				//参数验证
				this.$refs[formName].validate((valid) => {
					//判断表单是否通过验证
					if (valid) {
						updateArticleClassify(this.updateArticleClassifyFrom).then(res => {
							//判断服务请求是否成功
							if (res.success) {
								//重新查询登录用户文章分类
								this.getLoginArticleClassifyButton()
								successPrompt(res.message)
								this.openUpdateArticleClassify = false
							} else {
								warningPrompt(res.message)
							}
						})

					}
				});
			},
			//删除文章分类
			deleteArticleClassifyButton(id) {
				optionPrompt3("是否删除该文章分类?").then(() => {
					this.deleteArticleClassifyFrom.id = id
					deleteAccountArticleClassify(this.deleteArticleClassifyFrom).then(res => {
						//判断服务请求是否成功
						if (res.success) {
							//重新查询登录用户文章分类
							this.getLoginArticleClassifyButton()
							successPrompt(res.message)
						} else {
							warningPrompt(res.message)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
		},
		created() {
			this.getLoginArticleClassifyButton()
		}
	}
</script>

<style>
</style>
